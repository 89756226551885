import type { Device } from '@/data/device'
import { cn } from '@/lib/utils'

export const errorStatuses = [
  'broken_data_connection',
  'low_water',
  'not_operating_optimally',
  'error',
]
export const warningStatuses = [
  'high_water',
  'scheduled_maintenance',
  'no_data_received',
]
export const operatingWellStatuses = ['operating_well', 'pending']

export const allStatuses = [
  ...errorStatuses,
  ...warningStatuses,
  ...operatingWellStatuses,
]

const errorStyles = 'bg-error border border-red-300'
const errorHoverStyles = 'hover:bg-darkError'

const warningStyles = 'bg-orange-50 border border-orange-200'
const warningHoverStyles = 'hover:bg-orange-100'

const operatingWellStyles = 'bg-lightGreen border border-darkGreen'
const operatingWellHoverStyles =
  'hover:bg-darkGreen/50 hover:border-treeGreen/20'

const defaultStyles = 'bg-whiteSmoke border border-darkSmoke'

export function mapGroupedStatusToBackgroundColorWithoutHover(
  groupedStatus?: string,
) {
  if (!groupedStatus) return defaultStyles

  switch (groupedStatus.toLocaleLowerCase()) {
    case 'error':
      return errorStyles
    case 'warning':
      return warningStyles
    case 'operating_well':
      return operatingWellStyles
    default:
      return defaultStyles
  }
}

export function mapGroupedStatusToBackgroundColorWithHover(
  groupedStatus: string,
) {
  if (!groupedStatus) return 'bg-whiteSmoke'

  switch (groupedStatus.toLocaleLowerCase()) {
    case 'error':
      return cn(errorStyles, errorHoverStyles)
    case 'warning':
      return cn(warningStyles, warningHoverStyles)
    case 'operating_well':
      return cn(operatingWellStyles, operatingWellHoverStyles)
    default:
      return 'bg-whiteSmoke'
  }
}

export function mapDeviceStatusToBackgroundColorWithHover(
  status: Device['status'],
) {
  return errorStatuses.includes(status)
    ? cn(errorStyles, errorHoverStyles)
    : warningStatuses.includes(status)
      ? cn(warningStyles, warningHoverStyles)
      : cn(operatingWellStyles, operatingWellHoverStyles)
}

export function mapGroupedStatusToStatus(groupedStatus: string) {
  if (!groupedStatus) return []
  switch (groupedStatus.toLocaleLowerCase()) {
    case 'error':
      return errorStatuses
    case 'warning':
      return warningStatuses
    case 'operating_well':
      return operatingWellStatuses
    default:
      return []
  }
}

export function mapStatusToGroupedStatus(status: Device['status']) {
  if (!status) return ''

  if (errorStatuses.includes(status)) return 'error'
  if (warningStatuses.includes(status)) return 'warning'
  if (operatingWellStatuses.includes(status)) return 'operating_well'

  return ''
}
