import { TrashIcon } from '@radix-ui/react-icons'
import { useState } from 'react'

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'

function DeleteFirmwareButton({
  onDelete,
  name,
}: {
  onDelete: () => Promise<void>
  name: string
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <AlertDialog open={isOpen} onOpenChange={() => setIsOpen((o) => !o)}>
      <AlertDialogTrigger asChild>
        <Button
          type="button"
          variant="outline"
          className="h-8 w-8 rounded-full border-red-700/50 p-0 text-red-700 hover:bg-red-50 hover:text-red-800"
        >
          <span className="sr-only">Delete firmware version</span>
          <TrashIcon className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you want to delete the firmware version {name}
          </AlertDialogTitle>
          <AlertDialogDescription>
            This action can not be undone
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            isLoading={isLoading}
            className="bg-red-500 hover:bg-red-500/90"
            onClick={async () => {
              setIsLoading(true)
              await onDelete()
              setIsLoading(false)
              setIsOpen(false)
            }}
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
export default DeleteFirmwareButton
