import useOrganization from '@/hooks/useOrganization'
import useWeather from '@/hooks/useWeather'
import { getTempSuffix } from '@/utils/unitHelpers'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../ui/dropdown-menu'
import DashboardWeatherIcon from '../icon-resolvers/WeatherIcon'
import DashboardWeatherCard from '../WeatherCard'

function WeatherMenu() {
  const { organization } = useOrganization()
  const weather = useWeather(
    organization?.lat,
    organization?.lng,
    organization?.temperatureUnit,
  )

  if (!weather) return null

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="h-[56px] rounded-lg px-2 hover:bg-darkSand">
        <div className="flex gap-1">
          <DashboardWeatherIcon status={weather?.status} size="lg" />
          <div className="flex flex-col gap-1">
            <span className="text-xs font-bold">{weather?.address}</span>
            <span className="text-sm font-bold text-skyBlue">
              {weather?.temperature}
              {getTempSuffix(organization?.temperatureUnit)}
            </span>
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-8 flex max-w-xs flex-col gap-4 rounded-xl bg-whiteSmoke px-6 py-4">
        <DashboardWeatherCard weather={weather} organization={organization} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
export default WeatherMenu
