import * as Sentry from '@sentry/react'

import { axiosClient } from '@/lib/axios'

const BASE_URI = '/admin/metadata'

export interface MetadataPoint {
  id: number
  justBooted: boolean
  resetReason: number
  uptime: number
  firmwareVersion: string | null
  modemFwVersion: string | null
  referenceSignal: number
  activeSection: number
  errorStatus?: number
  deviceId: number
  IMEI: string
  createdAt: string
}

export interface MetadataResponse {
  metadataPoints: MetadataPoint[]
  totalCount: number
}

export const getMetadataPoints = async (
  abortSignal: AbortSignal,
  imei?: string | null,
  take = 20,
  page = 0,
  from?: Date,
  to?: Date,
  orgId?: string | null,
) => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      take: take.toString(),
      ...(typeof imei === 'string' && { imei }),
      ...(from && { from: from.toISOString() }),
      ...(to && { to: to.toISOString() }),
      ...(orgId && { orgId }),
    })

    const res = await axiosClient.get<MetadataResponse>(
      `${BASE_URI}?${params.toString()}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}
