import { Check, ChevronsUpDown } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import useAdminDevices from '@/hooks/useAdminDevices'
import { cn } from '@/lib/utils'

function DeviceImeiAutocomplete({
  imei,
  onSelect,
  orgId,
}: {
  imei: string
  onSelect: (imei: string) => void
  orgId?: string | null
}) {
  const { t } = useTranslation(['admin'])
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState(() => imei || '')
  const { data, isLoading } = useAdminDevices(
    input,
    undefined,
    undefined,
    orgId,
  )
  const devices = data?.devices || []

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
          disabled={isLoading}
        >
          {imei ? imei : t('device_select.search_text')}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <Command>
          <CommandInput
            placeholder={t('device_select.placeholder')}
            value={input}
            onInput={(e) => setInput(e.currentTarget.value)}
          />
          <CommandEmpty>{t('device_select.no_devices_found')}</CommandEmpty>
          <CommandGroup>
            {devices.slice(0, 10).map((d) => (
              <CommandItem
                key={d.imei}
                value={d.imei}
                onSelect={(currentValue) => {
                  onSelect(currentValue === imei ? '' : currentValue)
                  setOpen(false)
                }}
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    imei === d.imei ? 'opacity-100' : 'opacity-0',
                  )}
                />
                {d.imei} ({d.name})
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
export default DeviceImeiAutocomplete
