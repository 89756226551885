import * as Sentry from '@sentry/react'

import { axiosClient } from '@/lib/axios'
import type { UpdateOrganization } from '@/routes/app/organization-settings'

import type { DeviceStatus } from './device'
import type { User } from './user'

export type ExternalDisplayValue = {
  id?: number
  label: string
  value: string
  updatedAt?: string
}

export interface Organization {
  id: number
  name: string
  logoUrl?: string
  website?: string
  headerImageUrl?: string
  address: string
  lat: number
  lng: number
  timeZone?: string
  language?: string
  distanceUnit: string
  temperatureUnit: string
  users: User[]
}

export interface OrganizationUserInvite {
  email: string
  firstName?: string
  lastName?: string
  role: string
}

export interface OrganizationOverview {
  totalDeviceCount: number
  statuses: { status: DeviceStatus; numberOfDevices: number }[]
  displayValues: ExternalDisplayValue[]
}

const BASE_URL = `/organization`

export const getOrganization = async (id: string) => {
  try {
    const res = await axiosClient.get<Organization>(`${BASE_URL}/${id}`)
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { orgId: id } })
    throw err
  }
}

export const getOrganizations = async (signal: AbortSignal) => {
  try {
    const res = await axiosClient.get<Organization[]>(`${BASE_URL}`, { signal })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const updateOrganization = async (
  id: string,
  data: UpdateOrganization,
) => {
  try {
    const res = await axiosClient.put(`${BASE_URL}/${id}`, data)
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { orgId: id, data } })
    throw err
  }
}

export const getOrganizationOverview = async (
  id: string,
  signal: AbortSignal,
) => {
  try {
    const res = await axiosClient.get<OrganizationOverview>(
      `${BASE_URL}/${id}/overview`,
      {
        signal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { orgId: id } })
    throw err
  }
}

export const inviteUser = async (id: string, data: OrganizationUserInvite) => {
  try {
    const res = await axiosClient.post(`${BASE_URL}/${id}/invite-user`, data)
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { orgId: id, data } })
    throw err
  }
}

export const resendUserInvitation = async (id: string, userId: number) => {
  try {
    const res = await axiosClient.post(
      `${BASE_URL}/${id}/user/${userId}/resend-invitation`,
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { orgId: id, userId } })
    throw err
  }
}

export const deleteUser = async (id: string, userId: number) => {
  try {
    const res = await axiosClient.delete(`${BASE_URL}/${id}/user/${userId}`)
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { orgId: id, userId } })
    throw err
  }
}
