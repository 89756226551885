import WaterLevelLeftHigh from '@/assets/icons/waterlefthigh.svg?react'
import HighWaterIcon from '@/assets/icons/waterlevelssame.svg?react'
import LowWaterIcon from '@/assets/icons/waterlow.svg?react'
import WaterLevelRightHigh from '@/assets/icons/waterrighthigh.svg?react'
import type { Device } from '@/data/device'

function DeviceVolumeIcon({
  waterLevelLeft,
  waterLevelRight,
  status,
}: {
  waterLevelLeft: Device['waterLevelLeft']
  waterLevelRight: Device['waterLevelRight']
  status: Device['status']
}) {
  if (status === 'low_water' || !waterLevelLeft || !waterLevelRight)
    return <LowWaterIcon />
  if (status === 'high_water' || waterLevelLeft === waterLevelRight)
    return <HighWaterIcon />
  if (waterLevelLeft > waterLevelRight) return <WaterLevelLeftHigh />

  return <WaterLevelRightHigh />
}

export default DeviceVolumeIcon
