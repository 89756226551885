import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import type { AddEditExternalValue } from '@/components/v2/dialogs/ExternalValueDialog'
import {
  addExternalDisplayValue,
  deleteExternalDisplayValue,
  updateExternalDisplayValue,
} from '@/data/external-display-value'
import { getOrganizationOverview } from '@/data/organization'

const DEFAULT_POLLING_INTERVAL =
  import.meta.env.VITE_DEVICES_POLLING_INTERVAL || 30000

function useOverview() {
  const abortController = new AbortController()
  const { orgId = '' } = useParams()

  const {
    data: overview,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['overview', orgId],
    queryFn: async () => getOrganizationOverview(orgId, abortController.signal),
    refetchInterval: DEFAULT_POLLING_INTERVAL,
    enabled: Boolean(orgId),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  const updateMutation = useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: number
      data: AddEditExternalValue
    }) => updateExternalDisplayValue(orgId, id, data, abortController.signal),
    onSuccess: () => {
      refetch()
    },
  })

  const createMutation = useMutation({
    mutationFn: async (data: AddEditExternalValue) =>
      addExternalDisplayValue(orgId, data, abortController.signal),
    onSuccess: () => {
      refetch()
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async (id: number) =>
      deleteExternalDisplayValue(orgId, id, abortController.signal),
    onSuccess: () => {
      refetch()
    },
  })

  return {
    overview,
    isLoading,
    onCreateDisplayValue: createMutation.mutateAsync,
    onUpdateDisplayValue: updateMutation.mutateAsync,
    onDeleteDisplayValue: deleteMutation.mutateAsync,
  }
}
export default useOverview
