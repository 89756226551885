import { Pencil2Icon } from '@radix-ui/react-icons'
import type { ColumnDef, PaginationState } from '@tanstack/react-table'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { RefreshCw } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BooleanParam,
  DateParam,
  StringParam,
  useQueryParam,
} from 'use-query-params'

import AddEditMetadataDownlinkModal from '@/components/admin/AddEditMetadataDownlinkModal'
import DeleteCommandButton from '@/components/admin/DeleteCommandButton'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import PaginatedTable from '@/components/v2/PaginatedTable'
import DeviceImeiAutocomplete from '@/components/v2/selects/DeviceImeiAutocomplete'
import TableDateSelect from '@/components/v2/selects/TableDateSelect'
import UserEmailAutocomplete from '@/components/v2/selects/UserEmailAutocomplete'
import type { MetadataDownlink } from '@/data/admin/metadataDownlink'
import useAdminMetadataDownlink from '@/hooks/useAdminMetadataDownlink'
import useAdminMetadataDownlinks from '@/hooks/useAdminMetadataDownlinks'
import { cn } from '@/lib/utils'
import { localizedDateWithTime } from '@/utils/dateHelpers'

export default function MetadataDownlinksPage() {
  const { t } = useTranslation(['admin'])
  const [, setId] = useQueryParam('metadataDownlinkId', StringParam)
  const [searchText, setSearchText] = useQueryParam('imei', StringParam)
  const [email, setEmail] = useQueryParam('email', StringParam)
  const [includeDeleted, setIncludeDeleted] = useQueryParam(
    'includeDeleted',
    BooleanParam,
  )

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const [from] = useQueryParam('from', DateParam)
  const [to] = useQueryParam('to', DateParam)

  const { data, isLoading, refetch, isRefetching } = useAdminMetadataDownlinks(
    searchText,
    email,
    pagination.pageSize,
    pagination.pageIndex,
    from ?? undefined,
    to ?? undefined,
    includeDeleted,
  )
  const { onDelete } = useAdminMetadataDownlink()

  const columns = useMemo<ColumnDef<MetadataDownlink>[]>(() => {
    return [
      {
        id: 'actions',
        cell: ({ row }) => {
          const { id, imei, deletedAt, isDownlinkedToDevice } = row.original

          if (deletedAt) {
            return isDownlinkedToDevice ? (
              <Badge variant="default">Processed</Badge>
            ) : (
              <Badge variant="destructive">Deleted</Badge>
            )
          }

          return (
            <div className="flex gap-2">
              <Button
                variant="outline"
                type="button"
                className="h-8 w-8 rounded-full border-yellow-700/50 p-0 text-yellow-700 hover:bg-yellow-50 hover:text-yellow-800"
                onClick={() => setId(id.toString())}
              >
                <span className="sr-only">Edit metadata downlink</span>
                <Pencil2Icon className="h-4 w-4" />
              </Button>
              <DeleteCommandButton
                onDelete={async () => onDelete(id)}
                imei={imei}
              />
            </div>
          )
        },
      },
      {
        accessorKey: 'imei',
        header: t('metadata_downlinks.imei'),
      },
      {
        accessorKey: 'samples',
        header: t('metadata_downlinks.samples'),
      },
      {
        accessorKey: 'dataUplinkPeriod',
        header: t('metadata_downlinks.data_uplink_period'),
      },
      {
        accessorKey: 'metadataUplinkPeriod',
        header: t('metadata_downlinks.metadata_uplink_period'),
      },
      {
        accessorKey: 'fotaUplinkPeriod',
        header: t('metadata_downlinks.fota_uplink_period'),
      },
      {
        accessorKey: 'setCycleTime',
        header: t('metadata_downlinks.cycle_time'),
      },
      {
        accessorKey: 'createdAt',
        header: t('metadata_downlinks.created_at'),
        cell: ({ row }) => {
          const { createdAt } = row.original

          if (!createdAt) return null

          return (
            <span className="whitespace-nowrap">
              {localizedDateWithTime(new Date(createdAt))}
            </span>
          )
        },
      },
      {
        accessorKey: 'modifiedAt',
        header: t('metadata_downlinks.modified_at'),
        cell: ({ row }) => {
          const { modifiedAt } = row.original

          if (!modifiedAt) return null

          return (
            <span className="whitespace-nowrap">
              {localizedDateWithTime(new Date(modifiedAt))}
            </span>
          )
        },
      },
      {
        accessorKey: 'createdBy',
        header: t('metadata_downlinks.created_by'),
      },
      {
        accessorKey: 'modifiedBy',
        header: t('metadata_downlinks.modified_by'),
      },
      {
        accessorKey: 'deletedAt',
        header: t('metadata_downlinks.deleted_at'),
        cell: ({ row }) => {
          const { deletedAt } = row.original

          if (!deletedAt) return null

          return (
            <span className="whitespace-nowrap">
              {localizedDateWithTime(new Date(deletedAt))}
            </span>
          )
        },
      },
    ]
  }, [])

  const table = useReactTable({
    data: data ? data.downlinks || [] : [],
    columns,
    pageCount: data?.totalCount
      ? Math.ceil(data.totalCount / pagination.pageSize)
      : -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <div>
      <div className="relative my-8">
        <h2 className="text-3xl">{t('Metadata downlinks')}</h2>
        <div className="absolute right-0 top-0">
          <Button
            variant="outline"
            onClick={async () => refetch()}
            disabled={isRefetching}
          >
            <RefreshCw
              className={cn('h-4 w-4', isRefetching && 'animate-spin')}
            />
            <span className="hidden pl-2 text-base md:block">Refresh</span>
          </Button>
        </div>
      </div>
      <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap">
        <div className="min-w-full sm:min-w-[200px]">
          <DeviceImeiAutocomplete
            imei={searchText || ''}
            onSelect={(val) => setSearchText(val)}
          />
        </div>
        <div className="min-w-full sm:min-w-[200px]">
          <UserEmailAutocomplete
            email={email || ''}
            onSelect={(val) => setEmail(val)}
          />
        </div>
        <div className="min-w-full sm:min-w-[200px]">
          <TableDateSelect />
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="includeDeleted"
            checked={includeDeleted || false}
            onClick={() => setIncludeDeleted((prev) => !prev)}
          />
          <label
            htmlFor="includeDeleted"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Include deleted and processed?
          </label>
        </div>
        <div className="flex flex-1 justify-end">
          <Button
            type="button"
            variant="outline"
            size="icon"
            onClick={() => setId('new')}
          >
            +
          </Button>
        </div>
      </div>
      <PaginatedTable<MetadataDownlink> table={table} isLoading={isLoading} />
      <AddEditMetadataDownlinkModal />
    </div>
  )
}
