import { forwardRef, useState } from 'react'

import CrossedEye from '@/assets/icons/crossedeye.svg?react'
import Eye from '@/assets/icons/eye.svg?react'
import { Button } from '@/components/ui/button'
import { Input, type InputProps } from '@/components/ui/input'

const PasswordInput = forwardRef<HTMLInputElement, InputProps>((ref, props) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <div className="relative">
      <Input
        type={showPassword ? 'text' : 'password'}
        placeholder="Password"
        className="w-full pr-10"
        name="password"
        {...props}
        {...ref}
      />
      <Button
        size="icon"
        variant="ghost"
        className="absolute right-0 top-0 hover:bg-gray-100"
        onClick={() => setShowPassword((prev) => !prev)}
        type="button"
      >
        {showPassword ? <CrossedEye /> : <Eye />}
      </Button>
    </div>
  )
})
export default PasswordInput
