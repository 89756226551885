import { FilterIcon, PlusIcon } from 'lucide-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { activeSectionState, status } from '@/utils/enumHelpers'

const enum_conditions = ['eq', 'neq'] as const
const conditions = [...enum_conditions, 'lt', 'gt'] as const

type FilterCondition = (typeof conditions)[number]

interface Field {
  key: string
  name: string
  type: 'number' | 'enum'
  enumValues?: string[]
}

export interface Filter {
  field: string
  condition: FilterCondition
  value: string
}

export const fields: Field[] = [
  { key: 'waterlevelleft', name: 'Water level left', type: 'number' },
  { key: 'waterlevelright', name: 'Water level right', type: 'number' },
  {
    key: 'waterlevelpercentage',
    name: 'Water level percentage',
    type: 'number',
  },
  { key: 'fillvalvestate', name: 'Fill valve time', type: 'number' },
  { key: 'drainvalvestate', name: 'Drain valve time', type: 'number' },
  {
    key: 'fertigationvalvestate',
    name: 'Fertigation valve time',
    type: 'number',
  },
  { key: 'driplinevalvestate', name: 'Drip line valve time', type: 'number' },
  {
    key: 'activesection',
    name: 'Active section',
    type: 'enum',
    enumValues: activeSectionState.map((_, i) => i.toString()),
  },
  {
    key: 'status',
    name: 'Status',
    type: 'enum',
    enumValues: status.map((_, i) => i.toString()),
  },
]

export default function DataFilter() {
  const { t } = useTranslation(['admin'])
  const [query, setQuery] = useQueryParams({
    filters: withDefault(StringParam, '[]'),
  })

  const [currentFilter, setCurrentFilter] = useState<Filter>({
    field: '',
    condition: 'eq',
    value: '',
  })

  const selectedField = fields.find(
    (f) => f.key.toLowerCase() === currentFilter.field,
  )

  const filters: Filter[] = JSON.parse(query.filters)

  const updateFilters = useCallback(
    (newFilters: Filter[]) => {
      setQuery({ filters: JSON.stringify(newFilters) })
    },
    [setQuery],
  )

  const handleAddFilter = (newFilter: Filter) => {
    if (newFilter.field && newFilter.value) {
      updateFilters([...filters, newFilter])
      setCurrentFilter({ field: '', condition: 'eq', value: '' })
    }
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <FilterIcon className="mr-2 h-4 w-4" />
          {t('data_filter.filter')}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">
              {t('data_filter.header')}
            </h4>
          </div>
          <div className="grid gap-2">
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="field">Field</Label>
              <Select
                value={currentFilter.field}
                onValueChange={(value) =>
                  setCurrentFilter({
                    ...currentFilter,
                    field: value,
                    condition: 'eq',
                    value: '',
                  })
                }
              >
                <SelectTrigger className="col-span-2">
                  <SelectValue placeholder="Select field" />
                </SelectTrigger>
                <SelectContent>
                  {fields.map((field) => (
                    <SelectItem key={field.key} value={field.key.toLowerCase()}>
                      {field.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            {selectedField && (
              <>
                <div className="grid grid-cols-3 items-center gap-4">
                  <Label htmlFor="condition">Condition</Label>
                  <Select
                    value={currentFilter.condition}
                    onValueChange={(value) =>
                      setCurrentFilter({
                        ...currentFilter,
                        condition: value as FilterCondition,
                      })
                    }
                  >
                    <SelectTrigger className="col-span-2">
                      <SelectValue placeholder="Select condition" />
                    </SelectTrigger>
                    <SelectContent>
                      {selectedField.type === 'number' ? (
                        <>
                          {conditions.map((condition, i) => (
                            <SelectItem key={i} value={condition}>
                              {t(`data_filter.${condition}`)}
                            </SelectItem>
                          ))}
                        </>
                      ) : (
                        <>
                          {enum_conditions.map((condition, i) => (
                            <SelectItem key={i} value={condition}>
                              {t(`data_filter.${condition}`)}
                            </SelectItem>
                          ))}
                        </>
                      )}
                    </SelectContent>
                  </Select>
                </div>
                <div className="grid grid-cols-3 items-center gap-4">
                  <Label htmlFor="value">Value</Label>
                  {selectedField.type === 'number' ? (
                    <Input
                      id="value"
                      type="number"
                      value={currentFilter.value}
                      className="col-span-2"
                      onChange={(e) =>
                        setCurrentFilter({
                          ...currentFilter,
                          value: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <Select
                      value={currentFilter.value}
                      onValueChange={(value) =>
                        setCurrentFilter({ ...currentFilter, value })
                      }
                    >
                      <SelectTrigger className="col-span-2">
                        <SelectValue placeholder="Select value" />
                      </SelectTrigger>
                      <SelectContent>
                        {selectedField.enumValues?.map((value) => (
                          <SelectItem key={value} value={value.toLowerCase()}>
                            {t(`data_filter.${selectedField.key}.${value}`)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                </div>
              </>
            )}
          </div>
          <Button
            onClick={() => handleAddFilter(currentFilter)}
            className="w-full"
          >
            <PlusIcon className="mr-2 h-4 w-4" />
            {t('data_filter.add')}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
