import { useMutation } from '@tanstack/react-query'
import { useRef, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { uploadImage } from '@/data/file-upload'

import { Button } from '../ui/button'

import { useOrg } from './OrgProvider'

export type EntityType = 'device' | 'organization' | 'user'
type UploadVariables = {
  file: File
  entity: EntityType
}

function ImageSettings({
  onChange,
  value,
  entity,
  disabled,
}: {
  onChange?: (value?: string) => void
  value?: string | null
  entity: EntityType
  disabled?: boolean
}) {
  const { t } = useTranslation('common')
  const imagePreviewRef = useRef<HTMLImageElement>(null)
  const imageInputRef = useRef<HTMLInputElement>(null)
  const { selectedOrgId } = useOrg()
  const uploadMutation = useMutation({
    mutationFn: async ({ file, entity }: UploadVariables) =>
      uploadImage(selectedOrgId.toString(), file, entity),
    onSuccess: (data: string) => {
      onChange?.(data)
    },
  })

  const handleUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return

    await uploadMutation.mutateAsync({ file, entity })
  }

  return (
    <div className="flex flex-wrap items-center gap-8 sm:flex-nowrap">
      <img
        src={value || ''}
        className="h-28 w-28 rounded-2xl"
        alt="Upload"
        ref={imagePreviewRef}
      />
      <div>
        <Button
          variant="link"
          type="button"
          className="text-skyBlue"
          onClick={() => {
            imageInputRef.current?.click()
          }}
          disabled={disabled}
        >
          {value
            ? t('image.update_image', { ns: 'common' })
            : t('image.upload_image', { ns: 'common' })}
        </Button>
        {value ? (
          <Button
            variant="link"
            type="button"
            className="text-rockRed"
            onClick={() => {
              if (imageInputRef.current && imagePreviewRef.current) {
                imageInputRef.current.value = ''
                imagePreviewRef.current.src = ''
              }
              onChange?.()
            }}
            disabled={disabled}
          >
            {t('image.remove_image', { ns: 'common' })}
          </Button>
        ) : null}
        <input
          type="file"
          className="hidden"
          ref={imageInputRef}
          onChange={async (e) => handleUploadImage(e)}
        />
      </div>
    </div>
  )
}
export default ImageSettings
