import { useBrowserStorage } from './useBrowserStorage'

function useSelectedOrgId() {
  const [selectedOrgId, setSelectedOrgId] = useBrowserStorage(
    'org',
    0,
    'localStorage',
  )
  return { selectedOrgId, setSelectedOrgId }
}
export default useSelectedOrgId
