import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { useToast } from '@/components/ui/use-toast'
import DeviceSettingsForm, {
  type CreateUpdateDevice,
} from '@/components/v2/DeviceSettingsForm'
import InternalNavigationBar from '@/components/v2/InternalNavigationBar'
import useDevice from '@/hooks/useDevice'
import useOrganization from '@/hooks/useOrganization'
import { successfulToastClassNames } from '@/utils/toastHelpers'

function AddDevicePage() {
  const [imei] = useQueryParam('imei', withDefault(StringParam, ''))
  const { t } = useTranslation('app')
  const { orgId } = useParams()
  const { onCreate } = useDevice()
  const { toast } = useToast()
  const { organization } = useOrganization()
  const navigate = useNavigate()
  const location = useLocation()

  const handleSubmit = async (data: CreateUpdateDevice) => {
    try {
      await onCreate(data)
      toast({
        title: 'Device created!',
        className: successfulToastClassNames,
      })
      const deviceLink = location.pathname.split('/').slice(0, -1).join('/')
      navigate(deviceLink)
    } catch (err) {}
  }

  return (
    <div className="mx-auto w-full max-w-4xl px-2 py-4 sm:p-4">
      <InternalNavigationBar
        backLink={{
          href: `/app/${orgId}`,
          text: t('add_device.back_text'),
        }}
      />

      <div className="mt-8">
        <h2 className="text-2xl font-bold">{t('add_device.header')}</h2>
        <h4 className="mt-2">{t('add_device.subheader')}</h4>
        <div className="mt-8">
          <DeviceSettingsForm
            distanceUnit={organization?.distanceUnit}
            onSubmit={handleSubmit}
            device={{
              imei: imei,
              organizationId: organization?.id,
              lat: organization?.lat,
              lng: organization?.lng,
            }}
            mode="create"
          />
        </div>
      </div>
    </div>
  )
}
export default AddDevicePage
