import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { getNameInitials } from '@/utils/textHelpers'

import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../ui/dropdown-menu'
import { useAuth } from '../AuthProvider'
import { useOrg } from '../OrgProvider'
import OrganizationSelect from '../selects/OrganizationSelect'

function UserMenu() {
  const { user, handleLogout } = useAuth()
  const { t } = useTranslation('app')
  const { selectedOrgId, setSelectedOrgId } = useOrg()
  const navigate = useNavigate()

  const handleChange = (value: string) => {
    setSelectedOrgId(Number(value))
    navigate(`/app/${value}`)
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Avatar>
          <AvatarImage src={user?.imageUrl} />
          <AvatarFallback className="bg-skyBlue font-semibold text-white hover:bg-skyBlue/90">
            {getNameInitials(user?.firstName, user?.lastName)}
          </AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>
          {t('user_menu.header', {
            name: `${user?.firstName} ${user?.lastName}`,
          })}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <OrganizationSelect
            value={selectedOrgId.toString()}
            onChange={handleChange}
          />
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link to={`/app/${selectedOrgId}/users/${user?.id}`}>
            {t('user_menu.profile')}
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link to={`/app/${selectedOrgId}/change-password`}>
            {t('user_menu.change_password')}
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleLogout}>
          {t('user_menu.log_out')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
export default UserMenu
