import { PenIcon } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { mapGroupedStatusToBackgroundColorWithoutHover } from '@/utils/statusHelpers'

function DisplayValueCard({
  header,
  subheader,
  value,
  status,
  onEdit,
  showEdit = false,
}: {
  header: string
  subheader?: string
  value: string
  status?: string
  onEdit?: () => void
  showEdit?: boolean
}) {
  return (
    <div
      className={cn(
        'relative mt-4 flex min-w-[48%] max-w-[200px] flex-grow basis-0 flex-col gap-2 overflow-hidden rounded-3xl p-4 md:min-w-[180px] md:flex-grow-0 md:basis-auto',
        mapGroupedStatusToBackgroundColorWithoutHover(status),
      )}
    >
      <h6 className="truncate pr-8 text-sm font-semibold uppercase">
        {header}
      </h6>
      {subheader ? <span className="text-sm">{subheader}</span> : null}
      <h4 className="truncate text-lg font-bold">{value}</h4>
      {showEdit ? (
        <Button
          variant="ghost"
          size="icon"
          className="absolute right-1 top-1 rounded-full hover:bg-darkSmoke/70"
          onClick={onEdit}
        >
          <PenIcon className="h-4 w-4" />
        </Button>
      ) : null}
    </div>
  )
}
export default DisplayValueCard
