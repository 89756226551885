import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { getDeviceData } from '@/data/device-data'

const DEFAULT_POLLING_INTERVAL = 600000

function useDeviceData(id?: string, fromDate?: Date, toDate?: Date) {
  const abortController = new AbortController()
  const { orgId = '' } = useParams()

  const { data: deviceData, isLoading } = useQuery({
    queryKey: ['device-data', orgId, id, fromDate, toDate],
    queryFn: async () =>
      getDeviceData(orgId, id!, fromDate, toDate, abortController.signal),
    refetchInterval: DEFAULT_POLLING_INTERVAL,
    enabled: Boolean(orgId) && Boolean(id),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return {
    deviceData,
    isLoading,
  }
}
export default useDeviceData
