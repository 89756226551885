import { useEffect, useState, type MutableRefObject } from 'react'

export const useContainerDimensions = (
  myRef: MutableRefObject<HTMLElement | null>,
) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current!.offsetWidth,
      height: myRef.current!.offsetHeight,
    })

    const handleResize = () => {
      if (myRef.current) {
        setDimensions(getDimensions())
      }
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef])

  return dimensions
}
