import { useTranslation } from 'react-i18next'

import PrestineDrop from '@/assets/icons/prestinedrop.svg?react'
import type { OrganizationOverview } from '@/data/organization'
import { cn } from '@/lib/utils'
import {
  errorStatuses,
  mapGroupedStatusToBackgroundColorWithoutHover,
  operatingWellStatuses,
  warningStatuses,
} from '@/utils/statusHelpers'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'

import DeviceStatusIcon from './icon-resolvers/DeviceStatusIcon'

function getCardColor(statuses: OrganizationOverview['statuses']) {
  let hasError = false
  let hasWarning = false

  statuses.forEach((item) => {
    if (errorStatuses.includes(item.status) && item.numberOfDevices > 0) {
      hasError = true
    } else if (
      warningStatuses.includes(item.status) &&
      item.numberOfDevices > 0
    ) {
      hasWarning = true
    }
  })

  if (hasError) {
    return {
      status: 'error',
      styles: mapGroupedStatusToBackgroundColorWithoutHover('error'),
    }
  }

  if (hasWarning) {
    return {
      status: 'warning',
      styles: mapGroupedStatusToBackgroundColorWithoutHover('warning'),
    }
  }

  return {
    status: 'operating_well',
    styles: mapGroupedStatusToBackgroundColorWithoutHover('operating_well'),
  }
}

function OverviewCard({
  statuses,
}: {
  statuses?: OrganizationOverview['statuses']
}) {
  const { t } = useTranslation(['app', 'common'])

  if (!statuses) return null

  const { status: cardStatus, styles } = getCardColor(statuses)
  return (
    <div
      className={cn(
        'relative mt-4 flex basis-full flex-col justify-between overflow-hidden rounded-3xl p-4 md:min-w-[180px] md:flex-grow-0 md:basis-auto',
        styles,
      )}
    >
      <div className="flex items-center justify-between">
        <h6 className="truncate text-sm font-semibold uppercase">
          {t('overview_card.header')}
        </h6>

        <span>{t('overview_card.subheader')}</span>
      </div>
      <hr className={cn('my-4', styles)} />

      {cardStatus === 'operating_well' ? (
        <div className="flex items-center gap-4">
          <PrestineDrop />
          <h6 className="text-xl font-semibold">
            {t('overview_card.good_label')}
          </h6>
        </div>
      ) : (
        statuses
          .filter((status) => !operatingWellStatuses.includes(status.status))
          .map((item) => (
            <button
              className="group flex w-full cursor-default items-center justify-between gap-4 leading-7"
              key={item.status}
            >
              <div className="flex items-center gap-1">
                <div className="h-7 w-7">
                  <DeviceStatusIcon status={item.status} />
                </div>
                <div className="font-semibold">
                  {capitalizeFirstCharacter(t(item.status, { ns: 'common' }))}
                </div>
              </div>
              <div className="font-semibold">{item.numberOfDevices}</div>
            </button>
          ))
      )}
    </div>
  )
}
export default OverviewCard
