import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import type { Notification } from '@/data/notification'
import useOrganization from '@/hooks/useOrganization'
import { cn } from '@/lib/utils'
import { localizedTimeSince } from '@/utils/dateHelpers'

import { Avatar } from '../ui/avatar'

import DeviceImageWithFallback from './DeviceImageWithFallback'

function ActivityNotificationRow({
  notification,
}: {
  notification: Notification
}) {
  const { t, i18n } = useTranslation('app')
  const { organization } = useOrganization()

  return (
    <>
      <div
        className={cn(
          'flex flex-1 items-center justify-start gap-4 border-b p-2 last:border-b-0',
          !notification.isRead && 'bg-sky-50',
        )}
      >
        <Avatar className="h-8 w-8">
          <DeviceImageWithFallback
            imageUrl={notification.notificationIcon}
            id={notification.deviceId}
          />
        </Avatar>
        <div className="flex flex-1 flex-col items-start justify-start">
          <h4 className="text-sm font-semibold">{notification.deviceName}: </h4>

          <span className="text-sm">{notification.message}</span>

          <div className="mt-1 flex gap-4">
            <Link
              to={`/app/${organization?.id}/${notification.deviceId}`}
              className="text-sm text-skyBlue hover:cursor-pointer hover:underline"
            >
              {t('dashboard.notifications.go_to_device')}
            </Link>
            <span className="text-sm text-skyBlue hover:cursor-pointer hover:underline"></span>
          </div>
        </div>
        <h6 className="whitespace-nowrap text-xs opacity-60">
          {localizedTimeSince(new Date(notification.timestamp), i18n.language)}
        </h6>
      </div>
    </>
  )
}
export default ActivityNotificationRow
