import CloudIcon from '@/assets/icons/cloud.svg?react'
import RainIcon from '@/assets/icons/rain.svg?react'
import SnowIcon from '@/assets/icons/snow.svg?react'
import StormIcon from '@/assets/icons/storm.svg?react'
import SunIcon from '@/assets/icons/sun.svg?react'
import SunCloudIcon from '@/assets/icons/suncloud.svg?react'
import type { WeatherStatus } from '@/data/weather'

function WeatherIcon({
  status,
  size = 'default',
}: {
  status?: WeatherStatus
  size?: 'default' | 'lg' | 'xl'
}) {
  if (!status) return null

  const sizeClassName =
    size === 'default' ? 'w-6 h-6' : size === 'lg' ? 'w-10 h-10' : 'w-16 h-16'

  switch (status) {
    case 'sun':
      return <SunIcon className={sizeClassName} />
    case 'rain':
      return <RainIcon className={sizeClassName} />
    case 'snow':
      return <SnowIcon className={sizeClassName} />
    case 'storm':
      return <StormIcon className={sizeClassName} />
    case 'cloud':
      return <CloudIcon className={sizeClassName} />
    case 'sun_cloud':
      return <SunCloudIcon className={sizeClassName} />
  }
}
export default WeatherIcon
