import { GoogleMap } from '@react-google-maps/api'
import { MinusIcon, PlusIcon } from 'lucide-react'
import type { CSSProperties, ReactNode } from 'react'
import { memo, useEffect, useRef, useState } from 'react'

import { Button } from '@/components/ui/button'

import { useMap } from '../MapProvider'

interface MapViewProps {
  containerStyles?: CSSProperties
  options?: google.maps.MapOptions
  lat: number
  lng: number
  children?: ReactNode
  onClick?: (lat: number, lng: number) => void
}

const defaultOptions = {
  mapTypeId: 'satellite',
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: false,
}
const defaultContainerStyles = {
  width: '100%',
  height: '100%',
}

function MapView({
  containerStyles,
  options,
  lat,
  lng,
  children,
  onClick,
}: MapViewProps) {
  const mapRef = useRef<google.maps.Map>()
  const centerRef = useRef<{ lat: number; lng: number }>()
  const [zoom, setZoom] = useState(16)
  const { isLoaded, loadError } = useMap()

  useEffect(() => {
    const centerMapAroundLocation = (event: Event) => {
      mapRef.current?.setCenter({
        lat: (event as CustomEvent).detail.lat,
        lng: (event as CustomEvent).detail.lng,
      })
    }

    window.addEventListener('locate_me', centerMapAroundLocation)

    return () => {
      window.removeEventListener('locate_me', centerMapAroundLocation)
    }
  }, [])

  if (loadError) {
    return <div>Error loading maps</div>
  }

  if (!isLoaded) {
    return <div>Loading maps</div>
  }

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map
  }

  const handleZoomIn = () => {
    setZoom((prev) => prev + 1)
  }

  const handleZoomOut = () => {
    setZoom((prev) => prev - 1)
  }

  const handleMove = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter()
      if (newCenter) {
        centerRef.current = { lat: newCenter.lat(), lng: newCenter.lng() }
      }
    }
  }

  // Center needs to be stored in a ref - otherwise restored to initial device coordinates when marker is changed
  const center = centerRef.current || { lat, lng }

  return (
    <>
      <GoogleMap
        options={options || defaultOptions}
        mapContainerStyle={containerStyles || defaultContainerStyles}
        zoom={zoom}
        center={center}
        onClick={(e) =>
          onClick && e.latLng ? onClick(e.latLng.lat(), e.latLng.lng()) : null
        }
        onLoad={handleLoad}
        onCenterChanged={handleMove}
      >
        {children}
      </GoogleMap>
      <Button
        variant="secondary"
        className="absolute right-4 top-8 z-50 rounded-full px-2 font-bold text-black hover:bg-gray-50"
        onClick={handleZoomIn}
      >
        <PlusIcon />
      </Button>
      <Button
        variant="secondary"
        className="absolute right-4 top-20 z-50 rounded-full px-2 font-bold text-black hover:bg-gray-50"
        onClick={handleZoomOut}
      >
        <MinusIcon />
      </Button>
    </>
  )
}
export default memo(MapView)
