import { useNavigate } from 'react-router-dom'

import useSelectedOrgId from '@/hooks/useSelectedOrgId'

import OrganizationSelect from '../selects/OrganizationSelect'

function NoOrganizationErrorBoundary() {
  const { selectedOrgId, setSelectedOrgId } = useSelectedOrgId()
  const navigate = useNavigate()

  const handleChange = (value: string) => {
    setSelectedOrgId(Number(value))
    navigate(`/app/${value}`)
  }

  return (
    <div className="mt-4 rounded-md bg-sand p-4 md:mt-8">
      <h4 className="text-xl font-semibold text-concreteGray">
        The organization could not be found or you do not have permissions to
        view it
      </h4>
      <p>Choose from the organization you have access to from the list below</p>
      <div className="mt-8 w-auto">
        <OrganizationSelect
          value={selectedOrgId.toString()}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
export default NoOrganizationErrorBoundary
