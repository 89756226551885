import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'

import FlowTrackLogo from '../assets/flowtrackcover.svg?react'

function CoverPage() {
  const { t } = useTranslation('common')
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center px-8 md:px-16">
      <div>
        <FlowTrackLogo className="m-auto w-full" />
        <div className="w-full text-center">
          <Link to="/account/login" className="mt-10 h-10 ">
            <Button size="lg" className="mt-10 text-lg font-semibold">
              {capitalizeFirstCharacter(t('log in'))}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default CoverPage
