import * as Sentry from '@sentry/react'

import type { CreateUpdateAdminDevice } from '@/components/admin/AdminDeviceSettingsForm'
import { axiosClient } from '@/lib/axios'

import type { DetailedDataPoint } from './dataPoint'

export interface AdminListDevice {
  id: number
  organizationId: number
  organizationName?: string
  name: string
  type: string
  imei: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  status: string
}

export interface AdminDetailedDevice extends AdminListDevice {
  lat: number
  lng: number
  notificationsEnabled: boolean
  description?: string
  materialHeight: number
  gravelDepth: number
  materialDepth: number
  sandDepth: number
  width: number
  length: number
  imageUrl?: string
  distanceUnit: string
  basinSpecId: number
  basinSpecName?: string
  delta: number
  materialPorosity: number
  sandPorosity: number
  gravelPorosity: number
  data: DetailedDataPoint
  minDesiredWaterLevel: number
  maxDesiredWaterLevel: number
  shouldUpdateViaFOTA: boolean
  eligibleForBetaFirmwareVersions: boolean
  waterFlowRate: number
  cycleTime?: number
  pipeDiameter: number
  pipeDistance: number
  systemSlowness: number
  usesDripLines?: boolean
  fertigationFlowRate?: number
  desiredNitrogenPPM?: number
  stockSolutionNitrogenAmount?: number
  sensorOffsetLeft?: number
  sensorOffsetRight?: number
}

export interface PaginatedDevices {
  devices: AdminListDevice[]
  totalCount: number
}

const BASE_URL = `/admin/device`

export const getDevices = async (
  abortSignal: AbortSignal,
  imei?: string | null,
  take = 20,
  page = 0,
  orgId?: string | null,
) => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      take: take.toString(),
      ...(typeof orgId === 'string' && { orgId }),
      ...(typeof imei === 'string' && { imei }),
    })
    const res = await axiosClient.get<PaginatedDevices>(
      `${BASE_URL}?${params.toString()}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const getDevice = async (id: number, abortSignal: AbortSignal) => {
  try {
    const res = await axiosClient.get<AdminDetailedDevice>(
      `${BASE_URL}/${id}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const createDevice = async (
  data: CreateUpdateAdminDevice,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.post<AdminDetailedDevice>(
      `${BASE_URL}`,
      data,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const updateDevice = async (
  id: number,
  data: CreateUpdateAdminDevice,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.put<AdminDetailedDevice>(
      `${BASE_URL}/${id}`,
      data,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const deleteDevice = async (id: number, abortSignal: AbortSignal) => {
  try {
    await axiosClient.delete(`${BASE_URL}/${id}`, { signal: abortSignal })
    return
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}
