import type {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DataTablePagination } from '@/components/admin/DataTablePagination'
import DataTableSearch from '@/components/admin/DataTableSearch'
import { DataTableViewOptions } from '@/components/admin/DataTableViewOptions'
import { Skeleton } from '@/components/ui/skeleton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { useBrowserStorage } from '@/hooks/useBrowserStorage'

import { Button } from '../ui/button'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  filterColumn: string
  entityName: string
  onCreateClick?: () => void
  isLoading?: boolean
}

const SKELETON_ROW_COUNT = 10

export function DataTable<TData, TValue>({
  columns,
  data,
  filterColumn,
  entityName,
  onCreateClick,
  isLoading = false,
}: DataTableProps<TData, TValue>) {
  const { t } = useTranslation(['admin'])
  const [sorting, setSorting] = useBrowserStorage<SortingState>(
    `${entityName}_sorting`,
    [],
    'localStorage',
  )
  const [columnVisibility, setColumnVisibility] =
    useBrowserStorage<VisibilityState>(
      `${entityName}_visibility`,
      {},
      'localStorage',
    )

  // @TODO figure out how to add this to queryparam. Array of objects
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
  })

  return (
    <div>
      <div className="flex items-center justify-between gap-2 py-4">
        <div className="flex-1">
          <DataTableSearch table={table} filterColumn={filterColumn} />
        </div>
        <div className="flex gap-2">
          <DataTableViewOptions table={table} />
          {typeof onCreateClick !== 'undefined' ? (
            <Button
              type="button"
              variant="outline"
              size="icon"
              onClick={onCreateClick}
            >
              +
            </Button>
          ) : null}
        </div>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="whitespace-nowrap">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              Array.from({ length: SKELETON_ROW_COUNT }).map((_, i) => (
                <TableRow className="odd:bg-sky-50 hover:bg-sky-100" key={i}>
                  {columns.map((_, k) => (
                    <TableCell key={k}>
                      <Skeleton className="h-2 w-full" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className="odd:bg-sky-50 hover:bg-sky-100"
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t('data_table.no_results.text')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
}
