import { useTranslation } from 'react-i18next'

import { capitalizeFirstCharacter } from '@/utils/textHelpers'

import { FormControl } from '../../ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select'

const DEVICE_TYPES = [
  'green',
  'tee',
  'bunker',
  'equestrian',
  'equestrian_sand',
  'other',
]

function DeviceTypeSelect({
  onValueChange,
  value,
  disabled,
}: {
  onValueChange: (value: string) => void
  value: string
  disabled?: boolean
}) {
  const { t } = useTranslation('common')

  return (
    <Select onValueChange={onValueChange} value={value} disabled={disabled}>
      <FormControl>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        {DEVICE_TYPES.map((type) => (
          <SelectItem key={type} value={type}>
            {capitalizeFirstCharacter(t(type))}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
export default DeviceTypeSelect
