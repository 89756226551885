import * as Sentry from '@sentry/react'
import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { routes } from './routes'

import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root')!)
const router = createBrowserRouter(routes)

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [import.meta.env.VITE_API_BASE_URL],
  environment: import.meta.env.VITE_SENTRY_ENV,
  replaysSessionSampleRate:
    import.meta.env.VITE_SENTRY_ENV === 'production' ? 0.1 : 1,
  replaysOnErrorSampleRate: 1.0,
})

const AppWithRouter = () => {
  return (
    <Suspense fallback="loading...">
      <RouterProvider router={router} />
    </Suspense>
  )
}

if (import.meta.env.VITE_MSW_STATUS === 'enabled') {
  import('./mocks/browser')
    .then(({ worker }) => {
      worker.start()
    })
    .then(() => {
      root.render(<AppWithRouter />)
    })
} else {
  root.render(<AppWithRouter />)
}
