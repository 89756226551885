import * as Sentry from '@sentry/react'

import type { CreateUpdateOrganization } from '@/components/admin/organization/AddEditOrganizationModal'
import { axiosClient } from '@/lib/axios'

import type { OrganizationUser } from '../user'

export interface AdminOrganization {
  id: number
  name: string
  logoUrl?: string
  website?: string
  headerImageUrl?: string
  language?: string
  distanceUnit: 'meter' | 'feet'
  temperatureUnit: 'celsius' | 'fahrenheit'
  address: string
  lat: number
  lng: number
  timeZone: string
  createdAt: Date
  updatedAt: Date
  organizationUsers: OrganizationUser[]
  eligibleForBetaFirmwareVersions: boolean
}

export interface PaginatedOrganizations {
  organizations: AdminOrganization[]
  totalCount: number
}

const BASE_URL = `/admin/organization`

export const getOrganizations = async (
  abortSignal: AbortSignal,
  includeAdminUsers: boolean,
  name?: string | null,
  take = 20,
  page = 0,
) => {
  try {
    const params = new URLSearchParams({
      includeAdminUsers: includeAdminUsers.toString(),
      page: page.toString(),
      take: take.toString(),
      ...(typeof name === 'string' && { name }),
    })

    const res = await axiosClient.get<PaginatedOrganizations>(
      `${BASE_URL}?${params.toString()}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const getOrganization = async (
  id: number,
  includeAdminUsers: boolean,
  abortSignal: AbortSignal,
) => {
  try {
    const params = new URLSearchParams({
      includeAdminUsers: includeAdminUsers.toString(),
    })

    const res = await axiosClient.get<AdminOrganization>(
      `${BASE_URL}/${id}?${params.toString()}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const createOrganization = async (
  data: CreateUpdateOrganization,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.post(`${BASE_URL}`, data, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const updateOrganization = async (
  id: number,
  data: CreateUpdateOrganization,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.put(`${BASE_URL}/${id}`, data, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const deleteOrganization = async (
  id: number,
  abortSignal: AbortSignal,
) => {
  try {
    await axiosClient.delete(`${BASE_URL}/${id}`, { signal: abortSignal })
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}
