import { useTranslation } from 'react-i18next'

import type { Organization } from '@/data/organization'
import type { Weather } from '@/data/weather'
import { getNumberWithDecimals } from '@/utils/numberHelpers'
import { getTempSuffix } from '@/utils/unitHelpers'

import WatherIcon from './icon-resolvers/WeatherIcon'
import DashboardWindDirectionIcon from './icon-resolvers/WindDirectionIcon'
import WeatherCardValue from './WeatherCardValue'

function WeatherCard({
  weather,
  organization,
}: {
  weather?: Weather
  organization?: Organization
}) {
  const { t } = useTranslation('app')

  const precipitationUnit =
    organization?.distanceUnit === 'meter'
      ? 'mm'
      : organization?.distanceUnit === 'feet'
        ? 'in'
        : ''
  const windUnit =
    organization?.distanceUnit === 'meter'
      ? 'm/s'
      : organization?.distanceUnit === 'feet'
        ? 'mph'
        : ''

  return (
    <>
      <div className="flex items-center justify-between gap-4">
        <WatherIcon status={weather?.status} size="xl" />
        <WeatherCardValue
          isFeatured
          label={weather?.address || ''}
          value={`${getNumberWithDecimals(
            weather?.temperature,
            0,
          )} ${getTempSuffix(organization?.temperatureUnit)}`}
        />
      </div>
      <div className="flex justify-between gap-4 sm:justify-start md:gap-8">
        <WeatherCardValue
          label={t('dashboard.weather_card.precipitation_header')}
          value={`${getNumberWithDecimals(
            weather?.precipitation,
            0,
          )} ${precipitationUnit}`}
        />
        <WeatherCardValue
          label={t('dashboard.weather_card.wind_header')}
          value={`${getNumberWithDecimals(weather?.wind, 2)} ${windUnit}`}
          ValueIcon={
            <DashboardWindDirectionIcon direction={weather?.windDirection} />
          }
        />
        <WeatherCardValue
          label={t('dashboard.weather_card.humidity_header')}
          value={`${getNumberWithDecimals(weather?.humidity, 0)}%`}
        />
      </div>
    </>
  )
}
export default WeatherCard
