import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getWeather } from '@/data/weather'

const DEFAULT_POLLING_INTERVAL = 60000

function useWeather(lat?: number, lng?: number, temperatureUnit?: string) {
  const abortController = new AbortController()

  const units = temperatureUnit === 'fahrenheit' ? 'imperial' : 'metric'

  const { data: weather } = useQuery({
    queryKey: ['weather'],
    queryFn: async () => getWeather(lat!, lng!, units, abortController.signal),
    refetchInterval:
      import.meta.env.VITE_WEATHER_POLLING_INTERVAL || DEFAULT_POLLING_INTERVAL,
    enabled: Boolean(lat) && Boolean(lng),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return weather
}
export default useWeather
