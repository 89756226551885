import ValveClosed from '@/assets/icons/valveclosed.svg?react'
import ValveOpened from '@/assets/icons/valveopened.svg?react'
import type { ValveState } from '@/data/device'

function ValveStateIcon({ valveState }: { valveState?: ValveState }) {
  switch (valveState) {
    case 'open':
      return <ValveOpened />

    case 'closed':
    default:
      return <ValveClosed />
  }
}
export default ValveStateIcon
