import * as Sentry from '@sentry/react'

import { axiosClient } from '@/lib/axios'
import type { CreateAccount } from '@/routes/account/create-account'
import type { ResetPassword } from '@/routes/account/reset-password'
import type { SetPassword } from '@/routes/account/set-password'
import type { ChangePassword } from '@/routes/app/change-password'

import type { DetailedUser } from './user'

type LoginCommand = {
  email: string
  password: string
}

const BASE_URI = `/account`

export const login = async (command: LoginCommand) => {
  return axiosClient.post<DetailedUser>('/account/login', command)
}

export const logout = async () => {
  return axiosClient.post('/account/logout')
}

export const getProfile = async () => {
  const res = await axiosClient.get<DetailedUser>('/account/me')
  return res.data
}

export const changePassword = async (command: ChangePassword) => {
  try {
    const res = await axiosClient.put('/account/change-password', command)
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

// Set password, either via "forgot password" email or via invite
export const setPassword = async (
  ticketId: string,
  command: SetPassword | CreateAccount,
  abortController: AbortController,
) => {
  try {
    const res = await axiosClient.post<DetailedUser>(
      `account/set-password/${ticketId}`,
      command,
      {
        signal: abortController.signal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const resetPassword = async (
  data: ResetPassword,
  abortController: AbortController,
) => {
  try {
    const res = await axiosClient.post(`${BASE_URI}/reset-password`, data, {
      signal: abortController.signal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

// Check if ticket is valid
export const getIsTicketValid = async (id: string) => {
  try {
    await axiosClient.get(`account/ticket/${id}`)
    return true
  } catch (err) {
    return false
  }
}
