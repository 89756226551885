import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getUsers } from '@/data/admin/user'

function useAdminUsers(
  email?: string | null,
  organizationId?: string | null,
  includeAdminUsers?: boolean,
  take?: number,
  page?: number,
) {
  const abortController = new AbortController()
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: [
      'admin-users',
      email,
      organizationId,
      includeAdminUsers,
      take,
      page,
    ],
    queryFn: async () =>
      getUsers(
        abortController.signal,
        email,
        organizationId,
        includeAdminUsers,
        take,
        page,
      ),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return {
    data,
    isLoading,
    refetch,
    isRefetching,
  }
}

export default useAdminUsers
