import { InfoWindow, Marker } from '@react-google-maps/api'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import GreenMapMarker from '@/assets/icons/greenmapmarker.svg?url'
import OrangeMapMarker from '@/assets/icons/orangemapmarker.svg?url'
import RedMapMarker from '@/assets/icons/redmapmarker.svg?url'

function getStatusIcon(status: string) {
  switch (status) {
    case 'low_water':
    case 'broken_data_connection':
    case 'not_operating_optimally':
      return RedMapMarker

    case 'high_water':
    case 'scheduled_maintenance':
      return OrangeMapMarker

    case 'operating_well':
    case 'pending':
      return GreenMapMarker

    default:
      return OrangeMapMarker
  }
}

function MapMarker({
  id,
  name,
  status,
  lat,
  lng,
  navigateOnClick = false,
  showHoverTooltip = true,
}: {
  id: number
  name: string
  status: string
  lat: number
  lng: number
  navigateOnClick?: boolean
  showHoverTooltip?: boolean
}) {
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Marker
      position={{ lat, lng }}
      icon={{
        url: getStatusIcon(status),
        size: new google.maps.Size(32, 32),
        anchor: new google.maps.Point(16, 16),
      }}
      onMouseOver={() => showHoverTooltip && setIsHovered(true)}
      onMouseOut={() => showHoverTooltip && setIsHovered(false)}
      onClick={() =>
        navigateOnClick ? navigate(`${window.location.pathname}/${id}`) : null
      }
    >
      {isHovered && (
        <InfoWindow>
          <h4>{name}</h4>
        </InfoWindow>
      )}
    </Marker>
  )
}

export default MapMarker
