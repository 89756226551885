import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import {
  dismissNotification,
  getAllNotifications,
  readNotification,
} from '@/data/notification'

import type { MutationVariables } from './useNotifications'

const PAGE_SIZE = 20

function useActivity() {
  const abortController = new AbortController()
  const { orgId = '' } = useParams()
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['activity'],
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0 }) =>
      getAllNotifications(orgId, abortController, pageParam, PAGE_SIZE),
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = lastPage.length === PAGE_SIZE ? allPages.length : null
      return nextPage
    },
    enabled: Boolean(orgId),
  })

  const mutation = useMutation({
    mutationFn: async (variables: MutationVariables) => {
      return variables.type === 'read'
        ? readNotification(orgId, variables.id)
        : dismissNotification(orgId, variables.id)
    },
    onSuccess: () => {
      refetch()
    },
  })

  const handleRead = async (id: number) => {
    await mutation.mutateAsync({ id, type: 'read' })
  }

  const handleDismiss = async (id: number) => {
    await mutation.mutateAsync({ id, type: 'dismiss' })
  }

  return {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    error,
    onRead: handleRead,
    onDismiss: handleDismiss,
  }
}
export default useActivity
