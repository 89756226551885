import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import type { DetailedDevice, OpenCloseValve, ValveState } from '@/data/device'

import { Button } from '../../ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form'
import { Input } from '../../ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover'
import { Switch } from '../../ui/switch'

const OpenValveSchema = z.object({
  action: z.string(),
  minutes: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseInt(z.string().parse(val), 10) : val,
    z.number().min(1, 'Must be at least 1 minute'),
  ),
  desiredNitrogenPPM: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseInt(z.string().parse(val), 10) : val,
    z.number().optional().nullable(),
  ),
})

export type OpenValve = z.infer<typeof OpenValveSchema>

function WaterValveActionPopover({
  device,
  currentState,
  onOpenCloseValve,
  disabled,
  showPPMInput = false,
}: {
  device: DetailedDevice
  currentState: ValveState
  onOpenCloseValve?: (data: Omit<OpenCloseValve, 'type'>) => Promise<void>
  disabled?: boolean
  showPPMInput?: boolean
}) {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('app')

  const form = useForm<OpenValve>({
    resolver: zodResolver(OpenValveSchema),
    defaultValues: {
      action: currentState === 'open' ? 'close' : 'open',
      minutes: 1,
      desiredNitrogenPPM: device.desiredNitrogenPPM,
    },
  })

  useEffect(() => {
    form.reset({
      action: currentState === 'open' ? 'close' : 'open',
      minutes: 1,
      desiredNitrogenPPM: device.desiredNitrogenPPM,
    })
    return () => {
      form.reset()
    }
  }, [device])

  const handleSubmit = (data: OpenValve) => {
    if (onOpenCloseValve) {
      onOpenCloseValve({
        action: data.action as 'open' | 'close',
        minutes: data.minutes,
        desiredNitrogenPPM: data.desiredNitrogenPPM,
      })
      setIsOpen(false)
    }
  }

  const shouldOpenTrigger = currentState === 'closed'
  const handleClick = () => {
    if (currentState === 'open') {
      onOpenCloseValve?.({
        action: 'close',
        minutes: 0,
      })
    } else {
      setIsOpen(true)
    }
  }

  const showFertigationBlock =
    typeof device.desiredNitrogenPPM === 'number' &&
    device.desiredNitrogenPPM > 0 &&
    showPPMInput

  return (
    <>
      <Popover
        open={shouldOpenTrigger && isOpen}
        onOpenChange={(open) => setIsOpen(open)}
      >
        <PopoverTrigger onClick={handleClick} asChild disabled={disabled}>
          <Switch checked={currentState === 'open'} />
        </PopoverTrigger>
        <PopoverContent className="rounded-3xl shadow">
          <div>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <div className="flex justify-between pb-2">
                  <h6>{t('water_valve_action_popover.text')}</h6>
                  <div>
                    <FormField
                      control={form.control}
                      name="minutes"
                      render={({ field, fieldState }) => (
                        <FormItem className="flex-1">
                          <FormControl>
                            <Input
                              variant={fieldState.error ? 'error' : 'default'}
                              className="w-12 bg-gray-200 text-right text-black"
                              {...field}
                            />
                          </FormControl>
                          <FormLabel>
                            {t('water_valve_action_popover.minutes')}
                          </FormLabel>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                {showFertigationBlock ? (
                  <div className="flex justify-between pb-2">
                    <h6>{t('water_valve_action_popover.desired_nitrogen')}</h6>
                    <div>
                      <FormField
                        control={form.control}
                        name="desiredNitrogenPPM"
                        render={({ field, fieldState }) => (
                          <FormItem className="flex-1">
                            <FormControl>
                              <Input
                                variant={fieldState.error ? 'error' : 'default'}
                                className="w-12 bg-gray-200 text-right text-black"
                                {...field}
                                value={field.value || ''}
                              />
                            </FormControl>
                            <FormLabel>
                              {t('water_valve_action_popover.ppm')}
                            </FormLabel>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                ) : null}
                <hr />
                <div className="flex justify-between pt-2">
                  <Button variant="ghost" onClick={() => setIsOpen(false)}>
                    {t('water_valve_action_popover.close')}
                  </Button>
                  <Button
                    variant="ghost"
                    className="text-skyBlue"
                    type="submit"
                  >
                    {t('water_valve_action_popover.save')}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </PopoverContent>
      </Popover>
    </>
  )
}
export default WaterValveActionPopover
