import Waterdrop from '@/assets/illustrations/waterdrop.svg?react'

import { Card, type CardProps } from '../ui/card'

interface CardWithDropProps extends CardProps {
  children: JSX.Element
}

function CardWithDrop({ children, ...props }: CardWithDropProps) {
  return (
    <Card {...props} className="m-auto w-full px-10 py-10">
      {children}
      <div className="absolute bottom-0 left-0">
        <Waterdrop className="z-10 hidden -translate-x-1/2 scale-75 lg:block" />
      </div>
    </Card>
  )
}
export default CardWithDrop
