import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getOrganizations } from '@/data/admin/organization'

function useAdminOrganizations(
  disabled?: boolean,
  name?: string | null,
  take?: number,
  page?: number,
  includeAdminUsers = true,
) {
  const abortController = new AbortController()
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['admin-organizations', includeAdminUsers, take, page, name],
    queryFn: async () =>
      getOrganizations(
        abortController.signal,
        includeAdminUsers,
        name,
        take,
        page,
      ),
    enabled: !disabled,
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { data, isLoading, refetch, isRefetching }
}

export default useAdminOrganizations
