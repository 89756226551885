import { Pencil2Icon } from '@radix-ui/react-icons'
import type { ColumnDef, PaginationState } from '@tanstack/react-table'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { RefreshCw } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { StringParam, useQueryParam } from 'use-query-params'

import OrganizationNameAutocomplete from '@/components/admin/OrganizationNameAutocomplete'
import AddEditUserModal from '@/components/admin/user/AddEditUserModal'
import DeleteButtonWithConfirmation from '@/components/admin/user/DeleteButtonWithConfirmationDialog'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import PaginatedTable from '@/components/v2/PaginatedTable'
import type { AdminUser } from '@/data/admin/user'
import useAdminUser from '@/hooks/useAdminUser'
import useAdminUsers from '@/hooks/useAdminUsers'
import { cn } from '@/lib/utils'
import { localizedDateWithTime } from '@/utils/dateHelpers'

export default function UsersPage() {
  const { t } = useTranslation(['admin'])
  const [, setUserId] = useQueryParam('userId', StringParam)
  const [searchText, setSearchText] = useQueryParam('email', StringParam)
  const [email] = useDebounce(searchText, 500)

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const [organizationId, setOrganzationId] = useQueryParam(
    'organizationId',
    StringParam,
  )

  const { onDelete } = useAdminUser()
  const { data, isLoading, refetch, isRefetching } = useAdminUsers(
    email,
    organizationId,
    true,
    pagination.pageSize,
    pagination.pageIndex,
  )

  const columns = useMemo<ColumnDef<AdminUser>[]>(() => {
    return [
      {
        id: 'actions',
        cell: ({ row }) => {
          const { id, organizationUsers } = row.original

          return (
            <div className="flex gap-2">
              <Button
                type="button"
                variant="outline"
                className="h-8 w-8 rounded-full border-yellow-700/50 p-0 text-yellow-700 hover:bg-yellow-50 hover:text-yellow-800"
                onClick={() => setUserId(id.toString())}
              >
                <span className="sr-only">Edit user</span>
                <Pencil2Icon className="h-4 w-4" />
              </Button>

              <DeleteButtonWithConfirmation
                onDelete={async () =>
                  onDelete({
                    id,
                    organizationIds: organizationUsers.map(
                      (ou) => ou.organizationId,
                    ),
                  })
                }
              />
            </div>
          )
        },
      },
      {
        accessorKey: 'email',
        header: t('users.email.header'),
      },
      {
        accessorKey: 'firstName',
        header: t('users.firstName.header'),
      },
      {
        accessorKey: 'lastName',
        header: t('users.lastName.header'),
      },
      {
        accessorKey: 'phoneNumber',
        header: t('users.phoneNumber.header'),
      },
      {
        accessorKey: 'organizationName',
        header: t('users.organizationName.header'),
        cell: ({ row }) => {
          const { organizationUsers, role } = row.original
          return (
            <div className="whitespace-nowrap">
              {role === 'admin'
                ? 'All'
                : organizationUsers.map((ou) => ou.organizationName).join(', ')}
            </div>
          )
        },
      },
      {
        accessorKey: 'role',
        header: t('users.role.header'),
        cell: ({ row }) => {
          const { role } = row.original

          return (
            <span className="whitespace-nowrap">
              {t(`role.${role}`, { ns: 'app' })}
            </span>
          )
        },
      },
      {
        accessorKey: 'isBlocked',
        header: t('users.isBlocked.header'),
        cell: ({ row }) => {
          const { isBlocked, lastFailedLoginAt } = row.original

          if (!isBlocked || !lastFailedLoginAt) return null
          return (
            <span className="whitespace-nowrap">
              {localizedDateWithTime(new Date(lastFailedLoginAt))}
            </span>
          )
        },
      },
      {
        accessorKey: 'lastLoginAt',
        header: t('users.lastLoginAt.header'),
        cell: ({ row }) => {
          const { lastLoginAt } = row.original

          if (!lastLoginAt) return null
          return (
            <span className="whitespace-nowrap">
              {localizedDateWithTime(new Date(lastLoginAt))}
            </span>
          )
        },
      },
      {
        accessorKey: 'updatedAt',
        header: t('users.updatedAt.header'),
        cell: ({ row }) => {
          const { updatedAt } = row.original

          if (!updatedAt) return null
          return (
            <span className="whitespace-nowrap">
              {localizedDateWithTime(new Date(updatedAt))}
            </span>
          )
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('users.createdAt.header'),
        cell: ({ row }) => {
          const { createdAt } = row.original

          if (!createdAt) return null
          return (
            <span className="whitespace-nowrap">
              {localizedDateWithTime(new Date(createdAt))}
            </span>
          )
        },
      },
      {
        accessorKey: 'isEmailVerified',
        header: '',
        cell: ({ row }) => {
          const { isEmailVerified } = row.original
          return (
            <Badge
              className={cn(
                'whitespace-nowrap',
                isEmailVerified
                  ? 'border-green-300 bg-green-100 text-green-700 hover:bg-green-100'
                  : 'border-red-300 bg-red-100 text-red-700 hover:bg-red-100',
              )}
            >
              {isEmailVerified ? 'Email verified' : 'Email unverified'}
            </Badge>
          )
        },
      },
    ]
  }, [])

  const table = useReactTable({
    data: data ? data.users || [] : [],
    columns,
    pageCount: data?.totalCount
      ? Math.ceil(data.totalCount / pagination.pageSize)
      : -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <div>
      <div className="px-4">
        <div className="relative my-8">
          <h2 className="text-3xl">Users</h2>
          <div className="absolute right-0 top-0">
            <Button
              variant="outline"
              onClick={async () => refetch()}
              disabled={isRefetching}
            >
              <RefreshCw
                className={cn('h-4 w-4', isRefetching && 'animate-spin')}
              />
              <span className="pl-2 text-base">Refresh</span>
            </Button>
          </div>
        </div>
        <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap">
          <Input
            defaultValue={searchText ?? ''}
            onChange={(e) => setSearchText(e.currentTarget.value)}
            placeholder="Search by email..."
            className="max-w-sm"
          />
          <div className="w-full max-w-xs">
            <OrganizationNameAutocomplete
              value={organizationId ? organizationId : undefined}
              onSelect={(val) => setOrganzationId(val === 'all' ? null : val)}
            />
          </div>
          <div className="flex flex-1 justify-end">
            <Button
              type="button"
              variant="outline"
              size="icon"
              onClick={() => setUserId('new')}
            >
              +
            </Button>
          </div>
        </div>
        <PaginatedTable<AdminUser> table={table} isLoading={isLoading} />
      </div>
      <AddEditUserModal />
    </div>
  )
}
