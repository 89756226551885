import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'

import LocationPin from '@/assets/icons/mappin.svg?react'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'

import { useMap } from '../MapProvider'

function LocationAutocomplete({
  value,
  onChange,
  disabled,
}: {
  value: string
  onChange: (address: string, lat: number, lng: number) => void
  disabled?: boolean
}) {
  const [tempAddress, setTempAddress] = useState<string>()
  const { t } = useTranslation(['app', 'common'])
  const { isLoaded, loadError } = useMap()

  if (!isLoaded || loadError) return null

  const handleSelect = async (address: string) => {
    setTempAddress(address)
    const results = await geocodeByAddress(address)
    if (results && results.length > 0) {
      const coordinates = await getLatLng(results[0])
      onChange(address, coordinates.lat, coordinates.lng)
    }
  }

  return (
    <PlacesAutocomplete
      value={tempAddress || value}
      onChange={(val) => setTempAddress(val)}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div className="relative">
          <Input
            {...getInputProps({
              placeholder: t('location_autocomplete.search_places'),
            })}
            disabled={disabled}
          />
          {suggestions.length > 0 && (
            <div className="absolute left-0 right-0 rounded-md bg-white shadow-md">
              {suggestions.map((suggestion) => {
                return (
                  <div
                    className={cn(
                      'flex w-full cursor-pointer items-center gap-2 border-b border-b-darkSand py-2 text-base hover:bg-whiteSmoke',
                      suggestion.active && 'bg-whiteSmoke',
                    )}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    <LocationPin />
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  )
}
export default LocationAutocomplete
