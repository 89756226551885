import { zodResolver } from '@hookform/resolvers/zod'
import type { TFunction } from 'i18next'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import PasswordInput from '@/components/v2/PasswordInput'
import useAccount from '@/hooks/useAccount'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

const SetPasswordSchema = (t: TFunction) =>
  z.object({
    email: z.string().email(),
    password: z
      .string()
      .min(8, {
        message: t('password.min_length_validation'),
      })
      .max(100, {
        message: t('password.max_length_validation'),
      }),
  })

export type SetPassword = z.infer<ReturnType<typeof SetPasswordSchema>>

function SetPasswordPage() {
  const { t } = useTranslation('account')
  const [email] = useQueryParam('email', withDefault(StringParam, ''))
  const { id } = useParams()
  const { onSetPassword } = useAccount()
  const { toast } = useToast()

  const form = useForm<SetPassword>({
    resolver: zodResolver(SetPasswordSchema(t)),
    defaultValues: {
      email,
      password: '',
    },
  })

  const isSubmitting = form.formState.isSubmitting

  const handleSubmit = async (data: SetPassword) => {
    try {
      const user = await onSetPassword({ ticketId: id!, data })
      toast({
        title: t('set_password.successful_header'),
        description: t('set_password.successful_description'),
        className: successfulToastClassNames,
      })
      const defaultOrgId = user.organizationUsers?.[0]?.organizationId
      window.location.href = `/app/${defaultOrgId}`
    } catch (err) {
      toast({
        title: t('set_password.failure_header'),
        description: t('set_password.failure_description'),
        className: failureToastClassNames,
        duration: 0,
      })
    }
  }

  return (
    <>
      <h1 className="my-4 text-center text-4xl font-semibold text-black">
        {t('set_password.heading')}
      </h1>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="my-4 flex flex-col gap-2"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormControl>
                  <Input
                    variant={fieldState.error ? 'error' : 'default'}
                    disabled
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormControl>
                  <PasswordInput
                    placeholder={t('set_password.new_password_placeholder')}
                    variant={fieldState.error ? 'error' : 'default'}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            className="mt-2 w-full font-bold"
            type="submit"
            isLoading={isSubmitting}
          >
            {t('set_password.primary_action')}
          </Button>
        </form>
      </Form>
      <hr className="my-4 border-darkSand" />
      <div className="my-4 text-center">
        <Trans i18nKey="set_password.login_link" t={t}>
          Already know your password?{' '}
          <Link
            to="/account/login"
            className="font-semibold text-skyBlue hover:underline"
          >
            Log in here
          </Link>
        </Trans>
      </div>
    </>
  )
}
export default SetPasswordPage
