import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getFirmwareVersions } from '@/data/admin/firmware'

function useFirmwareVersions() {
  const abortController = new AbortController()
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['firmware-versions'],
    queryFn: async () => getFirmwareVersions(abortController.signal),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { data, isLoading, refetch, isRefetching }
}

export default useFirmwareVersions
