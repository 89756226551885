import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createContext, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { login, logout } from '@/data/account'
import { type DetailedUser } from '@/data/user'
import useProfile from '@/hooks/useProfile'
import useSelectedOrgId from '@/hooks/useSelectedOrgId'

interface AuthState {
  user?: DetailedUser
  isLoading: boolean
  handleLogin: ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => Promise<void>
  handleLogout: () => Promise<void>
}

const AuthContext = createContext<AuthState | undefined>(undefined)

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const queryClient = useQueryClient()
  const [user, setUser] = useState<DetailedUser>()
  const navigate = useNavigate()
  const location = useLocation()
  const { selectedOrgId, setSelectedOrgId } = useSelectedOrgId()

  const { user: profile, isLoading } = useProfile()

  const loginMutation = useMutation({
    mutationKey: ['login'],
    mutationFn: async (loginCommand: { email: string; password: string }) =>
      login(loginCommand),
  })

  const logoutMutation = useMutation({
    mutationKey: ['login'],
    mutationFn: async () => logout(),
    onSuccess: async () => {
      queryClient.clear()
    },
  })

  const handleLogin = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    try {
      const res = await loginMutation.mutateAsync({ email, password })
      setUser(res.data)

      let chosenOrgId = selectedOrgId
      if (!chosenOrgId) {
        if (res.data.organizationUsers?.[0]?.organizationId) {
          const orgId = res.data.organizationUsers?.[0]?.organizationId
          setSelectedOrgId(orgId)
          chosenOrgId = orgId
        } else {
          throw new Error('User has no organization')
        }
      }

      const origin = location.state?.from?.pathname || `/app/${chosenOrgId}`
      navigate(origin)
    } catch (err) {
      throw err
    }
  }

  const handleLogout = async () => {
    try {
      await logoutMutation.mutateAsync()
      setUser(undefined)
    } catch (err) {
      // @TODO handle error
      throw err
    }
  }
  const value = {
    user: user || profile,
    isLoading,
    handleLogin,
    handleLogout,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = (): AuthState => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuth must be used within an AuthProvider`)
  }

  return context
}

export { AuthProvider, useAuth }
