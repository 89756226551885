import { zodResolver } from '@hookform/resolvers/zod'
import { XCircle } from 'lucide-react'
import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { useToast } from '@/components/ui/use-toast'
import FormSectionHeader from '@/components/v2/FormSectionHeader'
import ImageSettings from '@/components/v2/ImageSettings'
import type { AdminUser } from '@/data/admin/user'
import useAdminUser from '@/hooks/useAdminUser'
import { MEMBER_ROLES } from '@/utils/roleHelpers'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

import OrganizationNameAutocomplete from '../OrganizationNameAutocomplete'

import AdminRoleSelect from './AdminUserRoleSelect'

const CreateUpdateUserSchema = z.object({
  email: z.string().email(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  role: z.string(),
  phoneNumber: z.string().optional(),
  emailNotificationsEnabled: z.boolean(),
  inAppNotificationsEnabled: z.boolean(),
  imageUrl: z.string().nullable().optional(),
  organizationUsers: z.array(
    z.object({
      organizationId: z.preprocess(
        (val) =>
          typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
        z.number().min(0),
      ),
      role: z.string(),
    }),
  ),
})

export type CreateUpdateUser = z.infer<typeof CreateUpdateUserSchema>

const getDefaultValues = (data?: AdminUser) => ({
  email: data?.email || '',
  firstName: data?.firstName || '',
  lastName: data?.lastName || '',
  role: data?.role || 'member',
  phoneNumber: data?.phoneNumber || '',
  emailNotificationsEnabled: data?.emailNotificationsEnabled || false,
  inAppNotificationsEnabled: data?.inAppNotificationsEnabled || true,
  imageUrl: data?.imageUrl || '',
  organizationUsers: data?.organizationUsers || [],
})

function AddEditUserModal() {
  const { t } = useTranslation('app')
  const [id, setId] = useQueryParam('userId', StringParam)
  const { toast } = useToast()
  const { data, onCreate, onUpdate } = useAdminUser(id || '')

  const isOpen = Boolean(id) && id !== 'link'
  const isNew = id === 'new'

  const form = useForm<CreateUpdateUser>({
    resolver: zodResolver(CreateUpdateUserSchema),
    defaultValues: getDefaultValues(data),
  })

  const {
    fields: organizationUsers,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: 'organizationUsers',
    shouldUnregister: true,
    keyName: '_id',
  })

  useEffect(() => {
    if (data) {
      form.reset({ ...getDefaultValues(data) })
    }
  }, [data])

  const handleSubmit = async (data: CreateUpdateUser) => {
    try {
      if (isNew) {
        await onCreate({ ...data })
      } else {
        await onUpdate({ id: Number(id!), data: { ...data } })
      }
      toast({
        title: `User ${isNew ? 'created' : 'updated'}!`,
        className: successfulToastClassNames,
      })
      handleClose()
    } catch (err) {
      toast({
        title: `Failed to ${isNew ? 'create' : 'update'} user`,
        className: failureToastClassNames,
      })
    }
  }

  const handleClose = () => {
    setId(null)
    form.reset({ ...getDefaultValues() })
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="max-h-[90%] max-w-3xl overflow-y-scroll">
        <DialogHeader>
          <DialogTitle className="text-3xl text-black">
            {isNew ? 'Create user' : 'Update user'}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="my-4 flex max-w-full flex-col gap-2 overflow-x-hidden px-1 sm:px-2"
          >
            <div className="my-8 flex flex-col gap-4">
              <FormSectionHeader text={'General'} />
              <FormField
                control={form.control}
                name="email"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex gap-2">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex-1">
                      <FormLabel>First name</FormLabel>
                      <FormControl>
                        <Input
                          variant={fieldState.error ? 'error' : 'default'}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex-1">
                      <FormLabel>Last name</FormLabel>
                      <FormControl>
                        <Input
                          variant={fieldState.error ? 'error' : 'default'}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Phone number</FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="role"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Role</FormLabel>
                    <FormControl>
                      <AdminRoleSelect {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="imageUrl"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Image</FormLabel>
                    <FormControl>
                      <ImageSettings
                        entity="user"
                        value={field.value}
                        onChange={(value?: string) => field.onChange(value)}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>

            <div className="relative my-8 flex flex-col gap-4">
              <FormSectionHeader text={'Access'} />
              <Button
                variant="link"
                className="absolute right-0 top-0"
                onClick={() =>
                  // @ts-expect-error - append expect fields that are set by user
                  append({ userId: Number(id) || 0, role: 'member' })
                }
              >
                + Add organization
              </Button>
              {organizationUsers.map((ou, i) => (
                <div className="flex gap-2" key={ou._id}>
                  <div className="flex-1">
                    <FormField
                      control={form.control}
                      name={`organizationUsers.${i}.organizationId`}
                      render={({ field }) => (
                        <FormItem>
                          <OrganizationNameAutocomplete
                            onSelect={field.onChange}
                            value={field.value?.toString()}
                          />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="flex-1">
                    <FormField
                      control={form.control}
                      name={`organizationUsers.${i}.role`}
                      render={({ field }) => (
                        <FormItem>
                          <Select
                            {...field}
                            onValueChange={field.onChange}
                            value={field.value}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {MEMBER_ROLES.map((role) => (
                                <SelectItem key={role} value={role}>
                                  {t(`role.${role}`)}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      )}
                    />
                  </div>

                  <div>
                    <Button
                      size="icon"
                      variant="ghost"
                      onClick={() => remove(i)}
                    >
                      <XCircle className="h-4 w-4 text-red-800" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>

            <div className="my-8 flex flex-col gap-4">
              <FormSectionHeader text={'Notifications'} />
              <FormField
                control={form.control}
                name="emailNotificationsEnabled"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between border-b pb-4">
                    <FormLabel>Email notifications</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>

            <DialogFooter>
              <Button variant="outline" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" isLoading={form.formState.isSubmitting}>
                {isNew ? 'Create' : 'Update'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
export default AddEditUserModal
