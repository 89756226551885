import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { Button } from '@/components/ui/button'
import type { Organization } from '@/data/organization'
import useOverview from '@/hooks/useOverview'
import useWeather from '@/hooks/useWeather'
import { shortLocalizedDateWithTime } from '@/utils/dateHelpers'
import { isOwner } from '@/utils/roleHelpers'

import { Card } from '../ui/card'

import { useAuth } from './AuthProvider'
import ExternalValueDialog from './dialogs/ExternalValueDialog'
import DisplayValueCard from './DisplayValueCard'
import OverviewCard from './OverviewCard'
import OverviewCardSkeleton from './skeletons/OverviewCardSkeleton'
import WeatherCard from './WeatherCard'

function Overview({
  organization,
  isLoading,
}: {
  organization?: Organization
  isLoading: boolean
}) {
  const { t } = useTranslation('app')
  const { user } = useAuth()
  const { orgId } = useParams()
  const [selectedCustomValue, setSelectedCustomValue] = useQueryParam(
    'customValue',
    withDefault(StringParam, null),
  )
  const { overview, isLoading: isOverviewLoading } = useOverview()

  const weather = useWeather(
    organization?.lat,
    organization?.lng,
    organization?.temperatureUnit,
  )

  const showLoading = isLoading || isOverviewLoading
  const showOwnerFunctionality = isOwner(
    user?.organizationUsers?.find((ou) => ou.organizationId === Number(orgId))
      ?.role,
  )

  return (
    <div className="flex flex-col gap-4 border-0 border-gray-300 py-4 sm:border-b">
      <div className="flex justify-between">
        <h1 className="hidden flex-1 text-3xl font-semibold sm:block">
          {t('overview.header')}
        </h1>
        {showOwnerFunctionality ? (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setSelectedCustomValue('new')}
            disabled={showLoading}
          >
            {t('overview.add_button')}
          </Button>
        ) : null}
      </div>

      <div className="flex flex-wrap justify-stretch gap-[4%] lg:gap-4">
        {showLoading ? (
          Array.from(new Array(4)).map((_, i) => (
            <OverviewCardSkeleton key={i} />
          ))
        ) : (
          <>
            <OverviewCard statuses={overview?.statuses} />

            <div className="mt-4 hidden w-[1px] border-l lg:block" />

            <DisplayValueCard
              header={t('overview_card.subheader')}
              subheader={t('overview_card.total')}
              value={overview?.totalDeviceCount?.toString() || ''}
            />
            {overview?.displayValues?.map((displayValue) => (
              <DisplayValueCard
                key={displayValue.id}
                header={displayValue.label}
                subheader={
                  displayValue.updatedAt
                    ? shortLocalizedDateWithTime(
                        new Date(displayValue.updatedAt),
                      )
                    : ''
                }
                value={displayValue.value}
                onEdit={() =>
                  setSelectedCustomValue(displayValue?.id?.toString())
                }
                showEdit={showOwnerFunctionality}
              />
            ))}
          </>
        )}
      </div>
      <div className="flex w-full flex-1 justify-stretch md:hidden">
        <Card variant="blue" className="w-full p-4 md:w-auto">
          <WeatherCard weather={weather} organization={organization} />
        </Card>
      </div>
      <ExternalValueDialog
        isOpen={Boolean(selectedCustomValue)}
        onClose={() => setSelectedCustomValue(null)}
        displayValue={
          selectedCustomValue
            ? overview?.displayValues?.find(
                (dv) => dv.id?.toString() === selectedCustomValue,
              ) || { value: '', label: '' }
            : undefined
        }
      />
    </div>
  )
}
export default Overview
