import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import useOrganizations from '@/hooks/useOrganizations'

function OrganizationSelect({
  onChange,
  value,
}: {
  onChange: (value: string) => void
  value?: string
}) {
  const { data } = useOrganizations()

  return (
    <Select onValueChange={onChange} value={value}>
      <SelectTrigger>
        <SelectValue placeholder="Select organization" />
      </SelectTrigger>
      <SelectContent>
        {(data || []).map((org) => (
          <SelectItem value={org.id.toString()} key={org.id}>
            {org.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
export default OrganizationSelect
