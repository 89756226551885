import type { UseFormReset } from 'react-hook-form'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { useToast } from '@/components/ui/use-toast'
import useAdminDevice from '@/hooks/useAdminDevice'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

import DeviceSettingsFormSkeleton from '../v2/skeletons/DeviceSettingsFormSkeleton'

import type { CreateUpdateAdminDevice } from './AdminDeviceSettingsForm'
import AdminDeviceSettingsForm from './AdminDeviceSettingsForm'

function AddEditDeviceModal() {
  const { toast } = useToast()
  const [id, setId] = useQueryParam('deviceId', StringParam)
  const { data, onCreate, onUpdate, isLoading } = useAdminDevice(id)

  const isOpen = Boolean(id)
  const isNew = id === 'new'
  const mode = isNew ? 'create' : isOpen ? 'update' : ''

  const handleSubmit = async (
    data: CreateUpdateAdminDevice,
    reset: UseFormReset<CreateUpdateAdminDevice>,
  ) => {
    try {
      if (isNew) {
        await onCreate(data)
      } else {
        await onUpdate({ id: Number(id!), data })
      }
      toast({
        title: `Device ${isNew ? 'created' : 'updated'}!`,
        className: successfulToastClassNames,
      })
      reset()
      setId(null)
    } catch (err) {
      toast({
        title: `Failed to ${isNew ? 'create' : 'update'} device`,
        className: failureToastClassNames,
      })
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={() => setId(null)}>
      <DialogContent className="max-h-[90%] max-w-5xl overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-3xl text-black">
            {isNew ? 'Create Device' : 'Update Device'}
          </DialogTitle>
        </DialogHeader>
        {isLoading && !data ? (
          <DeviceSettingsFormSkeleton />
        ) : (
          <AdminDeviceSettingsForm
            mode={mode as 'create' | 'update'}
            onSubmit={handleSubmit}
            device={data}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
export default AddEditDeviceModal
