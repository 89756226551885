import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import type { CreateUpdateOrganization } from '@/components/admin/organization/AddEditOrganizationModal'
import {
  createOrganization,
  deleteOrganization,
  getOrganization,
  updateOrganization,
} from '@/data/admin/organization'

function useAdminOrganization(orgId?: string, includeAdminUsers = true) {
  const abortController = new AbortController()
  const queryClient = useQueryClient()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['admin-organization', orgId, includeAdminUsers],
    queryFn: async () =>
      getOrganization(Number(orgId), includeAdminUsers, abortController.signal),
    enabled: Boolean(orgId) && !isNaN(Number(orgId)),
  })

  const updateMutation = useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: number
      data: CreateUpdateOrganization
    }) => updateOrganization(id, data, abortController.signal),
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['admin-organizations'] })
    },
  })

  const createMutation = useMutation({
    mutationFn: async (data: CreateUpdateOrganization) =>
      createOrganization(data, abortController.signal),
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['admin-organizations'] })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async (id: number) =>
      deleteOrganization(id, abortController.signal),
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['admin-organizations'] })
    },
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return {
    data,
    isLoading,
    refetch,
    onCreate: createMutation.mutateAsync,
    onUpdate: updateMutation.mutateAsync,
    onDelete: deleteMutation.mutateAsync,
  }
}
export default useAdminOrganization
