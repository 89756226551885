import { StringParam, useQueryParam, withDefault } from 'use-query-params'

export function useOpenedModal(
  key: string,
): [
  displayMode: string,
  onChange: (newValue: string) => void,
  onClose: () => void,
] {
  const [openedModal, setOpenedModal] = useQueryParam(
    key,
    withDefault(StringParam, ''),
  )

  const handleChange = (id?: string) => {
    setOpenedModal(id || 'new')
  }

  const handleClose = () => {
    setOpenedModal(null)
  }

  return [openedModal, handleChange, handleClose]
}
