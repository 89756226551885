import InfoIcon from '@/assets/icons/info.svg?react'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

function InfoTooltip({
  text,
  delay = 0,
  position = 'bottom',
}: {
  text: string
  delay?: number
  position?: 'bottom' | 'top' | 'left' | 'right'
}) {
  return (
    <TooltipProvider delayDuration={delay}>
      <Tooltip>
        <TooltipTrigger tabIndex={-1}>
          <InfoIcon className="fill-sky-100" />
        </TooltipTrigger>
        <TooltipContent side={position} className="z-50 max-w-xs">
          {text}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
export default InfoTooltip
