import { useTranslation } from 'react-i18next'

import { supportedLanguages } from '@/i18n'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'

import { FormControl } from '../ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'

function LanguageSelect({
  onChange,
  value,
  disabled,
}: {
  onChange: (value: string) => void
  value: string
  disabled?: boolean
}) {
  const { t } = useTranslation('common')

  return (
    <Select onValueChange={onChange} value={value} disabled={disabled}>
      <FormControl>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        {supportedLanguages.map((lang) => (
          <SelectItem value={lang} key={lang}>
            {capitalizeFirstCharacter(t(`language_${lang}`))}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
export default LanguageSelect
