import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { useToast } from '@/components/ui/use-toast'
import useUser from '@/hooks/useUser'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

import { Button } from '../ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form'
import { Input } from '../ui/input'

import RoleSelect from './selects/RoleSelect'

const CreateUserSchema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  role: z.string(),
})

export type CreateUser = z.infer<typeof CreateUserSchema>

function InviteUserModal() {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation(['app', 'common'])
  const { onCreate } = useUser()
  const { toast } = useToast()

  const form = useForm<CreateUser>({
    resolver: zodResolver(CreateUserSchema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      role: 'member',
    },
  })

  useEffect(() => {
    return () => form.reset()
  }, [])

  const isSubmitting = form.formState.isSubmitting

  const handleSubmit = async (data: CreateUser) => {
    try {
      await onCreate(data)
      setIsOpen(false)
      toast({
        title: t('invite_user_modal.successful_header'),
        description: t('invite_user_modal.successful_description'),
        className: successfulToastClassNames,
      })
      form.reset()
    } catch (err) {
      toast({
        title: t('invite_user_modal.failure_header'),
        description: t('invite_user_modal.failure_description'),
        className: failureToastClassNames,
        duration: 0,
      })
    }
  }

  return (
    <>
      <Button
        variant="link"
        className="text-base"
        onClick={() => setIsOpen(true)}
      >
        + {t('organization_settings.invite_user')}
      </Button>
      <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('organization_settings.invite_user')}</DialogTitle>
            <DialogDescription>
              {t('invite_user_modal.description')}
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form id="invite-form" onSubmit={form.handleSubmit(handleSubmit)}>
              <div className="my-4 flex flex-col gap-4">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex-1">
                      <FormLabel>
                        {capitalizeFirstCharacter(t('email', { ns: 'common' }))}
                      </FormLabel>
                      <FormControl>
                        <Input
                          variant={fieldState.error ? 'error' : 'default'}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex gap-4">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field, fieldState }) => (
                      <FormItem className="flex-1">
                        <FormLabel>
                          {capitalizeFirstCharacter(
                            t('first_name', { ns: 'common' }),
                          )}
                        </FormLabel>
                        <FormControl>
                          <Input
                            variant={fieldState.error ? 'error' : 'default'}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field, fieldState }) => (
                      <FormItem className="flex-1">
                        <FormLabel>
                          {capitalizeFirstCharacter(
                            t('last_name', { ns: 'common' }),
                          )}
                        </FormLabel>
                        <FormControl>
                          <Input
                            variant={fieldState.error ? 'error' : 'default'}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="role"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormLabel>{t('role')}</FormLabel>
                      <FormControl>
                        <RoleSelect
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <DialogFooter className="mt-8">
                <Button
                  type="button"
                  className="font-bold"
                  isLoading={isSubmitting}
                  onClick={form.handleSubmit(handleSubmit)}
                >
                  {t('modal.save')}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default InviteUserModal
