import { Skeleton } from '@/components/ui/skeleton'

const ITEM_COUNT = 30

function ActivityLoadingPage() {
  const loadingItems = Array.from(new Array(ITEM_COUNT))

  return (
    <div className="max-w-4xl w-full mx-auto px-2 py-4 sm:p-4">
      <h2 className="text-2xl font-bold">
        <Skeleton className="w-32 h-8" />
      </h2>
      <div className="mt-8 flex flex-col">
        {loadingItems.map((_, i) => (
          <div
            key={i}
            className="flex justify-start gap-4 items-center flex-1 p-2 border-b last:border-b-0"
          >
            <Skeleton className="w-8 h-8 rounded-full" />
            <div className="flex flex-col justify-start items-start flex-1">
              <div className="flex gap-2 items-center justify-start w-full">
                <h4 className="text-sm w-[80%]">
                  <Skeleton className="w-full h-6" />
                </h4>
              </div>
              <div className="flex gap-4 mt-1">
                <Skeleton className="w-12 h-4" />
                <Skeleton className="w-12 h-4" />
              </div>
            </div>
            <h6 className="text-xs opacity-60 whitespace-nowrap">
              <Skeleton className="w-20 h-6" />
            </h6>
          </div>
        ))}
      </div>
    </div>
  )
}
export default ActivityLoadingPage
