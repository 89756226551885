import { useQuery } from '@tanstack/react-query'

import { getDashboardData } from '@/data/admin/dashboard'

const DEFAULT_REFETCH_INTERVAL =
  import.meta.env.VITE_DEVICES_POLLING_INTERVAL || 15000

function useAdminDashboard() {
  const { data, isLoading } = useQuery({
    queryKey: ['admin-dashboard'],
    queryFn: async () => getDashboardData(),
    refetchInterval: Number(DEFAULT_REFETCH_INTERVAL),
  })

  return { data, isLoading }
}

export default useAdminDashboard
