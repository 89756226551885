import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useAuth } from '@/components/v2/AuthProvider'
import { getDevices } from '@/data/device'

const DEFAULT_REFETCH_INTERVAL =
  import.meta.env.VITE_DEVICES_POLLING_INTERVAL || 15000

function useDevices(
  type?: string,
  status?: (string | null)[],
  searchText?: string,
) {
  const { user } = useAuth()
  const { orgId = '' } = useParams()
  const abortController = new AbortController()
  const queryClient = useQueryClient()

  const {
    data: devices,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ['devices', orgId, type, status, searchText],
    queryFn: async () => {
      const devices = await getDevices(
        orgId,
        type,
        status,
        searchText,
        abortController.signal,
      )

      queryClient.setQueryData(['devices', 'map'], [...(devices || [])])

      return devices
    },
    refetchInterval: Number(DEFAULT_REFETCH_INTERVAL),
    enabled: Boolean(orgId) && Boolean(user),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { devices, isLoading, refetch, isRefetching }
}
export default useDevices
