import { useQuery } from '@tanstack/react-query'

import { getProfile } from '@/data/account'

const useProfile = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => getProfile(),
  })

  return { user: data, isLoading }
}
export default useProfile
