import { useTranslation } from 'react-i18next'

import { Skeleton } from '@/components/ui/skeleton'

import FormSectionHeader from '../FormSectionHeader'

function DeviceSettingsFormSkeleton() {
  const { t } = useTranslation(['app'])
  return (
    <>
      <FormSectionHeader text={t('device_settings.settings_header')} />
      <div className="my-4 flex flex-col gap-4">
        <Skeleton className="h-[64px] w-full rounded-3xl" />
        <Skeleton className="h-[64px] w-full rounded-3xl" />
        <Skeleton className="h-[64px] w-full rounded-3xl" />
        <div className="flex items-center gap-4">
          <Skeleton className="h-[64px] w-full rounded-3xl" />
          <Skeleton className="h-[64px] w-full rounded-3xl" />
          <Skeleton className="h-[64px] w-full rounded-3xl" />
        </div>
        <div className="flex items-center gap-4">
          <Skeleton className="h-[64px] w-full rounded-3xl" />
          <Skeleton className="h-[64px] w-full rounded-3xl" />
          <Skeleton className="h-[64px] w-full rounded-3xl" />
        </div>
        <div className="flex items-center gap-4">
          <Skeleton className="h-[64px] w-full rounded-3xl" />
          <Skeleton className="h-[64px] w-full rounded-3xl" />
          <Skeleton className="h-[64px] w-full rounded-3xl" />
        </div>
        <Skeleton className="h-[64px] w-full rounded-3xl" />
        <div className="flex items-center gap-4">
          <Skeleton className="h-[64px] w-full rounded-3xl" />
          <Skeleton className="h-[64px] w-full rounded-3xl" />
        </div>
        <div className="flex items-center gap-4">
          <Skeleton className="h-[64px] w-full rounded-3xl" />
          <Skeleton className="h-[64px] w-full rounded-3xl" />
        </div>
        <Skeleton className="h-[64px] w-full rounded-3xl" />
      </div>
    </>
  )
}
export default DeviceSettingsFormSkeleton
