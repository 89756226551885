import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useToast } from '@/components/ui/use-toast'
import { useAuth } from '@/components/v2/AuthProvider'
import DeviceSettingsForm from '@/components/v2/DeviceSettingsForm'
import InternalNavigationBar from '@/components/v2/InternalNavigationBar'
import DeviceSettingsFormSkeleton from '@/components/v2/skeletons/DeviceSettingsFormSkeleton'
import useDevice from '@/hooks/useDevice'
import type { CreateUpdateDevice } from '@/utils/deviceHelpers'
import { isOwnerOrManager } from '@/utils/roleHelpers'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

function DeviceSettingsPage() {
  const { t } = useTranslation(['app', 'common'])
  const { deviceId, orgId } = useParams()
  const { device, onUpdate, isLoading } = useDevice(deviceId, 0)
  const { toast } = useToast()
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const handleSubmit = async (data: CreateUpdateDevice) => {
    try {
      await onUpdate(data)
      toast({
        title: 'Settings updated!',
        className: successfulToastClassNames,
      })
      const deviceLink = location.pathname.split('/').slice(0, -1).join('/')
      navigate(deviceLink)
    } catch (err) {
      toast({
        title: 'Update failed!',
        description:
          'Something went wrong when updating the device settings. Please try again in a while',
        className: failureToastClassNames,
      })
    }
  }

  const isAllowedToEdit = isOwnerOrManager(
    user?.organizationUsers?.find((ou) => ou.organizationId === Number(orgId))
      ?.role,
  )

  return (
    <div className="mx-auto w-full max-w-4xl px-2 py-4 sm:p-4">
      <InternalNavigationBar
        backLink={{
          href: `${window.location.pathname.split('/').slice(0, -1).join('/')}`,
          text: t('device_settings.back_text', { name: device?.name || '' }),
        }}
      />

      <div className="mt-8">
        {isLoading && !device ? (
          <DeviceSettingsFormSkeleton />
        ) : (
          <DeviceSettingsForm
            device={device}
            onSubmit={handleSubmit}
            distanceUnit={device?.distanceUnit}
            disabled={!isAllowedToEdit}
          />
        )}
      </div>
    </div>
  )
}
export default DeviceSettingsPage
