import { createContext, useContext } from 'react'

import useSelectedOrgId from '@/hooks/useSelectedOrgId'

type OrgState = {
  selectedOrgId: number
  setSelectedOrgId: (id: number) => void
}

const OrgContext = createContext<OrgState | undefined>(undefined)

const OrgProvider = ({ children }: { children: JSX.Element }) => {
  const { selectedOrgId, setSelectedOrgId } = useSelectedOrgId()

  return (
    <OrgContext.Provider value={{ selectedOrgId, setSelectedOrgId }}>
      {children}
    </OrgContext.Provider>
  )
}

const useOrg = (): OrgState => {
  const context = useContext(OrgContext)
  if (!context) {
    throw new Error(`useOrg must be used within an OrgProvider`)
  }

  return context
}

export { OrgProvider, useOrg }
