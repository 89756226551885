import * as Sentry from '@sentry/react'

import type { CreateUpdateFirmware } from '@/components/admin/AddEditFirmwareModal'
import { axiosClient } from '@/lib/axios'

const BASE_URI = '/admin/firmware'

export interface FirmwareVersion {
  id: number
  name: string
  isBetaVersion: boolean
  updatedAt: string
  isLatest?: boolean
}

export const getFirmwareVersions = async (abortSignal: AbortSignal) => {
  try {
    const res = await axiosClient.get<FirmwareVersion[]>(`${BASE_URI}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const getFirmwareVersion = async (
  id: number,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.get<FirmwareVersion>(`${BASE_URI}/${id}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const updateFirmwareVersion = async (
  id: number,
  data: CreateUpdateFirmware,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.put<FirmwareVersion>(
      `${BASE_URI}/${id}`,
      data,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const createFirmwareVersion = async (
  data: CreateUpdateFirmware,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.post<FirmwareVersion>(`${BASE_URI}`, data, {
      signal: abortSignal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const deleteFirmwareVersion = async (
  id: number,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.delete(`${BASE_URI}/${id}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}
