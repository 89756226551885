import { zodResolver } from '@hookform/resolvers/zod'
import type { TFunction } from 'i18next'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import PasswordInput from '@/components/v2/PasswordInput'
import useAccount from '@/hooks/useAccount'
import { useBrowserStorage } from '@/hooks/useBrowserStorage'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

const CreateAccountSchema = (t: TFunction) =>
  z.object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().email(),
    password: z
      .string()
      .min(8, {
        message: t('password.min_length_validation'),
      })
      .max(100, {
        message: t('password.max_length_validation'),
      }),
  })

export type CreateAccount = z.infer<ReturnType<typeof CreateAccountSchema>>

function CreateAccountPage() {
  const [, setSelectedOrg] = useBrowserStorage<number>('org', 0, 'localStorage')
  const { t } = useTranslation('account')
  const [email] = useQueryParam('email', withDefault(StringParam, ''))
  const [firstName] = useQueryParam('firstName', withDefault(StringParam, ''))
  const [lastName] = useQueryParam('lastName', withDefault(StringParam, ''))
  const { id } = useParams()
  const { onSetPassword } = useAccount()
  const { toast } = useToast()

  const form = useForm<CreateAccount>({
    resolver: zodResolver(CreateAccountSchema(t)),
    defaultValues: {
      email,
      password: '',
      firstName: firstName || '',
      lastName: lastName || '',
    },
  })

  const isSubmitting = form.formState.isSubmitting

  const handleSubmit = async (data: CreateAccount) => {
    try {
      const user = await onSetPassword({ ticketId: id!, data })
      toast({
        title: t('create_account.successful_header'),
        description: t('create_account.successful_description'),
        className: successfulToastClassNames,
      })
      const defaultOrgId = user.organizationUsers?.[0]?.organizationId
      setSelectedOrg(defaultOrgId)
      window.location.href = `/app/${defaultOrgId}`
    } catch (err) {
      toast({
        title: t('create_account.failure_header'),
        description: t('create_account.failure_description'),
        className: failureToastClassNames,
        duration: 0,
      })
    }
  }

  return (
    <>
      <h1 className="my-4 text-center text-4xl font-semibold text-black">
        {t('create_account.heading')}
      </h1>
      <p>{t('create_account.subheading')}</p>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="my-4 flex flex-col gap-2"
        >
          <div className="flex gap-2">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field, fieldState }) => (
                <FormItem className="flex-1">
                  <FormControl>
                    <Input
                      placeholder={t('create_account.first_name_placeholder')}
                      variant={fieldState.error ? 'error' : 'default'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field, fieldState }) => (
                <FormItem className="flex-1">
                  <FormControl>
                    <Input
                      placeholder={t('create_account.last_name_placeholder')}
                      variant={fieldState.error ? 'error' : 'default'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="email"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormControl>
                  <Input
                    variant={fieldState.error ? 'error' : 'default'}
                    disabled
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormControl>
                  <PasswordInput
                    placeholder={t('create_account.password_placeholder')}
                    variant={fieldState.error ? 'error' : 'default'}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            className="mt-2 w-full font-bold"
            type="submit"
            isLoading={isSubmitting}
          >
            {t('create_account.primary_action')}
          </Button>
        </form>
      </Form>
      <p className="text-normal py-2 leading-6">
        <Trans i18nKey="create_account.terms_of_service" t={t}>
          By clicking <span className="font-semibold">“Create account”</span>,
          you acknowledge that you have read and understood, and agree to
          CapillaryFlow's{' '}
          <Link
            to="/account/terms-and-conditions"
            target="_blank"
            className="font-semibold text-skyBlue hover:underline"
          >
            Terms & Conditions
          </Link>{' '}
          and{' '}
          <Link
            to="/account/privacy-policy"
            target="_blank"
            className="font-semibold text-skyBlue hover:underline"
          >
            Privacy Policy
          </Link>
          .
        </Trans>
      </p>
      <hr className="my-4 border-darkSand" />
      <div className="my-4 text-center">
        <Trans i18nKey="create_account.login_link" t={t}>
          Already have an account?{' '}
          <Link
            to="/account/login"
            className="font-semibold text-skyBlue hover:underline"
          >
            Log in here
          </Link>
        </Trans>
      </div>
    </>
  )
}
export default CreateAccountPage
