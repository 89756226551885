import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import type { CreateUpdateAdminDevice } from '@/components/admin/AdminDeviceSettingsForm'
import {
  createDevice,
  deleteDevice,
  getDevice,
  updateDevice,
} from '@/data/admin/device'

function useAdminDevice(id?: string | null) {
  const abortController = new AbortController()
  const queryClient = useQueryClient()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['admin-device', id],
    queryFn: async () => getDevice(Number(id), abortController.signal),
    enabled: Boolean(id) && !isNaN(Number(id)),
  })

  const updateMutation = useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: number
      data: CreateUpdateAdminDevice
    }) => updateDevice(id, data, abortController.signal),
    onSuccess: () => {
      refetch()
      queryClient.refetchQueries({
        queryKey: ['admin-devices'],
      })
    },
  })

  const createMutation = useMutation({
    mutationFn: async (data: CreateUpdateAdminDevice) =>
      createDevice(data, abortController.signal),
    onSuccess: () => {
      refetch()
      queryClient.refetchQueries({
        queryKey: ['admin-devices'],
      })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async (id: number) => deleteDevice(id, abortController.signal),
    onSuccess: () => {
      refetch()
      queryClient.refetchQueries({
        queryKey: ['admin-devices'],
      })
    },
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return {
    data,
    isLoading,
    refetch,
    onCreate: createMutation.mutateAsync,
    onUpdate: updateMutation.mutateAsync,
    onDelete: deleteMutation.mutateAsync,
  }
}
export default useAdminDevice
