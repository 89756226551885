import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import type { CreateUpdateUser } from '@/components/admin/user/AddEditUserModal'
import type { LinkUser } from '@/components/admin/user/LinkUserModal'
import {
  createUser,
  deleteUser,
  getUser,
  linkUser,
  unlinkUser,
  updateUser,
} from '@/data/admin/user'

function useAdminUser(id?: string) {
  const abortController = new AbortController()
  const queryClient = useQueryClient()

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['admin-user', id],
    queryFn: async () =>
      !isNaN(Number(id))
        ? getUser(Number(id), abortController.signal)
        : undefined,
    enabled: Boolean(id) && !isNaN(Number(id)),
  })

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: { id: number; data: CreateUpdateUser }) =>
      updateUser(id, data, abortController.signal),
    onSuccess: (_, variables) => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['admin-users'] })
      queryClient.invalidateQueries({
        queryKey: [
          'admin-organization',
          variables.data.organizationUsers.map((ou) => ou.organizationId),
        ],
      })
      queryClient.invalidateQueries({ queryKey: ['admin-organizations'] })
    },
  })

  const createMutation = useMutation({
    mutationFn: async (data: CreateUpdateUser) =>
      createUser(data, abortController.signal),
    onSuccess: (_, variables) => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['admin-users'] })
      queryClient.invalidateQueries({
        queryKey: [
          'admin-organization',
          variables.organizationUsers.map((ou) => ou.organizationId),
        ],
      })
      queryClient.invalidateQueries({ queryKey: ['admin-organizations'] })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async ({
      id,
      // organizationId is only used for invalidating queries
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      organizationIds,
    }: {
      id: number
      organizationIds?: number[]
    }) => deleteUser(id, abortController.signal),
    onSuccess: (_, variables) => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['admin-users'] })
      if (variables.organizationIds) {
        queryClient.invalidateQueries({
          queryKey: [
            'admin-organization',
            variables.organizationIds?.map((orgId) => orgId),
          ],
        })
        queryClient.invalidateQueries({ queryKey: ['admin-organizations'] })
      }
    },
  })

  const linkMutation = useMutation({
    mutationFn: async ({ data }: { data: LinkUser }) =>
      linkUser(Number(data.userId), data, abortController.signal),
    onSuccess: (_, variables) => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['admin-users'] })
      queryClient.invalidateQueries({ queryKey: ['admin-organizations'] })
      queryClient.invalidateQueries({
        queryKey: ['admin-organization', variables.data.organizationId],
      })
    },
  })

  const unlinkMutation = useMutation({
    mutationFn: async ({ data }: { data: LinkUser }) =>
      unlinkUser(Number(data.userId), data, abortController.signal),
    onSuccess: (_, variables) => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['admin-users'] })
      queryClient.invalidateQueries({ queryKey: ['admin-organizations'] })
      queryClient.invalidateQueries({
        queryKey: ['admin-organization', variables.data.organizationId],
      })
    },
  })

  return {
    data,
    isLoading,
    refetch,
    isRefetching,
    onUpdate: updateMutation.mutateAsync,
    onCreate: createMutation.mutateAsync,
    onDelete: deleteMutation.mutateAsync,
    onLink: linkMutation.mutateAsync,
    onUnlink: unlinkMutation.mutateAsync,
  }
}

export default useAdminUser
