function WeatherCardValue({
  label,
  value,
  isFeatured = false,
  ValueIcon,
}: {
  label: string
  value: string
  isFeatured?: boolean
  ValueIcon?: React.ReactElement
}) {
  return isFeatured ? (
    <div className="flex flex-col items-end">
      <h6 className="text-lg font-semibold">{label}</h6>
      <h4 className="text-4xl font-semibold text-skyBlue">{value}</h4>
    </div>
  ) : (
    <div>
      <span className="block text-xs">{label}</span>
      <div className="flex items-center gap-2 whitespace-nowrap">
        <span className="text-base font-bold">{value}</span>
        {ValueIcon}
      </div>
    </div>
  )
}
export default WeatherCardValue
