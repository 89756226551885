import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Bell from '@/assets/icons/bell.svg?react'
import BellAlert from '@/assets/icons/bellalert.svg?react'
import type { Notification } from '@/data/notification'
import useNotifications from '@/hooks/useNotifications'
import useSelectedOrgId from '@/hooks/useSelectedOrgId'
import { cn } from '@/lib/utils'

import { Button } from '../../ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../ui/dropdown-menu'
import NotificationRow from '../NotificationRow'

function NotificationMenu() {
  const { t } = useTranslation('app')
  const { notificationResponse, onRead } = useNotifications() || {}
  const { selectedOrgId } = useSelectedOrgId()

  const { unreadCount, notifications } = notificationResponse || {}

  const handleClick = async (notification: Notification) => {
    await onRead(notification.id)
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="rounded-full hover:bg-darkSand/70"
        >
          {typeof unreadCount === 'number' && unreadCount > 0 ? (
            <BellAlert className="h-8 w-8" />
          ) : (
            <Bell className="h-8 w-8" />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-4 max-w-xs px-0 pt-0">
        {notifications && notifications.length > 0 ? (
          notifications.map((notification, i) => (
            <DropdownMenuItem
              key={notification.id}
              className={cn(
                'border-b border-b-darkSmoke/30 hover:cursor-pointer',
                !notification.isRead
                  ? 'border-b-darkSmoke/50 bg-whiteSmoke'
                  : 'hover:bg-whiteSmoke',
                i + 1 === notifications?.length && 'border-b-0',
              )}
              asChild
            >
              <Link
                to={`/app/${selectedOrgId}/${notification.deviceId}`}
                onClick={async () => handleClick(notification)}
              >
                <NotificationRow
                  key={notification.id}
                  notification={notification}
                />
              </Link>
            </DropdownMenuItem>
          ))
        ) : (
          <div className="p-2">
            <span className="text-sm text-concreteGray">
              No unread notifications
            </span>
          </div>
        )}
        <div className="mt-2 p-2">
          <Link to={`/app/${selectedOrgId}/activity`}>
            <Button className="w-full">
              {t('dashboard.notifications.view_all')}
            </Button>
          </Link>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
export default NotificationMenu
