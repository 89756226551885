import * as Sentry from '@sentry/react'

import { axiosClient } from '@/lib/axios'

const BASE_URL = '/admin/dashboard'

interface DashboardData {
  devices?: number
  organizations?: number
  users?: number
  dataPoints?: number
  lastDataPointAt: string | null
  updatedAt?: string | null
}

export const getDashboardData = async () => {
  try {
    const res = await axiosClient.get<DashboardData>(BASE_URL)
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}
