import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import useAccount from '@/hooks/useAccount'

const ResetPasswordSchema = z.object({
  email: z.string().email(),
})

export type ResetPassword = z.infer<typeof ResetPasswordSchema>

function ResetPasswordPage() {
  const { t } = useTranslation('account')
  const { onResetPassword } = useAccount()
  const { toast } = useToast()
  const form = useForm<ResetPassword>({
    resolver: zodResolver(ResetPasswordSchema),
    defaultValues: {
      email: '',
    },
  })

  const isSubmitting = form.formState.isSubmitting
  const isSubmitted = form.formState.isSubmitted

  const handleSubmit = async (data: ResetPassword) => {
    try {
      await onResetPassword(data)
      toast({
        title: t('reset_password.successful_header'),
        description: t('reset_password.successful_description'),
        className: 'bg-whiteSmoke border-darkSmoke text-concreteGray',
        duration: 15000,
      })
    } catch (err) {
      toast({
        title: t('reset_password.failure_header'),
        description: t('reset_password.failure_description'),
        className: 'bg-[#FFEFE8] border border-red-200 text-concreteGray',
        duration: 15000,
      })
    }
  }

  return (
    <>
      <h1 className="my-4 text-center text-4xl font-semibold text-black">
        {t('reset_password.heading')}
      </h1>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="my-4 flex flex-col gap-2"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormControl>
                  <Input
                    variant={fieldState.error ? 'error' : 'default'}
                    placeholder={t('reset_password.email_placeholder')}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            className="mt-2 w-full font-bold"
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting || isSubmitted}
          >
            {t('reset_password.primary_action')}
          </Button>
        </form>
      </Form>
      <hr className="my-4 border-darkSand" />
      <div className="my-4 text-center">
        <Trans i18nKey="reset_password.login_link" t={t}>
          Already know your password?{' '}
          <Link
            to="/account/login"
            className="font-semibold text-skyBlue hover:underline"
          >
            Log in here
          </Link>
        </Trans>
      </div>
    </>
  )
}
export default ResetPasswordPage
