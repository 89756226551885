import { TrashIcon } from '@radix-ui/react-icons'
import { Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import useAdminDevice from '@/hooks/useAdminDevice'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

import { toast } from '../ui/use-toast'

function DeleteDeviceButton({
  id,
  imei,
  name,
}: {
  id: number
  imei: string
  name: string
}) {
  const { onDelete } = useAdminDevice(id.toString())

  const form = useForm()

  const handleDelete = async () => {
    try {
      await onDelete(id)
      toast({
        title: 'Device deleted!',
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: `Failed to delete device`,
        className: failureToastClassNames,
      })
    }
  }

  const isSubmitting = form.formState.isSubmitting

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          type="button"
          variant="outline"
          className="h-8 w-8 rounded-full border-red-700/50 p-0 text-red-700 hover:bg-red-50 hover:text-red-800"
          isLoading={isSubmitting}
        >
          <span className="sr-only">Delete Device</span>
          <TrashIcon className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you want to delete the Device {name} with IMEI {imei}?
          </AlertDialogTitle>
          <AlertDialogDescription>
            This action can not be undone
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-red-500 hover:bg-red-500/90"
            onClick={form.handleSubmit(handleDelete)}
            disabled={isSubmitting}
          >
            <span className={isSubmitting ? 'invisible' : 'visible'}>
              Delete
            </span>
            {isSubmitting && <Loader2 className="absolute animate-spin" />}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
export default DeleteDeviceButton
