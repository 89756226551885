import { Pencil2Icon } from '@radix-ui/react-icons'
import type { ColumnDef } from '@tanstack/react-table'

import { DataTable } from '@/components/admin/DataTable'
import { Button } from '@/components/ui/button'
import type { FirmwareVersion } from '@/data/admin/firmware'
import useFirmwareVersion from '@/hooks/useFirmwareVersion'
import { useOpenedModal } from '@/hooks/useOpenedModal'
import { localizedDateWithTime } from '@/utils/dateHelpers'

import { Badge } from '../ui/badge'

import DeleteFirmwareButton from './DeleteFirmwareButton'

function FirmwareVersionsTable({
  firmwareVersions,
  isLoading = false,
}: {
  firmwareVersions: FirmwareVersion[]
  isLoading?: boolean
}) {
  const [, handleOpenModal] = useOpenedModal('firmwareVersionId')
  const { onDelete } = useFirmwareVersion()

  const columns: ColumnDef<FirmwareVersion>[] = [
    {
      id: 'actions',
      cell: ({ row }) => {
        const { id, name } = row.original

        return (
          <div className="flex gap-2">
            <Button
              type="button"
              variant="outline"
              className="h-8 w-8 rounded-full border-yellow-700/50 p-0 text-yellow-700 hover:bg-yellow-50 hover:text-yellow-800"
              onClick={() => handleOpenModal(id.toString())}
            >
              <span className="sr-only">Edit firmware</span>
              <Pencil2Icon className="h-4 w-4" />
            </Button>
            <DeleteFirmwareButton
              onDelete={async () => onDelete(id)}
              name={name}
            />
          </div>
        )
      },
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row }) => {
        const { name, isLatest } = row.original

        return (
          <div className="flex gap-4">
            <span>{name}</span>
            {isLatest ? (
              <Badge
                variant="secondary"
                className="bg-orange-200 text-orange-800 hover:bg-orange-200 hover:text-orange-800"
              >
                Latest stable version
              </Badge>
            ) : null}
          </div>
        )
      },
    },
    {
      accessorKey: 'isBetaVersion',
      header: 'Beta version',
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated',
      cell: ({ row }) => {
        const { updatedAt } = row.original

        if (!updatedAt) return null
        return (
          <span className="whitespace-nowrap">
            {localizedDateWithTime(new Date(updatedAt))}
          </span>
        )
      },
    },
  ]

  return (
    <div className="max-w-full overflow-x-auto">
      <DataTable
        columns={columns}
        data={firmwareVersions}
        isLoading={isLoading}
        filterColumn="name"
        entityName="firmware"
        onCreateClick={() => handleOpenModal('new')}
      />
    </div>
  )
}
export default FirmwareVersionsTable
