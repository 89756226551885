import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  dismissNotification,
  getHeaderNotifications,
  readNotification,
} from '@/data/notification'

const DEFAULT_REFETCH_INTERVAL =
  import.meta.env.VITE_NOTIFICATIONS_POLLING_INTERVAL || 60000

export type MutationVariables = {
  id: number
  type: 'read' | 'dismiss'
}

function useNotifications() {
  const abortController = new AbortController()
  const { orgId = '' } = useParams()
  const { data: notificationResponse, refetch } = useQuery({
    queryKey: ['notifications', orgId],
    queryFn: async () => getHeaderNotifications(orgId, abortController),
    refetchInterval: Number(DEFAULT_REFETCH_INTERVAL),
    enabled: Boolean(orgId),
  })

  const mutation = useMutation({
    mutationFn: async (variables: MutationVariables) => {
      return variables.type === 'read'
        ? readNotification(orgId, variables.id)
        : dismissNotification(orgId, variables.id)
    },
    onSuccess: () => {
      refetch()
    },
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  const handleRead = async (id: number) => {
    await mutation.mutateAsync({ id, type: 'read' })
  }

  const handleDismiss = async (id: number) => {
    await mutation.mutateAsync({ id, type: 'dismiss' })
  }

  return { notificationResponse, onRead: handleRead, onDismiss: handleDismiss }
}

export default useNotifications
