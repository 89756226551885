import BrokenDataConnectionIcon from '@/assets/icons/brokendataconnection.svg?react'
import ErrorIcon from '@/assets/icons/error.svg?react'
import WarningIcon from '@/assets/icons/warning.svg?react'
import type { DetailedDevice } from '@/data/device'
import { errorStatuses, warningStatuses } from '@/utils/statusHelpers'

function DeviceStatusIcon({ status }: { status: DetailedDevice['status'] }) {
  return (
    <>
      {status === 'broken_data_connection' ? (
        <BrokenDataConnectionIcon />
      ) : errorStatuses.includes(status) ? (
        <ErrorIcon />
      ) : warningStatuses.includes(status) ? (
        <WarningIcon />
      ) : null}
    </>
  )
}
export default DeviceStatusIcon
