import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import DeviceImageWithFallback from '@/components/v2/DeviceImageWithFallback'
import DeviceStatusBadge from '@/components/v2/DeviceStatusBadge'
import DeviceTypeIcon from '@/components/v2/icon-resolvers/DeviceTypeIcon'
import DeviceValveIcon from '@/components/v2/icon-resolvers/DeviceValveIcon'
import DeviceVolumeIcon from '@/components/v2/icon-resolvers/DeviceVolumeIcon'
import type { DataPoint } from '@/data/data-point'
import type { Device } from '@/data/device'
import { cn } from '@/lib/utils'
import { shortLocalizedDateWithTime } from '@/utils/dateHelpers'
import {
  mapDeviceStatusToBackgroundColorWithHover,
  operatingWellStatuses,
} from '@/utils/statusHelpers'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'

export type DeviceListCardProps = {
  id: Device['id']
  name: Device['name']
  status: Device['status']
  link: string
  type: Device['type']
  imageUrl: Device['imageUrl']
  volume?: DataPoint['waterLevelPercentage']
  valveState?: DataPoint['fillValveState']
  timestamp?: DataPoint['timestamp']
  waterLevelLeft?: DataPoint['waterLevelLeft']
  waterLevelRight?: DataPoint['waterLevelRight']
}

function DeviceListCard({
  id,
  type,
  name,
  status,
  volume,
  valveState,
  timestamp,
  link,
  imageUrl,
  waterLevelLeft,
  waterLevelRight,
}: DeviceListCardProps) {
  const { t } = useTranslation(['app', 'common'])

  return (
    <Link to={link} className="sm:aspect-square">
      <div
        className={cn(
          'flex h-full w-full cursor-pointer flex-col rounded-3xl px-4 py-3',
          mapDeviceStatusToBackgroundColorWithHover(status),
        )}
      >
        <div className="flex items-center gap-2">
          <DeviceTypeIcon type={type} />
          <div className="flex flex-col items-baseline truncate">
            <span className="text-xs">
              {capitalizeFirstCharacter(t(type, { ns: 'common' }))}
            </span>
            <TooltipProvider delayDuration={0} disableHoverableContent>
              <Tooltip>
                <TooltipTrigger>
                  <h4 className="max-w-full truncate whitespace-nowrap font-semibold leading-5">
                    {name}
                  </h4>
                </TooltipTrigger>
                <TooltipContent>{name}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>

        <hr className={cn('my-4 border-slate-300')} />
        <div className="flex flex-1 flex-col">
          {type === 'bunker' ? (
            <div className="-mt-2">
              <DeviceImageWithFallback
                id={id}
                imageUrl={imageUrl}
                isDashboard={true}
              />
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-2">
                  <DeviceVolumeIcon
                    waterLevelLeft={waterLevelLeft}
                    waterLevelRight={waterLevelRight}
                    status={status}
                  />
                  <span className="text-sm">{t('device_card.volume')}</span>
                </div>
                <span className={'font-semibold'}>
                  {volume ? (
                    `${Number(volume).toFixed(2)}%`
                  ) : (
                    <TooltipProvider delayDuration={0} disableHoverableContent>
                      <Tooltip>
                        <TooltipTrigger>
                          <span className="text-gray-500 opacity-80">
                            {capitalizeFirstCharacter(t(`device_card.unknown`))}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>
                          {t('device_card.data_unknown_tooltip')}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </span>
              </div>

              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-2">
                  <DeviceValveIcon valveState={valveState} />
                  <span className="text-sm">{t('device_card.valve')}</span>
                </div>
                <span className="items-center whitespace-nowrap font-semibold">
                  {valveState ? (
                    capitalizeFirstCharacter(
                      t(`device_card.valve_${valveState}`),
                    )
                  ) : (
                    <TooltipProvider delayDuration={0} disableHoverableContent>
                      <Tooltip>
                        <TooltipTrigger>
                          <span className="text-gray-500 opacity-80">
                            {capitalizeFirstCharacter(t(`device_card.unknown`))}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>
                          {t('device_card.valve_unknown_tooltip')}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </span>
              </div>
            </div>
          )}

          {/* Spacer to push status to bottom */}
          <div className="flex-1" />

          <div className="mt-4 flex items-center gap-2">
            {operatingWellStatuses.includes(status) ? (
              <h6 className="flex-1 text-xs leading-6">
                {capitalizeFirstCharacter(t('updated', { ns: 'common' }))}{' '}
                <span className="whitespace-nowrap">
                  {timestamp
                    ? shortLocalizedDateWithTime(new Date(timestamp))
                    : ''}
                </span>
              </h6>
            ) : (
              <DeviceStatusBadge status={status} />
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default DeviceListCard
