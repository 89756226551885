import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { useToast } from '@/components/ui/use-toast'
import { useAuth } from '@/components/v2/AuthProvider'
import FormSectionHeader from '@/components/v2/FormSectionHeader'
import ImageSettings from '@/components/v2/ImageSettings'
import useUser from '@/hooks/useUser'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

const UserSchema = z.object({
  id: z.number(),
  imageUrl: z.string().nullable().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string(),
  phoneNumber: z.string().optional(),
  role: z.string(),
  emailNotificationsEnabled: z.boolean(),
  inAppNotificationsEnabled: z.boolean(),
  isEmailVerified: z.boolean(),
})

export type Profile = z.infer<typeof UserSchema>

function UserSettingsPage() {
  const { t } = useTranslation(['app', 'common'])
  const { userId } = useParams()
  const { user } = useAuth()
  const { toast } = useToast()
  const { onUpdate } = useUser()
  const navigate = useNavigate()
  const location = useLocation()

  const form = useForm<Profile>({
    resolver: zodResolver(UserSchema),
    defaultValues: {
      id: user?.id,
      isEmailVerified: user?.isEmailVerified || false,
      imageUrl: user?.imageUrl || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || '',
      role: user?.role,
      emailNotificationsEnabled: user?.emailNotificationsEnabled || false,
      inAppNotificationsEnabled: user?.inAppNotificationsEnabled || true,
    },
  })

  useEffect(() => {
    if (user && !form.formState.isDirty) {
      form.reset(user)
    }
  }, [user])

  const handleSubmit = async (data: Profile) => {
    try {
      await onUpdate({ id: Number(userId), data })
      toast({
        title: t('user_settings.successful_header'),
        description: t('user_settings.successful_description'),
        className: successfulToastClassNames,
      })
      const dashboardLink = location.pathname.split('/').slice(0, -2).join('/')
      navigate(dashboardLink)
    } catch (err) {
      toast({
        title: t('user_settings.failure_header'),
        description: t('user_settings.failure_description'),
        className: failureToastClassNames,
        duration: 0,
      })
    }
  }

  const isSubmitting = form.formState.isSubmitting

  return (
    <div className="mx-auto w-full max-w-4xl p-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <FormSectionHeader text={t('user_settings.profile_header')} />
          <div className="my-4 flex flex-col gap-4">
            <FormField
              control={form.control}
              name="imageUrl"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>{t('user_settings.profile_picture')}</FormLabel>
                  <FormControl>
                    <ImageSettings
                      entity="user"
                      value={field.value}
                      onChange={(value?: string) => field.onChange(value)}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="flex flex-wrap gap-4 sm:flex-nowrap">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field, fieldState }) => (
                  <FormItem className="min-w-full flex-1 sm:min-w-fit">
                    <FormLabel className="flex items-center gap-2">
                      {capitalizeFirstCharacter(
                        t('first_name', { ns: 'common' }),
                      )}
                    </FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field, fieldState }) => (
                  <FormItem className="min-w-full flex-1 sm:min-w-fit">
                    <FormLabel className="flex items-center gap-2">
                      {capitalizeFirstCharacter(
                        t('last_name', { ns: 'common' }),
                      )}
                    </FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="email"
              render={({ field, fieldState }) => (
                <FormItem className="min-w-full flex-1 sm:min-w-fit">
                  <FormLabel className="flex items-center gap-2">
                    {capitalizeFirstCharacter(t('email', { ns: 'common' }))}
                  </FormLabel>
                  <FormControl>
                    <Input
                      variant={fieldState.error ? 'error' : 'default'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phoneNumber"
              render={({ field, fieldState }) => (
                <FormItem className="min-w-full flex-1 sm:min-w-fit">
                  <FormLabel className="flex items-center gap-2">
                    {capitalizeFirstCharacter(
                      t('phone_number', { ns: 'common' }),
                    )}
                  </FormLabel>
                  <FormControl>
                    <Input
                      variant={fieldState.error ? 'error' : 'default'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormSectionHeader
            text={t('notifications.header', { ns: 'common' })}
          />
          <div className="my-4 flex flex-col gap-4">
            <FormField
              control={form.control}
              name="emailNotificationsEnabled"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between border-b pb-4">
                  <FormLabel>
                    {capitalizeFirstCharacter(
                      t('email_notifications', { ns: 'common' }),
                    )}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="my-8 flex gap-4">
            <Button
              className="mt-2 font-bold"
              type="submit"
              isLoading={isSubmitting}
            >
              {t('user_settings.save')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
export default UserSettingsPage
