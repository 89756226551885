import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getDevices } from '@/data/admin/device'

function useAdminDevices(
  imei?: string | null,
  take?: number,
  page?: number,
  orgId?: string | null,
) {
  const abortController = new AbortController()
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['admin-devices', take, page, imei, orgId],
    queryFn: async () =>
      getDevices(abortController.signal, imei, take, page, orgId),
    placeholderData: keepPreviousData,
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { data, isLoading, refetch, isRefetching }
}

export default useAdminDevices
