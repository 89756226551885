import { TrashIcon } from '@radix-ui/react-icons'
import { useTranslation } from 'react-i18next'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'

function DeleteOrganizationButtonWithConfirmation({
  onDelete,
  name,
  isDeleting,
}: {
  onDelete: () => void
  name: string
  isDeleting?: boolean
}) {
  const { t } = useTranslation('admin')

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          type="button"
          variant="outline"
          className="h-8 w-8 rounded-full border-red-700/50 p-0 text-red-700 hover:bg-red-50 hover:text-red-800"
          isLoading={isDeleting}
        >
          <span className="sr-only">Delete organization</span>
          <TrashIcon className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t('organization.modal.delete_header', { name })}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {t('organization.modal.delete_description')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            {t('organization.modal.cancel_button')}
          </AlertDialogCancel>
          <AlertDialogAction
            className="bg-red-500 hover:bg-red-500/90"
            onClick={onDelete}
          >
            {t('organization.modal.confirm_button')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
export default DeleteOrganizationButtonWithConfirmation
