import { RefreshCw } from 'lucide-react'

import AddEditFirmwareModal from '@/components/admin/AddEditFirmwareModal'
import FirmwareVersionsTable from '@/components/admin/FirmwareVersionsTable'
import { Button } from '@/components/ui/button'
import useFirmwareVersions from '@/hooks/useFirmwareVersions'
import { cn } from '@/lib/utils'

export default function FirmwarePage() {
  const { data, isLoading, refetch, isRefetching } = useFirmwareVersions()

  return (
    <div>
      <div className="px-4">
        <div className="relative my-8">
          <h2 className="text-3xl">Firmware versions</h2>
          <div className="absolute right-0 top-0">
            <Button
              variant="outline"
              onClick={async () => refetch()}
              disabled={isRefetching}
            >
              <RefreshCw
                className={cn('h-4 w-4', isRefetching && 'animate-spin')}
              />
              <span className="pl-2 text-base">Refresh</span>
            </Button>
          </div>
        </div>
        <FirmwareVersionsTable
          firmwareVersions={data || []}
          isLoading={isLoading}
        />
      </div>
      <AddEditFirmwareModal />
    </div>
  )
}
