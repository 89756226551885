import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getMetadataPoints } from '@/data/admin/metadataPoint'

const DEFAULT_REFETCH_INTERVAL =
  import.meta.env.VITE_ADMIN_METADATAPOINTS_POLLING_INTERVAL || 30000

function useAdminMetadataPoints(
  imei?: string | null,
  take?: number,
  page?: number,
  from?: Date,
  to?: Date,
  orgId?: string | null,
) {
  const abortController = new AbortController()

  const { data, isLoading, status, refetch, isRefetching } = useQuery({
    queryKey: ['admin-metadatapoints', take, page, imei, from, to, orgId],
    queryFn: async () =>
      getMetadataPoints(
        abortController.signal,
        imei,
        take,
        page,
        from,
        to,
        orgId,
      ),
    placeholderData: keepPreviousData,
    refetchInterval: Number(DEFAULT_REFETCH_INTERVAL),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { data, isLoading, refetch, isRefetching, status }
}

export default useAdminMetadataPoints
