import { useMutation } from '@tanstack/react-query'
import { useEffect } from 'react'

import { changePassword, resetPassword, setPassword } from '@/data/account'
import type { CreateAccount } from '@/routes/account/create-account'
import type { ResetPassword } from '@/routes/account/reset-password'
import type { SetPassword } from '@/routes/account/set-password'
import type { ChangePassword } from '@/routes/app/change-password'

function useAccount() {
  const abortController = new AbortController()

  const resetMutation = useMutation({
    mutationFn: async (data: ResetPassword) => {
      return resetPassword(data, abortController)
    },
  })

  const setMutation = useMutation({
    mutationFn: async ({
      ticketId,
      data,
    }: {
      ticketId: string
      data: SetPassword | CreateAccount
    }) => {
      const user = await setPassword(ticketId, data, abortController)
      return user
    },
  })

  const changeMutation = useMutation({
    mutationFn: async (data: ChangePassword) => {
      return changePassword(data)
    },
  })

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return {
    onResetPassword: resetMutation.mutateAsync,
    onSetPassword: setMutation.mutateAsync,
    onChangePassword: changeMutation.mutateAsync,
  }
}
export default useAccount
