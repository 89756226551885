import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import queryString from 'query-string'
import { Outlet } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { AuthProvider } from './components/v2/AuthProvider'
import { MapProvider } from './components/v2/MapProvider'
import { OrgProvider } from './components/v2/OrgProvider'
import './styles.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: false,
    },
    mutations: {
      retry: false,
    },
  },
})

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider>
        <MapProvider>
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
            options={{
              searchStringToObject: queryString.parse,
              objectToSearchString: queryString.stringify,
            }}
          >
            <OrgProvider>
              <Outlet />
            </OrgProvider>
          </QueryParamProvider>
        </MapProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
