import { useTranslation } from 'react-i18next'

import MoistureIcon from '@/assets/icons/moisture.svg?react'
import BunkerIllustration from '@/assets/illustrations/bunker.svg?react'
import type { DetailedDevice } from '@/data/device'

function BunkerOverview({ device }: { device: DetailedDevice }) {
  const { t } = useTranslation('app')

  const showMoisture = typeof device.moisturePercentage === 'number'

  return (
    <div className="relative flex gap-4 overflow-x-hidden">
      <BunkerIllustration />

      {showMoisture ? (
        <div
          className={`hidden flex-1 justify-between text-sm font-bold leading-6 sm:flex`}
        >
          <h6 className="flex flex-1 gap-2">
            <MoistureIcon /> {t('device.details.moisture')}
          </h6>
          <h6>{device.moisturePercentage}%</h6>
        </div>
      ) : null}
    </div>
  )
}
export default BunkerOverview
