import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getBasinSpecs } from '@/data/basin-spec'

function useBasinSpecs() {
  const abortController = new AbortController()
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['basin-specs'],
    queryFn: async () => getBasinSpecs(abortController.signal),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { data, isLoading, refetch, isRefetching }
}

export default useBasinSpecs
