import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getUsers } from '@/data/user'

function useOrganizationUsers(organizationId?: string) {
  const abortController = new AbortController()
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['organization-users', organizationId],
    queryFn: async () => getUsers(organizationId!, abortController.signal),
    enabled: Boolean(organizationId),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { data, isLoading, refetch, isRefetching }
}

export default useOrganizationUsers
