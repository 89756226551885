import { useMutation } from '@tanstack/react-query'

import { generateQrCode } from '@/data/admin/qrCode'
import type { QRCodeData } from '@/routes/admin/qrcode'

function useQRCode() {
  const mutation = useMutation({
    mutationFn: async (data: QRCodeData) => generateQrCode(data),
  })

  return { generate: mutation.mutateAsync }
}
export default useQRCode
