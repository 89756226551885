import { Check, ChevronsUpDown } from 'lucide-react'
import { useState } from 'react'

import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import useAdminUsers from '@/hooks/useAdminUsers'
import { cn } from '@/lib/utils'

function UserEmailAutocomplete({
  email,
  onSelect,
}: {
  email: string
  onSelect: (email: string) => void
}) {
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState('')
  const { data, isLoading } = useAdminUsers(input)
  const users = data?.users || []

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
          disabled={isLoading}
        >
          {email ? email : 'Select user...'}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <Command>
          <CommandInput
            placeholder="Search user..."
            value={input}
            onInput={(e) => setInput(e.currentTarget.value)}
          />
          <CommandEmpty>No users found.</CommandEmpty>
          <CommandGroup>
            {users.map((u) => (
              <CommandItem
                key={u.id}
                value={u.email}
                onSelect={(currentValue) => {
                  onSelect(currentValue === email ? '' : currentValue)
                  setOpen(false)
                }}
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    email === u.email ? 'opacity-100' : 'opacity-0',
                  )}
                />
                {u.email}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
export default UserEmailAutocomplete
