import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import type { CreateUpdateMetadataDownlink } from '@/components/admin/AddEditMetadataDownlinkModal'
import {
  createMetadataDownlink,
  deleteMetadataDownlink,
  getMetadataDownlinkById,
  updateMetadataDownlink,
} from '@/data/admin/metadataDownlink'

function useAdminMetadataDownlink(id?: string | null) {
  const abortController = new AbortController()
  const queryClient = useQueryClient()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['MetadataDownlink', id],
    queryFn: async () =>
      getMetadataDownlinkById(Number(id), abortController.signal),
    enabled: Boolean(id) && !isNaN(Number(id)),
  })

  const updateMutation = useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: number
      data: CreateUpdateMetadataDownlink
    }) => updateMetadataDownlink(id, data, abortController.signal),
    onSuccess: () => {
      refetch()
      queryClient.refetchQueries({
        queryKey: ['MetadataDownlinks'],
      })
    },
  })

  const createMutation = useMutation({
    mutationFn: async (data: CreateUpdateMetadataDownlink) =>
      createMetadataDownlink(data, abortController.signal),
    onSuccess: () => {
      refetch()
      queryClient.refetchQueries({
        queryKey: ['MetadataDownlinks'],
      })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async (id: number) =>
      deleteMetadataDownlink(id, abortController.signal),
    onSuccess: () => {
      refetch()
      queryClient.refetchQueries({
        queryKey: ['MetadataDownlinks'],
      })
    },
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return {
    data,
    isLoading,
    refetch,
    onCreate: createMutation.mutateAsync,
    onUpdate: updateMutation.mutateAsync,
    onDelete: deleteMutation.mutateAsync,
  }
}
export default useAdminMetadataDownlink
