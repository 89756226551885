import * as Sentry from '@sentry/react'

import type { AddEditExternalValue } from '@/components/v2/dialogs/ExternalValueDialog'
import { axiosClient } from '@/lib/axios'

const getBaseUri = (organizationId: string) =>
  `/organization/${organizationId}/externaldisplayvalue`

export const updateExternalDisplayValue = async (
  organizationId: string,
  id: number,
  data: AddEditExternalValue,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.put(
      `${getBaseUri(organizationId)}/${id}`,
      data,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const addExternalDisplayValue = async (
  organizationId: string,
  data: AddEditExternalValue,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.post(`${getBaseUri(organizationId)}`, data, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const deleteExternalDisplayValue = async (
  organizationId: string,
  id: number,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.delete(
      `${getBaseUri(organizationId)}/${id}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}
