import { Check, ChevronsUpDown } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import timezones from '@/utils/timezones.json'

import { Button } from '../../ui/button'
import { FormControl } from '../../ui/form'

function TimeZoneSelect({
  value,
  onChange,
  disabled,
}: {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}) {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('app')

  const tz = useMemo(() => {
    return [...new Set(timezones.map((t) => [...t.utc]).flat())]
  }, [])

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild disabled={disabled}>
        <FormControl>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={isOpen}
            className="w-full justify-between"
          >
            {value
              ? tz.find(
                  (timeZone) => timeZone.toLowerCase() === value.toLowerCase(),
                )
              : null}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput placeholder={t('time_zone_select.placeholder')} />
          <CommandEmpty>{t('time_zone_select.empty')}</CommandEmpty>
          <CommandGroup>
            {tz.map((timeZone) => (
              <CommandItem
                key={timeZone}
                value={timeZone}
                onSelect={(currentValue) => {
                  onChange(currentValue === value ? '' : currentValue)
                  setIsOpen(false)
                }}
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    value === timeZone ? 'opacity-100' : 'opacity-0',
                  )}
                />
                {timeZone}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
export default TimeZoneSelect
