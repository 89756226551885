import * as Sentry from '@sentry/react'

import { axiosClient } from '@/lib/axios'

export interface BasinSpec {
  id?: number
  name: string
  sectionSeparatorHeight: number
  maxValue: number
}

const BASE_URL = '/basinspec'

export const getBasinSpecs = async (abortSignal: AbortSignal) => {
  try {
    const res = await axiosClient.get<BasinSpec[]>(`${BASE_URL}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}
