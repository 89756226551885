import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Fragment } from 'react'

import type { Device } from '@/data/device'

import MapMarker from './MapMarker'

function DeviceListMapMarkers() {
  const queryClient = useQueryClient()
  const { data: devices } = useQuery({
    queryKey: ['devices', 'map'],
    queryFn: () => queryClient.getQueryData<Device[]>(['devices', 'map']),
    refetchInterval: 15000,
  })

  return (
    <>
      {(devices || []).map((device) =>
        device.lat && device.lng ? (
          <Fragment key={device.id}>
            <MapMarker
              id={device.id}
              status={device.status}
              name={device.name}
              lat={device.lat}
              lng={device.lng}
              navigateOnClick={true}
            />
          </Fragment>
        ) : null,
      )}
    </>
  )
}

export default DeviceListMapMarkers
