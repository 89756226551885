import WaterLeftHighIcon from '@/assets/icons/waterlefthigh.svg?react'
import WaterLevelsSameIcon from '@/assets/icons/waterlevelssame.svg?react'
import WaterLowIcon from '@/assets/icons/waterlow.svg?react'
import WaterRightHighIcon from '@/assets/icons/waterrighthigh.svg?react'
import type { Device } from '@/data/device'

interface DashboardWaterLevelProps {
  waterLevelLeft: Device['waterLevelLeft']
  waterLevelRight: Device['waterLevelRight']
  hasWaterLevelError: boolean
}

function WaterLevelIcon({
  waterLevelLeft,
  waterLevelRight,
  hasWaterLevelError,
}: DashboardWaterLevelProps) {
  if (hasWaterLevelError || !waterLevelLeft || !waterLevelRight)
    return <WaterLowIcon />

  if (waterLevelLeft > waterLevelRight) return <WaterLeftHighIcon />
  if (waterLevelLeft < waterLevelRight) return <WaterRightHighIcon />
  return <WaterLevelsSameIcon />
}
export default WaterLevelIcon
