import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { StringParam, useQueryParam } from 'use-query-params'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import UserEmailAutocomplete from '@/components/v2/selects/UserEmailAutocomplete'
import useAdminUser from '@/hooks/useAdminUser'

const LinkUserSchema = z.object({
  userId: z.string(),
  organizationId: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0),
  ),
  role: z.string(),
})

export type LinkUser = z.infer<typeof LinkUserSchema>

function LinkUserModal({
  organizationId,
  organizationName,
}: {
  organizationId?: string | null
  organizationName?: string
}) {
  const { onLink } = useAdminUser()
  const [userId, setUserId] = useQueryParam('userId', StringParam)

  const form = useForm<LinkUser>({
    resolver: zodResolver(LinkUserSchema),
    defaultValues: {
      userId: '',
      organizationId: organizationId ? Number(organizationId) : 0,
      role: 'member',
    },
  })

  const isModalOpen = userId === 'link'

  const handleSubmit = (data: LinkUser) => {
    onLink({ data })
  }

  const handleClose = () => {
    setUserId(null)
    form.reset()
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-h-[90%] max-w-3xl overflow-y-scroll">
        <DialogHeader>
          <DialogTitle className="text-3xl text-black">Link user</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="my-4 flex max-w-full flex-col gap-2 overflow-x-hidden px-1 sm:px-2"
          >
            <div className="flex gap-2">
              <div className="flex-1">
                <FormField
                  name={`organizationId`}
                  render={({ field }) => (
                    <FormItem>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={organizationId?.toString()}
                        disabled
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem
                            value={organizationId?.toString() || ''}
                            key={organizationId}
                          >
                            {organizationName}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex-1">
                <FormField
                  name={`userId`}
                  render={({ field }) => (
                    <FormItem>
                      <UserEmailAutocomplete
                        onSelect={field.onChange}
                        email={field.value}
                      />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <DialogFooter className="mt-8">
              <Button variant="outline" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" isLoading={form.formState.isSubmitting}>
                Save
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
export default LinkUserModal
