import { useTranslation } from 'react-i18next'

import type { Notification } from '@/data/notification'
import { localizedTimeSince } from '@/utils/dateHelpers'

import { Avatar } from '../ui/avatar'

import DeviceImageWithFallback from './DeviceImageWithFallback'

function NotificationRow({ notification }: { notification: Notification }) {
  const { i18n } = useTranslation('app')

  return (
    <>
      <div className={'flex flex-1 items-center justify-start gap-4'}>
        <Avatar className="h-8 w-8">
          <DeviceImageWithFallback
            imageUrl={notification.notificationIcon}
            id={notification.deviceId}
          />
        </Avatar>
        <div className="flex flex-1 flex-col items-start justify-start">
          <h4 className="text-sm font-semibold">{notification.deviceName}: </h4>
          <span>{notification.message}</span>
          <h6 className="whitespace-nowrap text-xs opacity-60">
            {localizedTimeSince(
              new Date(notification.timestamp),
              i18n.language,
            )}
          </h6>
        </div>
      </div>
    </>
  )
}
export default NotificationRow
