import { useTranslation } from 'react-i18next'

import { USER_ROLES } from '@/utils/roleHelpers'

import { FormControl } from '../../ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select'

function AdminRoleSelect({
  onChange,
  value,
  disabled,
}: {
  onChange: (value: string) => void
  value: string
  disabled?: boolean
}) {
  const { t } = useTranslation('app')

  return (
    <Select onValueChange={onChange} value={value} disabled={disabled}>
      <FormControl>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        {USER_ROLES.map((role) => (
          <SelectItem key={role} value={role}>
            {t(`role.${role}`)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
export default AdminRoleSelect
