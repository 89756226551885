import type { DetailedDevice } from '@/data/device'

import Basin from '../app/device/Basin'

function HydroponicsOverview({ device }: { device: DetailedDevice }) {
  return (
    <div className="relative -translate-x-4 scale-90 xs:translate-x-0 xs:scale-100 md:overflow-x-hidden">
      <Basin device={device} />
    </div>
  )
}
export default HydroponicsOverview
