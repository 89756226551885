import { Check, ChevronsUpDown } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import useAdminOrganizations from '@/hooks/useAdminOrganizations'
import { cn } from '@/lib/utils'

function OrganizationNameAutocomplete({
  onSelect,
  value,
}: {
  onSelect: (value: string) => void
  value?: string | null
}) {
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState('')
  const [debouncedSearch] = useDebounce(input, 500)

  const { data, isLoading } = useAdminOrganizations(undefined, debouncedSearch)

  const organizations = data?.organizations || []

  const selectedOrg = useMemo(() => {
    return organizations.find((o) => o.id.toString() === value)
  }, [value, organizations])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
          disabled={isLoading}
        >
          {selectedOrg ? selectedOrg.name : 'Select organization...'}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search organization..."
            value={input}
            onInput={(e) => setInput(e.currentTarget.value)}
          />
          <CommandEmpty>
            {isLoading ? 'Loading...' : 'No organizations found'}
          </CommandEmpty>
          <CommandGroup>
            {organizations.slice(0, 10).map((o) => (
              <CommandItem
                key={o.id}
                value={o.id.toString()}
                onSelect={(currentValue) => {
                  onSelect(currentValue === value ? '' : currentValue)
                  setOpen(false)
                }}
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    value === o.id.toString() ? 'opacity-100' : 'opacity-0',
                  )}
                />
                {o.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
export default OrganizationNameAutocomplete
