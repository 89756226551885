import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import {
  deleteUser,
  getOrganization,
  inviteUser,
  resendUserInvitation,
  updateOrganization,
  type OrganizationUserInvite,
} from '@/data/organization'
import type { UpdateOrganization } from '@/routes/app/organization-settings'

function useOrganization() {
  const { orgId } = useParams()
  const queryClient = useQueryClient()

  const {
    data: organization,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['organization', orgId],
    queryFn: async () => getOrganization(orgId!),
    enabled: Boolean(orgId) && !isNaN(Number(orgId)),
  })

  const changeMutation = useMutation({
    mutationFn: async (data: UpdateOrganization) =>
      updateOrganization(orgId!, data),
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['device'] })
    },
  })

  const inviteMutation = useMutation({
    mutationFn: async (data: OrganizationUserInvite) =>
      inviteUser(orgId!, data),
    onSuccess: () => {
      refetch()
    },
  })

  const resendInvitationMutation = useMutation({
    mutationFn: async (userId: number) => resendUserInvitation(orgId!, userId),
    onSuccess: () => {
      refetch()
    },
  })

  const deleteUserMutation = useMutation({
    mutationFn: async (id: number) => deleteUser(orgId!, id),
    onSuccess: () => {
      refetch()
    },
  })

  return {
    organization,
    isLoading,
    onChange: changeMutation.mutateAsync,
    onInvite: inviteMutation.mutateAsync,
    onResendInvitation: resendInvitationMutation.mutateAsync,
    onDeleteUser: deleteUserMutation.mutateAsync,
  }
}
export default useOrganization
