import type { Table } from '@tanstack/react-table'

import { Input } from '@/components/ui/input'

interface DataTableSearchProps<TData> {
  table: Table<TData>
  filterColumn: string
}

export function DataTableSearch<TData>({
  table,
  filterColumn,
}: DataTableSearchProps<TData>) {
  return (
    <div className="flex items-center">
      <Input
        placeholder={`Search based on ${filterColumn}`}
        value={
          (table.getColumn(filterColumn)?.getFilterValue() as string) ?? ''
        }
        onChange={(event) => {
          table.getColumn(filterColumn)?.setFilterValue(event.target.value)
        }}
        className="max-w-sm"
      />
    </div>
  )
}

export default DataTableSearch
