import { axiosClient } from '@/lib/axios'

export type WeatherStatus =
  | 'sun'
  | 'cloud'
  | 'sun_cloud'
  | 'rain'
  | 'storm'
  | 'snow'
export type WindDirection = 'S' | 'SW' | 'W' | 'NW' | 'N' | 'NE' | 'E' | 'SE'

export interface Weather {
  address: string
  temperature: number
  precipitation: number
  wind: number
  humidity: number
  status: WeatherStatus
  windDirection?: WindDirection
}

export const getWeather = async (
  lat: number,
  lng: number,
  units: string,
  abortSignal?: AbortSignal,
): Promise<Weather> => {
  const res = await axiosClient.get(
    `/weather?lat=${lat}&lng=${lng}&units=${units}`,
    {
      signal: abortSignal,
    },
  )
  return res.data
}
