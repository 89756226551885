import type { ColumnDef } from '@tanstack/react-table'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { RefreshCw, XIcon } from 'lucide-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DateTimeParam,
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import OrganizationNameAutocomplete from '@/components/admin/OrganizationNameAutocomplete'
import { Button } from '@/components/ui/button'
import type { Filter } from '@/components/v2/DataFilter'
import DataFilter, { fields } from '@/components/v2/DataFilter'
import DeviceStatusBadge from '@/components/v2/DeviceStatusBadge'
import PaginatedTable from '@/components/v2/PaginatedTable'
import DeviceImeiAutocomplete from '@/components/v2/selects/DeviceImeiAutocomplete'
import TableDateSelect from '@/components/v2/selects/TableDateSelect'
import type { DetailedDataPoint } from '@/data/admin/dataPoint'
import type { Device } from '@/data/device'
import useAdminDataPoints from '@/hooks/useAdminDataPoints'
import { cn } from '@/lib/utils'
import { localizedDateWithTime } from '@/utils/dateHelpers'

export default function DataPage() {
  const { t } = useTranslation(['admin'])
  const [searchText, setSearchText] = useQueryParam('imei', StringParam)

  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0))
  const [pageSize, setPageSize] = useQueryParam(
    'pageSize',
    withDefault(NumberParam, 20),
  )
  const [orgId, setOrgId] = useQueryParam('orgId', StringParam)
  const [from] = useQueryParam('from', DateTimeParam)
  const [to] = useQueryParam('to', DateTimeParam)

  const [query, setQuery] = useQueryParams({
    filters: withDefault(StringParam, '[]'),
  })

  const filters: Filter[] = JSON.parse(query.filters)

  const { data, isLoading, refetch, isRefetching } = useAdminDataPoints(
    searchText,
    pageSize,
    page,
    from ?? undefined,
    to ?? undefined,
    orgId,
    query.filters,
  )

  const columns = useMemo<ColumnDef<DetailedDataPoint>[]>(() => {
    return [
      {
        accessorKey: 'timestamp',
        header: t('data_points.timestamp'),
        cell: ({ row }) => {
          const { timestamp } = row.original

          if (!timestamp) return null

          return (
            <span className="whitespace-nowrap">
              {localizedDateWithTime(new Date(timestamp))}
            </span>
          )
        },
      },
      {
        accessorKey: 'imei',
        header: t('data_points.imei'),
      },
      {
        accessorKey: 'waterLevelLeft',
        header: t('data_points.water_level_left'),
      },
      {
        accessorKey: 'waterLevelRight',
        header: t('data_points.water_level_right'),
      },
      {
        accessorKey: 'waterLevelPercentage',
        header: t('data_points.water_level_percentage'),
      },
      {
        accessorKey: 'fillValveState',
        header: t('data_points.fill_valve_state'),
      },
      {
        accessorKey: 'drainValveState',
        header: t('data_points.drain_valve_state'),
      },
      {
        accessorKey: 'dripLineValveState',
        header: t('data_points.drip_line_valve_state'),
      },
      {
        accessorKey: 'fertigationValveState',
        header: t('data_points.fertigation_valve_state'),
      },
      {
        accessorKey: 'balanceValveState',
        header: t('data_points.balance_valve_state'),
      },
      {
        accessorKey: 'activeSection',
        header: t('data_points.active_section'),
      },
      {
        accessorKey: 'autoRegulate',
        header: t('data_points.auto_regulate'),
      },
      {
        accessorKey: 'status',
        header: t('data_points.status'),
        cell: ({ row }) => {
          const { status } = row.original

          if (!status) return null

          return (
            <div className="max-w-fit">
              <DeviceStatusBadge status={status as Device['status']} />
            </div>
          )
        },
      },
      {
        accessorKey: 'temperatureLeft',
        header: t('data_points.temperature_left'),
      },
      {
        accessorKey: 'temperatureRight',
        header: t('data_points.temperature_right'),
      },
      {
        accessorKey: 'moistureLeft',
        header: t('data_points.moisture_left'),
      },
      {
        accessorKey: 'moistureRight',
        header: t('data_points.moisture_right'),
      },
      {
        accessorKey: 'ecLeft',
        header: t('data_points.ec_left'),
      },
      {
        accessorKey: 'ecRight',
        header: t('data_points.ec_right'),
      },
      {
        accessorKey: 'varianceLeft',
        header: t('data_points.variance_left'),
      },
      {
        accessorKey: 'varianceRight',
        header: t('data_points.variance_right'),
      },
      {
        accessorKey: 'createdAt',
        header: t('organizations.createdAt.header'),
        cell: ({ row }) => {
          const { createdAt } = row.original

          if (!createdAt) return null

          return (
            <span className="whitespace-nowrap">
              {localizedDateWithTime(new Date(createdAt))}
            </span>
          )
        },
      },
    ]
  }, [])

  const table = useReactTable({
    data: data ? data.dataPoints || [] : [],
    columns,
    pageCount: data?.totalCount ? Math.ceil(data.totalCount / pageSize) : -1,
    state: {
      pagination: {
        pageSize: pageSize,
        pageIndex: page,
      },
    },
    onPaginationChange: (updater) => {
      // make sure updater is callable (to avoid typescript warning)
      if (typeof updater !== 'function') return

      const nextState = updater({ pageIndex: page, pageSize: pageSize })

      setPage(nextState.pageIndex)
      setPageSize(nextState.pageSize)
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  const handleRemoveFilter = (index: number) => {
    const newFilters = filters.filter((_, i) => i !== index)
    setQuery({ filters: JSON.stringify(newFilters) })
  }

  return (
    <div>
      <div className="relative my-8">
        <h2 className="text-3xl">{t('Data')}</h2>
        <div className="absolute right-0 top-0">
          <Button
            variant="outline"
            onClick={async () => refetch()}
            disabled={isRefetching}
          >
            <RefreshCw
              className={cn('h-4 w-4', isRefetching && 'animate-spin')}
            />
            <span className="hidden pl-2 text-base md:block">Refresh</span>
          </Button>
        </div>
      </div>
      <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap">
        <div className="min-w-full sm:min-w-[200px]">
          <OrganizationNameAutocomplete
            onSelect={(val) => setOrgId(val)}
            value={orgId}
          />
        </div>
        <div className="min-w-full sm:min-w-[200px]">
          <DeviceImeiAutocomplete
            orgId={orgId}
            imei={searchText || ''}
            onSelect={(val) => setSearchText(val)}
          />
        </div>
        <div className="min-w-full sm:min-w-[200px]">
          <TableDateSelect />
        </div>
        <DataFilter />
      </div>
      {filters && filters.length > 0 ? (
        <div className="my-4">
          <div className="flex flex-wrap gap-2">
            {filters.map((filter, index) => {
              const currentFilter = fields.find((f) => f.key === filter.field)
              return (
                <div
                  key={index}
                  className="text-primary-foreground flex items-center rounded-full bg-sand px-3 py-2 text-sm"
                >
                  <span>
                    {currentFilter?.name || filter.field}{' '}
                    {t(`data_filter_display.${filter.condition}`)}{' '}
                    {currentFilter?.type === 'enum'
                      ? t(`data_filter.${filter.field}.${filter.value}`)
                      : filter.value}
                  </span>
                  <button
                    onClick={() => handleRemoveFilter(index)}
                    className="ml-2 focus:outline-none"
                    aria-label={`Remove ${filter.field} filter`}
                  >
                    <XIcon className="h-4 w-4" />
                  </button>
                </div>
              )
            })}
          </div>
        </div>
      ) : null}
      <PaginatedTable<DetailedDataPoint> table={table} isLoading={isLoading} />
    </div>
  )
}
