import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { StringParam, useQueryParam } from 'use-query-params'
import { z } from 'zod'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useToast } from '@/components/ui/use-toast'
import useFirmwareVersion from '@/hooks/useFirmwareVersion'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Switch } from '../ui/switch'
import InfoTooltip from '../v2/InfoTooltip'

const CreateUpdateFirmwareSchema = (isNew: boolean) =>
  z.object({
    isBetaVersion: z.boolean(),
    ...(isNew && {
      firmwareFile: z.custom<File | undefined>((file) => file instanceof File, {
        message: 'A firmware file is required',
      }),
    }),
  })

export type CreateUpdateFirmware = z.infer<
  ReturnType<typeof CreateUpdateFirmwareSchema>
>

function AddEditFirmwareModal() {
  const { toast } = useToast()
  const [id, setId] = useQueryParam('firmwareVersionId', StringParam)
  const { data, onCreate, onUpdate } = useFirmwareVersion(id)

  const isOpen = Boolean(id)
  const isNew = id === 'new'

  const form = useForm<CreateUpdateFirmware>({
    resolver: zodResolver(CreateUpdateFirmwareSchema(isNew)),
    defaultValues: {
      firmwareFile: undefined,
      isBetaVersion: data?.isBetaVersion || false,
    },
  })

  useEffect(() => {
    if (data && !form.formState.isDirty) {
      form.reset({
        isBetaVersion: data.isBetaVersion,
      })
    }
  }, [data])

  const handleSubmit = async (data: CreateUpdateFirmware) => {
    try {
      if (isNew) {
        await onCreate(data)
      } else {
        await onUpdate({ id: Number(id!), data })
      }
      toast({
        title: `Firmware version ${isNew ? 'created' : 'updated'}!`,
        className: successfulToastClassNames,
      })
      handleClose()
    } catch (err) {
      toast({
        title: `Failed to ${isNew ? 'create' : 'update'} firmware version`,
        className: failureToastClassNames,
      })
    }
  }

  const handleClose = () => {
    form.reset()
    setId(null)
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="max-h-[90%] max-w-5xl overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-3xl text-black">
            {isNew ? 'Create firmware version' : 'Update firmware version'}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            {isNew ? (
              <FormField
                control={form.control}
                name="firmwareFile"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>File</FormLabel>
                    <FormControl>
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        name={field.name}
                        type="file"
                        accept=".bin"
                        onChange={(e) => {
                          field.onChange(e.target.files?.[0])
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : null}
            <div className="mt-8">
              <FormField
                control={form.control}
                name="isBetaVersion"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>
                      Beta version{' '}
                      <InfoTooltip
                        position="right"
                        text="Beta versions will only be visible for organizations with beta mode enabled"
                      />{' '}
                    </FormLabel>
                    <FormControl>
                      <Switch
                        className={'block'}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mt-8 flex items-center justify-end gap-2">
              <Button variant="ghost" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="default"
                type="submit"
                isLoading={form.formState.isSubmitting}
              >
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
export default AddEditFirmwareModal
