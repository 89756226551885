import * as Sentry from '@sentry/react'

import type { CreateUser } from '@/components/v2/InviteUserModal'
import { axiosClient } from '@/lib/axios'
import type { Profile } from '@/routes/app/user-settings'

export type UserRole = 'admin' | 'organization_admin' | 'user'
export type OrgUserRole = 'owner' | 'manager' | 'member'

export interface DetailedUser {
  id: number
  email: string
  role: OrgUserRole
  firstName?: string
  lastName?: string
  imageUrl?: string
  lastLoginAt?: string
  phoneNumber?: string
  organizationUsers: OrganizationUser[]
  emailNotificationsEnabled?: boolean
  inAppNotificationsEnabled?: boolean
  isEmailVerified: boolean
}

export interface User {
  id: number
  email: string
  role: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  lastLoginAt?: string
  imageUrl?: string
  organizationUsers: OrganizationUser[]
}

export interface OrganizationUser {
  id: number
  userId: number
  organizationId: number
  organizationName: string
  role: string
}

const getBaseUri = (organizationId: string) =>
  `/organization/${organizationId}/user`

export const getUsers = async (
  organizationId: string,
  abortSignal?: AbortSignal,
) => {
  try {
    const res = await axiosClient.get<User[]>(`${getBaseUri(organizationId)}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const getUser = async (organizationId: string, id: number) => {
  try {
    const res = await axiosClient.get<DetailedUser>(
      `${getBaseUri(organizationId)}/${id}`,
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const deleteUser = async (organizationId: string, id: number) => {
  try {
    const res = await axiosClient.delete(`${getBaseUri(organizationId)}/${id}`)
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const updateUser = async (
  organizationId: string,
  id: number,
  data: Profile,
) => {
  try {
    const res = await axiosClient.put<DetailedUser>(
      `${getBaseUri(organizationId)}/${id}`,
      data,
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const createUser = async (organizationId: string, data: CreateUser) => {
  try {
    const res = await axiosClient.post<DetailedUser>(
      `${getBaseUri(organizationId)}`,
      data,
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const sendUserInvite = async (organizationId: string, id: number) => {
  try {
    const res = await axiosClient.post(
      `${getBaseUri(organizationId)}/${id}/send-invite`,
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}
