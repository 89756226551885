import type { Libraries } from '@react-google-maps/api'
import { useLoadScript } from '@react-google-maps/api'
import { createContext, memo, useContext } from 'react'

type MapState = {
  isLoaded: boolean
  loadError?: Error
}

const MapContext = createContext<MapState | undefined>(undefined)

const libraries: Libraries = ['places', 'visualization']

const MapProvider = memo(({ children }: { children: JSX.Element }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries,
  })

  return (
    <MapContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </MapContext.Provider>
  )
})

const useMap = (): MapState => {
  const context = useContext(MapContext)
  if (!context) {
    throw new Error(`useMap must be used within a MapProvider`)
  }

  return context
}

export { MapProvider, useMap }
