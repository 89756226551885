import { zodResolver } from '@hookform/resolvers/zod'
import type { TFunction } from 'i18next'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useToast } from '@/components/ui/use-toast'
import FormSectionHeader from '@/components/v2/FormSectionHeader'
import PasswordInput from '@/components/v2/PasswordInput'
import useAccount from '@/hooks/useAccount'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

const ChangePasswordSchema = (t: TFunction) =>
  z.object({
    currentPassword: z.string(),
    newPassword: z
      .string()
      .min(8, {
        message: t('password.min_length_validation', { ns: 'account' }),
      })
      .max(100, {
        message: t('password.max_length_validation', { ns: 'account' }),
      }),
  })

export type ChangePassword = z.infer<ReturnType<typeof ChangePasswordSchema>>

function ChangePasswordPage() {
  const { t } = useTranslation(['app', 'common', 'account'])
  const { onChangePassword } = useAccount()
  const { toast } = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  const form = useForm<ChangePassword>({
    resolver: zodResolver(ChangePasswordSchema(t)),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
    },
  })

  const isSubmitting = form.formState.isSubmitting
  const isSubmitted = false

  const handleSubmit = async (data: ChangePassword) => {
    try {
      await onChangePassword(data)
      toast({
        title: t('change_password.successful_header'),
        description: t('change_password.successful_description'),
        className: successfulToastClassNames,
      })
      const dashboardLink = location.pathname.split('/').slice(0, -1).join('/')
      navigate(dashboardLink)
    } catch (err) {
      toast({
        title: t('change_password.failure_header'),
        description: t('change_password.failure_description'),
        className: failureToastClassNames,
        duration: 0,
      })
    }
  }

  return (
    <div className="mx-auto w-full max-w-4xl p-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <FormSectionHeader text={t('change_password.header')} />
          <div className="my-4 flex flex-col gap-4">
            <FormField
              control={form.control}
              name="currentPassword"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('change_password.current_password')}</FormLabel>
                  <FormControl>
                    <PasswordInput
                      placeholder=""
                      variant={fieldState.error ? 'error' : 'default'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newPassword"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('change_password.new_password')}</FormLabel>
                  <FormControl>
                    <PasswordInput
                      placeholder=""
                      variant={fieldState.error ? 'error' : 'default'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="my-8 flex gap-4">
              <Button
                className="mt-2 font-bold"
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting || isSubmitted}
              >
                {t('change_password.save')}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  )
}
export default ChangePasswordPage
