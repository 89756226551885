import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import ArrowLeftIcon from '@/assets/icons/arrowleft.svg?react'
import MapPin from '@/assets/icons/mappin.svg?react'
import SettingsIcon from '@/assets/icons/settings.svg?react'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'

import { Button } from '../ui/button'

interface NavLink {
  href: string
  text: string
}

interface InternalNavigationBarProps {
  backLink: NavLink
  additionalLink?: NavLink
  contextText?: string
  onViewOnMapClick?: () => void
}

function InternalNavigationBar({
  backLink,
  additionalLink,
  contextText,
  onViewOnMapClick,
}: InternalNavigationBarProps) {
  const { t } = useTranslation('common')
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-4">
        <Link to={backLink.href} className="flex items-center">
          <Button size="sm" variant="anonymous" className="hover:bg-whiteSmoke">
            <ArrowLeftIcon />
            <span className="hidden font-bold sm:block">{backLink.text}</span>
          </Button>
        </Link>
        {typeof onViewOnMapClick === 'function' ? (
          <Button
            size="sm"
            variant="ghost"
            className="hidden items-center sm:flex"
            onClick={onViewOnMapClick}
          >
            <MapPin />
            {capitalizeFirstCharacter(t('view_on_map'))}
          </Button>
        ) : null}
      </div>
      {contextText ? (
        <h6 className="block flex-1 text-center font-bold sm:hidden">
          {contextText}
        </h6>
      ) : null}
      {additionalLink && (
        <Link to={additionalLink.href}>
          <Button size="sm" variant="anonymous" className="hover:bg-whiteSmoke">
            <SettingsIcon />
            <span className="hidden sm:block">{additionalLink.text}</span>
          </Button>
        </Link>
      )}
    </div>
  )
}
export default InternalNavigationBar
