import ValveClosedIcon from '@/assets/icons/valveclosed.svg?react'
import ValveOpenIcon from '@/assets/icons/valveopened.svg?react'
import type { ValveState } from '@/data/device'

function ValveStateIcon({ valveState }: { valveState?: ValveState }) {
  switch (valveState) {
    case 'open':
      return <ValveOpenIcon />
    case 'closed':
    default:
      return <ValveClosedIcon />
  }
}

export default ValveStateIcon
