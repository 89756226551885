import { memo } from 'react'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'

import { Skeleton } from '../ui/skeleton'

import DeviceListMapMarkers from './map/DeviceListMapMarkers'
import MapView from './map/MapView'

function DeviceListMap({
  lat,
  lng,
  isLoading,
}: {
  lat?: number
  lng?: number
  isLoading: boolean
}) {
  const [isOpen] = useQueryParam('map', withDefault(BooleanParam, false))

  if (isLoading) {
    return (
      <div className="relative hidden min-h-[70vh] rounded-3xl lg:block lg:w-[400px] lg:min-w-[400px]">
        <Skeleton className="absolute h-full w-full rounded-3xl border border-slate-200" />
      </div>
    )
  }

  return (
    <>
      {lat && lng ? (
        <MapView
          containerStyles={{
            width: isOpen ? '100vw' : '400px',
            height: '100%',
          }}
          lat={lat}
          lng={lng}
        >
          <DeviceListMapMarkers />
        </MapView>
      ) : null}
    </>
  )
}
export default memo(DeviceListMap)
