import { Outlet } from 'react-router-dom'

import FlowTrackLogo from '@/assets/flowtracklogo.svg?react'
import { Toaster } from '@/components/ui/toaster'

import CapillaryFlowHeader from '../CapillaryFlowHeader'
import CardWithDrop from '../CardWithDrop'

function AccountLayout() {
  return (
    <div className="flex min-h-screen w-full flex-col justify-between gap-4">
      <div>
        <CapillaryFlowHeader />
      </div>

      <div className="m-auto flex max-h-[600px] min-h-[80vh] w-full max-w-5xl items-center justify-center">
        <CardWithDrop>
          <div className="mx-auto min-h-[400px] max-w-sm">
            <FlowTrackLogo className="mx-auto my-8" />
            <Outlet />
            <Toaster />
          </div>
        </CardWithDrop>
      </div>
      <div className="flex-1" />
    </div>
  )
}

export default AccountLayout
