import { zodResolver } from '@hookform/resolvers/zod'
import { XCircle } from 'lucide-react'
import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { useToast } from '@/components/ui/use-toast'
import FormSectionHeader from '@/components/v2/FormSectionHeader'
import ImageSettings from '@/components/v2/ImageSettings'
import LanguageSelect from '@/components/v2/LanguageSelect'
import DistanceUnitSelect from '@/components/v2/selects/DistanceUnitSelect'
import LocationAutocomplete from '@/components/v2/selects/LocationAutocomplete'
import TemperatureSelect from '@/components/v2/selects/TemperatureSelect'
import TimeZoneSelect from '@/components/v2/selects/TimeZoneSelect'
import UserIdAutocomplete from '@/components/v2/selects/UserIdAutocomplete'
import type { AdminOrganization } from '@/data/admin/organization'
import useAdminOrganization from '@/hooks/useAdminOrganization'
import { useOpenedModal } from '@/hooks/useOpenedModal'
import { MEMBER_ROLES } from '@/utils/roleHelpers'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

const CreateUpdateOrganizationSchema = (isNew: boolean) =>
  z.object({
    id: isNew ? z.number().optional() : z.number(),
    name: z.string().min(1).max(100),
    logoUrl: z.string().optional(),
    website: z.string().optional(),
    headerImageUrl: z.string().optional(),
    language: z.string().optional(),
    distanceUnit: z.string(),
    temperatureUnit: z.string(),
    address: z.string(),
    lat: z.number(),
    lng: z.number(),
    timeZone: z.string(),
    eligibleForBetaFirmwareVersions: z.boolean(),
    organizationUsers: z.array(
      z.object({
        userId: z.preprocess(
          (val) =>
            typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
          z.number().min(0),
        ),
        role: z.string(),
      }),
    ),
  })

export type CreateUpdateOrganization = z.infer<
  ReturnType<typeof CreateUpdateOrganizationSchema>
>

const getDefaultValues = (
  data?: AdminOrganization,
): CreateUpdateOrganization => ({
  id: data?.id,
  name: data?.name || '',
  logoUrl: data?.logoUrl || '',
  website: data?.website || '',
  headerImageUrl: data?.headerImageUrl || '',
  language: data?.language || 'sv',
  distanceUnit: data?.distanceUnit || 'meter',
  temperatureUnit: data?.temperatureUnit || 'celsius',
  address: data?.address || '',
  lat: data?.lat || 0,
  lng: data?.lng || 0,
  eligibleForBetaFirmwareVersions:
    data?.eligibleForBetaFirmwareVersions || false,
  timeZone:
    data?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone || '',
  organizationUsers: data?.organizationUsers || [],
})

function AddEditOrganizationModal() {
  const { t } = useTranslation(['admin', 'common', 'app'])
  const [organizationId, , handleClose] = useOpenedModal('organizationId')
  const { toast } = useToast()
  const { data, onCreate, onUpdate } = useAdminOrganization(
    organizationId,
    false,
  )

  const isOpen = Boolean(organizationId)
  const isNew = organizationId === 'new'

  const form = useForm<CreateUpdateOrganization>({
    resolver: zodResolver(CreateUpdateOrganizationSchema(isNew)),
    defaultValues: getDefaultValues(data),
  })

  const {
    fields: organizationUsers,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: 'organizationUsers',
    shouldUnregister: true,
    keyName: '_id',
  })

  useEffect(() => {
    if (data) {
      form.reset({ ...getDefaultValues(data) })
    }
  }, [data])

  const handleSubmit = async (data: CreateUpdateOrganization) => {
    try {
      if (isNew) {
        await onCreate(data)
      } else {
        await onUpdate({ id: Number(organizationId!), data })
      }
      toast({
        title: `Organization ${isNew ? 'created' : 'updated'}!`,
        className: successfulToastClassNames,
      })
      handleCloseAndReset()
    } catch (err) {
      toast({
        title: `Failed to ${isNew ? 'create' : 'update'} organization`,
        className: failureToastClassNames,
      })
    }
  }

  const handleCloseAndReset = () => {
    handleClose()
    form.reset({ ...getDefaultValues() })
  }

  const isSubmitting = form.formState.isSubmitting

  return (
    <Dialog open={isOpen} onOpenChange={handleCloseAndReset}>
      <DialogContent className="max-h-[90%] max-w-3xl overflow-y-scroll">
        <DialogHeader>
          <DialogTitle className="text-3xl text-black">
            {isNew ? 'Create organization' : 'Update organization'}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="my-4 flex max-w-full flex-col gap-2 overflow-x-hidden px-1 sm:px-2"
          >
            <FormField
              control={form.control}
              name="logoUrl"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Logo</FormLabel>
                  <FormControl>
                    <ImageSettings
                      entity="organization"
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="name"
              render={({ field, fieldState }) => (
                <FormItem className="flex-1">
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input
                      variant={fieldState.error ? 'error' : 'default'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem className="min-w-full flex-1 sm:min-w-fit">
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <LocationAutocomplete
                      value={field.value}
                      onChange={(address, lat, lng) => {
                        form.setValue('address', address)
                        form.setValue('lat', lat)
                        form.setValue('lng', lng)
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-wrap gap-4 sm:flex-nowrap">
              <FormField
                control={form.control}
                name="timeZone"
                render={({ field }) => (
                  <FormItem className="min-w-full flex-1 sm:min-w-fit">
                    <FormLabel>Time zone</FormLabel>
                    <TimeZoneSelect
                      value={field.value}
                      onChange={(value) => {
                        form.setValue('timeZone', value)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="language"
                render={({ field }) => (
                  <FormItem className="min-w-full flex-1 sm:min-w-fit">
                    <FormLabel>Language</FormLabel>
                    <FormControl>
                      <LanguageSelect
                        onChange={field.onChange}
                        value={field.value || ''}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-wrap gap-2 sm:flex-nowrap">
              <FormField
                control={form.control}
                name="distanceUnit"
                render={({ field }) => (
                  <FormItem className="w-full sm:flex-1 ">
                    <FormLabel>Distance unit</FormLabel>
                    <DistanceUnitSelect
                      value={field.value}
                      onChange={field.onChange}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="temperatureUnit"
                render={({ field }) => (
                  <FormItem className="w-full sm:flex-1 ">
                    <FormLabel>Temperature unit</FormLabel>
                    <TemperatureSelect
                      value={field.value}
                      onChange={field.onChange}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="my-8 flex flex-col gap-4">
              <FormSectionHeader
                text={t('device_settings.fota_header', { ns: 'app' })}
              />
              <FormField
                control={form.control}
                name="eligibleForBetaFirmwareVersions"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between border-b pb-4">
                    <FormLabel className="text-base">
                      {t(
                        'device_settings.eligible_for_beta_firmware_versions',
                        { ns: 'app' },
                      )}
                    </FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value || false}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-wrap gap-2 sm:flex-nowrap"></div>

            <div className="relative my-8 flex flex-col gap-4">
              <div>
                <h4 className="w-full text-lg font-semibold">Access</h4>
                <h6 className="w-full border-b border-black pb-2 text-sm">
                  Admins have implicit access even if they are not listed here
                </h6>
              </div>
              <Button
                variant="link"
                className="absolute right-0 top-0"
                onClick={() =>
                  // @ts-expect-error - append expect fields that are set by user
                  append({ role: 'member' })
                }
              >
                + Add user
              </Button>
              {organizationUsers.map((ou, i) => (
                <div className="flex gap-2" key={ou._id}>
                  <div className="flex-1">
                    <FormField
                      name={`organizationUsers.${i}.userId`}
                      render={({ field }) => (
                        <FormItem>
                          <UserIdAutocomplete
                            onSelect={field.onChange}
                            userId={field.value?.toString()}
                          />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="flex-1">
                    <FormField
                      name={`organizationUsers.${i}.role`}
                      render={({ field }) => (
                        <FormItem>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {MEMBER_ROLES.map((role) => (
                                <SelectItem key={role} value={role}>
                                  {t(`role.${role}`, { ns: 'app' })}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      )}
                    />
                  </div>

                  <div>
                    <Button
                      size="icon"
                      variant="ghost"
                      onClick={() => remove(i)}
                    >
                      <XCircle className="h-4 w-4 text-red-800" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>

            <DialogFooter>
              <Button variant="outline" onClick={handleCloseAndReset}>
                {t('organization.modal.close_button')}
              </Button>
              <Button type="submit" isLoading={isSubmitting}>
                {t('organization.modal.save_button')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
export default AddEditOrganizationModal
