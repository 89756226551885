import { Skeleton } from '@/components/ui/skeleton'

function DeviceLoadingPage() {
  return (
    <>
      {/* DeviceIllustrationCard */}
      <Skeleton className="rounded-3xl w-full h-[352px] md:h-[362px]" />

      <div className="flex gap-4 flex-wrap sm:flex-nowrap">
        {/* DeviceImage */}
        <Skeleton className="rounded-3xl w-full h-[239px]" />

        {/* DeviceDetailsCard */}
        <Skeleton className="rounded-3xl w-full h-[239px]" />
      </div>

      {/* DeviceDataChart */}
      <Skeleton className="rounded-3xl w-full h-[374px]" />
    </>
  )
}
export default DeviceLoadingPage
