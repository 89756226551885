import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getDistanceUnits } from '@/data/enum'

function useDistanceUnits() {
  const abortController = new AbortController()

  const { data: units, isLoading } = useQuery({
    queryKey: ['distanceUnits'],
    queryFn: async () => getDistanceUnits(abortController),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { units, isLoading }
}

export default useDistanceUnits
