import { axiosClient } from '@/lib/axios'

export type Notification = {
  id: number
  message: string
  timestamp: string
  isRead: boolean
  isDismissed: boolean
  isResolved: boolean
  deviceId: number
  notificationIcon?: string
  deviceName: string
}

export type NotificationResponse = {
  unreadCount: number
  notifications: Notification[]
}

const getBaseUri = (organizationId: string) =>
  `/organization/${organizationId}/notification`

export const getHeaderNotifications = async (
  organizationId: string,
  abortController: AbortController,
) => {
  const res = await axiosClient.get<NotificationResponse>(
    `${getBaseUri(organizationId)}`,
    {
      signal: abortController.signal,
    },
  )
  return res.data
}

export const getAllNotifications = async (
  organizationId: string,
  abortController: AbortController,
  page: number,
  limit: number,
) => {
  const offset = page * limit
  const res = await axiosClient.get<Notification[]>(
    `${getBaseUri(organizationId)}/all?limit=${limit}&offset=${offset}`,
    {
      signal: abortController.signal,
    },
  )

  return res.data
}

export const readNotification = async (
  organizationId: string,
  id: Notification['id'],
) => {
  await axiosClient.put(`${getBaseUri(organizationId)}/${id}/read`)
}

export const dismissNotification = async (
  organizationId: string,
  id: Notification['id'],
) => {
  await axiosClient.put(`${getBaseUri(organizationId)}/${id}/dismiss`)
}
