import { zodResolver } from '@hookform/resolvers/zod'
import type { AxiosError } from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, Navigate } from 'react-router-dom'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useAuth } from '@/components/v2/AuthProvider'
import { useOrg } from '@/components/v2/OrgProvider'
import PasswordInput from '@/components/v2/PasswordInput'

const LoginSchema = z.object({
  email: z.string().email(),
  password: z.string(),
})

export type Login = z.infer<typeof LoginSchema>

const LoginPage = () => {
  const { selectedOrgId, setSelectedOrgId } = useOrg()

  const { t } = useTranslation('account')
  const form = useForm<Login>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })
  const { handleLogin, user } = useAuth()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const isSubmitting = form.formState.isSubmitting

  const handleSubmit = async (data: Login) => {
    try {
      await handleLogin({ email: data.email, password: data.password })
    } catch (err) {
      const errorData = (err as AxiosError).response?.data

      setErrorMessage(
        typeof errorData === 'string'
          ? errorData
          : t('set_password.login_failed'),
      )
    }
  }

  if (user && user.organizationUsers.length > 0) {
    let orgId = selectedOrgId
    if (
      !orgId ||
      orgId?.toString() === 'undefined' ||
      !user.organizationUsers.map((ou) => ou.organizationId).includes(orgId)
    ) {
      const defaultOrgId = user.organizationUsers[0].organizationId
      if (defaultOrgId) {
        setSelectedOrgId(defaultOrgId)
      }
      orgId = defaultOrgId
    }

    return <Navigate to={`/app/${orgId}`} />
  }

  return (
    <>
      <h1 className="my-4 text-center text-4xl font-semibold text-black">
        {t('login.heading')}
      </h1>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="my-4 flex flex-col gap-2"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormControl>
                  <Input
                    variant={fieldState.error ? 'error' : 'default'}
                    placeholder={t('login.email_placeholder')}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormControl>
                  <PasswordInput
                    placeholder={t('login.password_placeholder')}
                    variant={fieldState.error ? 'error' : 'default'}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            className="mt-2 w-full font-bold"
            type="submit"
            isLoading={isSubmitting}
          >
            {t('login.primary_action')}
          </Button>
        </form>
      </Form>
      <p className="text-normal py-2 leading-6">
        <Trans i18nKey="login.terms_of_service" t={t}>
          By clicking <span className="font-semibold">“Log in”</span>, you
          acknowledge that you have read and understood, and agree to
          CapillaryFlow's{' '}
          <a
            href="#terms"
            className="font-semibold text-skyBlue hover:underline"
          >
            Terms & Conditions
          </a>{' '}
          and{' '}
          <a
            href="#privacy"
            className="font-semibold text-skyBlue hover:underline"
          >
            Privacy Policy
          </a>
          .
        </Trans>
      </p>
      <hr className="my-4 border-darkSand" />
      <div className="my-4 text-center">
        <Trans i18nKey="login.reset_password_link" t={t}>
          Forgot your password?{' '}
          <Link
            to="/account/reset-password"
            className="font-semibold text-skyBlue hover:underline"
          >
            Click here
          </Link>
        </Trans>
      </div>
      <div>
        {errorMessage && (
          <span className="text-lg text-red-700">{errorMessage}</span>
        )}
      </div>
    </>
  )
}

export default LoginPage
