import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import type { ExternalDisplayValue } from '@/data/organization'
import useOverview from '@/hooks/useOverview'
import { shortLocalizedDateWithTime } from '@/utils/dateHelpers'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../ui/alert-dialog'
import { Button } from '../../ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../ui/dialog'
import { useToast } from '../../ui/use-toast'
import OverviewCard from '../DisplayValueCard'

const AddEditExternalValueSchema = z.object({
  id: z.number().optional(),
  label: z.string(),
  value: z.string(),
})

export type AddEditExternalValue = z.infer<typeof AddEditExternalValueSchema>

function ExternalValueDialog({
  displayValue,
  isOpen,
  onClose,
}: {
  displayValue?: ExternalDisplayValue
  isOpen: boolean
  onClose: () => void
}) {
  const { t } = useTranslation('app')
  const { onCreateDisplayValue, onUpdateDisplayValue, onDeleteDisplayValue } =
    useOverview()
  const { toast } = useToast()
  const form = useForm<AddEditExternalValue>({
    resolver: zodResolver(AddEditExternalValueSchema),
    defaultValues: {
      id: displayValue?.id || undefined,
      label: displayValue?.label || '',
      value: displayValue?.value || '',
    },
  })

  useEffect(() => {
    form.reset(displayValue)

    return () => {
      form.reset()
    }
  }, [displayValue])

  const value = useWatch({
    control: form.control,
    name: 'value',
    defaultValue: displayValue?.value || '',
  })

  const label = useWatch({
    control: form.control,
    name: 'label',
    defaultValue: displayValue?.label || '',
  })

  const isUpdate = typeof displayValue?.id === 'number'
  const isNew = !isUpdate && typeof displayValue !== 'undefined'

  if (!isUpdate && !isNew) return null

  const handleSubmit = async (data: AddEditExternalValue) => {
    try {
      if (isUpdate && displayValue?.id) {
        await onUpdateDisplayValue({ id: Number(displayValue.id), data })
      } else {
        await onCreateDisplayValue(data)
      }
      toast({
        title: isUpdate ? 'Overview field updated' : 'Overview field added',
        className: successfulToastClassNames,
      })
      onClose()
    } catch (err) {
      toast({
        title: isUpdate ? 'Update failed!' : 'Failed to add overview field',
        className: failureToastClassNames,
      })
    }
  }

  const handleDelete = async () => {
    try {
      if (!displayValue?.id) return

      await onDeleteDisplayValue(displayValue.id)
      onClose()
      toast({
        title: 'Overview field deleted',
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: 'Failed to delete overview field',
        className: failureToastClassNames,
      })
    }
  }

  const isSubmitting = form.formState.isSubmitting

  return (
    <Dialog open={Boolean(isOpen)} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>{t('external_value_dialog.header')}</DialogTitle>
          <DialogDescription>
            {t('external_value_dialog.subheader')}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="my-4 flex flex-col gap-4">
              <div className="flex flex-wrap items-center gap-8 md:flex-nowrap">
                <div className="flex flex-1 flex-col gap-4">
                  <FormField
                    control={form.control}
                    name="label"
                    render={({ field, fieldState }) => (
                      <FormItem className="flex-1">
                        <FormLabel>
                          {t('external_value_dialog.title')}
                        </FormLabel>
                        <FormControl>
                          <Input
                            variant={fieldState.error ? 'error' : 'default'}
                            placeholder={t(
                              'external_value_dialog.title_placeholder',
                            )}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="value"
                    render={({ field, fieldState }) => (
                      <FormItem className="flex-1">
                        <FormLabel>
                          {t('external_value_dialog.value')}
                        </FormLabel>
                        <FormControl>
                          <Input
                            variant={fieldState.error ? 'error' : 'default'}
                            placeholder={t(
                              'external_value_dialog.value_placeholder',
                            )}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex min-w-full flex-col items-center justify-center md:min-w-[200px]">
                  <h4 className="text-center">
                    {t('external_value_dialog.preview')}
                  </h4>
                  <div className="m-auto min-w-[200px]">
                    <OverviewCard
                      subheader={shortLocalizedDateWithTime(new Date())}
                      value={value || 'Title'}
                      header={label || 'Value'}
                    />
                  </div>
                </div>
              </div>
              <DialogFooter className="mt-4">
                <Button variant="outline" onClick={onClose} className="mt-4">
                  {t('external_value_dialog.close')}
                </Button>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button
                      tabIndex={-1}
                      type="button"
                      variant="outline"
                      className="mt-4 border-red-700/50 text-red-700 hover:bg-red-50 hover:text-red-800"
                    >
                      <span>{t('external_value_dialog.remove')}</span>
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        {t('external_value_dialog.remove_header', { name })}
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        {t('external_value_dialog.remove_description')}
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>
                        {t('external_value_dialog.cancel_button')}
                      </AlertDialogCancel>
                      <AlertDialogAction
                        className="bg-red-500 hover:bg-red-500/90"
                        onClick={async () => handleDelete()}
                      >
                        {t('external_value_dialog.confirm_button')}
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>

                <Button
                  variant="default"
                  type="submit"
                  isLoading={isSubmitting}
                  className="mt-4"
                >
                  {isUpdate
                    ? t('external_value_dialog.save_changes')
                    : t('external_value_dialog.add')}
                </Button>
              </DialogFooter>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
export default ExternalValueDialog
