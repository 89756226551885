export const capitalizeFirstCharacter = (text: string) => {
  if (!text || text.length === 0) return text
  const firstLetter = text.substring(0, 1).toLocaleUpperCase()
  return `${firstLetter}${text.substring(1)}`
}

export const getNameInitials = (firstName?: string, lastName?: string) => {
  if (!firstName && !lastName) return ''
  if (firstName && !lastName) return firstName.slice(0, 2)
  if (lastName && !firstName) return lastName.slice(0, 2)

  return `${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`
}
