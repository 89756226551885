import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { useAuth } from '@/components/v2/AuthProvider'
import FormSectionHeader from '@/components/v2/FormSectionHeader'
import ImageSettings from '@/components/v2/ImageSettings'
import InviteUserModal from '@/components/v2/InviteUserModal'
import LanguageSelect from '@/components/v2/LanguageSelect'
import OrganizationUsersTable from '@/components/v2/OrganizationUsersTable'
import DistanceUnitSelect from '@/components/v2/selects/DistanceUnitSelect'
import LocationAutocomplete from '@/components/v2/selects/LocationAutocomplete'
import TemperatureSelect from '@/components/v2/selects/TemperatureSelect'
import TimeZoneSelect from '@/components/v2/selects/TimeZoneSelect'
import useOrganization from '@/hooks/useOrganization'
import useOrganizationUsers from '@/hooks/useOrganizationUsers'
import { isOwner } from '@/utils/roleHelpers'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

const OrganizationSchema = z.object({
  id: z.number(),
  logoUrl: z.string().nullable().optional(),
  name: z.string(),
  lat: z.number(),
  lng: z.number(),
  address: z.string(),
  timeZone: z.string(),
  language: z.string(),
  distanceUnit: z.string(),
  temperatureUnit: z.string(),
})

export type UpdateOrganization = z.infer<typeof OrganizationSchema>

function OrganizationSettingsPage() {
  const { t } = useTranslation(['app', 'common'])
  const { organization, onChange } = useOrganization()
  const { toast } = useToast()
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { orgId } = useParams()
  const { data: users } = useOrganizationUsers(orgId)

  const disabled = !isOwner(
    user?.organizationUsers?.find((ou) => ou.organizationId === Number(orgId))
      ?.role,
  )

  const form = useForm<UpdateOrganization>({
    resolver: zodResolver(OrganizationSchema),
    defaultValues: {
      id: organization?.id,
      logoUrl: organization?.logoUrl || '',
      name: organization?.name || '',
      lat: organization?.lat || 0,
      lng: organization?.lng || 0,
      address: organization?.address || '',
      timeZone: organization?.timeZone || '',
      language: organization?.language || 'en',
      distanceUnit: organization?.distanceUnit || 'meter',
      temperatureUnit: organization?.temperatureUnit || 'celsius',
    },
  })

  useEffect(() => {
    if (organization && !form.formState.isDirty) {
      form.reset(organization)
    }
  }, [organization])

  const handleSubmit = async (data: UpdateOrganization) => {
    try {
      await onChange(data)
      toast({
        title: 'Organization settings updated!',
        className: successfulToastClassNames,
      })
      const dashboardLink = location.pathname.split('/').slice(0, -1).join('/')
      navigate(dashboardLink)
    } catch (err) {
      toast({
        title: 'Organization update failed!',
        className: failureToastClassNames,
      })
    }
  }

  return (
    <div className="mx-auto w-full max-w-5xl p-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <FormSectionHeader
            text={t('organization_settings.settings_header')}
          />
          <div className="my-4 flex flex-col gap-4">
            <FormField
              control={form.control}
              name="logoUrl"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>
                    {t('organization_settings.company_logo')}
                  </FormLabel>
                  <FormControl>
                    <ImageSettings
                      entity="organization"
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      disabled={disabled}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="name"
              render={({ field, fieldState }) => (
                <FormItem className="min-w-full flex-1 sm:min-w-fit">
                  <FormLabel className="flex items-center gap-2">
                    {t('organization_settings.name')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      disabled={disabled}
                      variant={fieldState.error ? 'error' : 'default'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem className="min-w-full flex-1 sm:min-w-fit">
                  <FormLabel className="flex items-center gap-2">
                    {t('organization_settings.address')}
                  </FormLabel>
                  <FormControl>
                    <LocationAutocomplete
                      value={field.value}
                      onChange={(address, lat, lng) => {
                        form.setValue('address', address)
                        form.setValue('lat', lat)
                        form.setValue('lng', lng)
                      }}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-wrap gap-4 sm:flex-nowrap">
              <FormField
                control={form.control}
                name="timeZone"
                render={({ field }) => (
                  <FormItem className="flex min-w-full flex-1 flex-col justify-end sm:min-w-fit">
                    <FormLabel className="">
                      {t('organization_settings.timeZone')}
                    </FormLabel>
                    <TimeZoneSelect
                      disabled={disabled}
                      value={field.value}
                      onChange={(value) => {
                        form.setValue('timeZone', value)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="language"
                render={({ field }) => (
                  <FormItem className="min-w-full flex-1 sm:min-w-fit">
                    <FormLabel>
                      {capitalizeFirstCharacter(
                        t('language', { ns: 'common' }),
                      )}
                    </FormLabel>
                    <FormControl>
                      <LanguageSelect
                        disabled={disabled}
                        onChange={field.onChange}
                        value={field.value}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="my-8 flex flex-col gap-4">
            <FormSectionHeader text={t('organization_settings.units_header')} />
            <div className="my-4 flex flex-col gap-4">
              <FormField
                control={form.control}
                name="distanceUnit"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between border-b pb-4">
                    <FormLabel>
                      {capitalizeFirstCharacter(
                        t('distance_unit', { ns: 'common' }),
                      )}
                    </FormLabel>
                    <FormControl>
                      <div className="w-full max-w-[200px]">
                        <DistanceUnitSelect
                          disabled={disabled}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="temperatureUnit"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between border-b pb-4">
                    <FormLabel>
                      {capitalizeFirstCharacter(
                        t('temperature_unit', { ns: 'common' }),
                      )}
                    </FormLabel>
                    <FormControl>
                      <div className="w-full max-w-[200px]">
                        <TemperatureSelect
                          disabled={disabled}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="my-8 flex flex-col gap-4">
            <div className="relative">
              <FormSectionHeader
                text={capitalizeFirstCharacter(t('users', { ns: 'common' }))}
              />
              {!disabled ? (
                <div className="absolute right-0 top-0">
                  <InviteUserModal />
                </div>
              ) : null}
            </div>
            <div className="my-4 flex flex-col gap-4">
              <OrganizationUsersTable users={users} disabled={disabled} />
            </div>
          </div>
          <div className="my-8 flex gap-4">
            <Button
              variant="default"
              size="default"
              type="submit"
              className="font-semibold"
              isLoading={form.formState.isSubmitting}
              disabled={form.formState.isSubmitting || disabled}
            >
              {t('organization_settings.save')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
export default OrganizationSettingsPage
