import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { useAuth } from '@/components/v2/AuthProvider'
import type { CreateUser } from '@/components/v2/InviteUserModal'
import { createUser, deleteUser, sendUserInvite, updateUser } from '@/data/user'
import type { Profile } from '@/routes/app/user-settings'

function useUser() {
  const queryClient = useQueryClient()
  const { orgId = '' } = useParams()
  const { user } = useAuth()

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: { id: number; data: Profile }) =>
      updateUser(orgId, id, data),
    onSuccess: (newUser, { id }) => {
      queryClient.invalidateQueries({ queryKey: ['user', orgId, id] })
      queryClient.invalidateQueries({ queryKey: ['organization', orgId] })
      queryClient.invalidateQueries({ queryKey: ['organization-users', orgId] })
      if (user?.id === id) queryClient.setQueryData(['profile'], newUser)
    },
  })

  const createMutation = useMutation({
    mutationFn: async (data: CreateUser) => createUser(orgId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization', orgId] })
      queryClient.invalidateQueries({ queryKey: ['organization-users', orgId] })
    },
  })

  const inviteMutation = useMutation({
    mutationFn: async (id: number) => sendUserInvite(orgId, id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: ['user', orgId, id] })
      queryClient.invalidateQueries({ queryKey: ['organization', orgId] })
      queryClient.invalidateQueries({ queryKey: ['organization-users', orgId] })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async (id: number) => deleteUser(orgId, id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: ['user', orgId, id] })
      queryClient.invalidateQueries({ queryKey: ['organization', orgId] })
      queryClient.invalidateQueries({ queryKey: ['organization-users', orgId] })
    },
  })

  return {
    onUpdate: updateMutation.mutateAsync,
    onInvite: inviteMutation.mutateAsync,
    onDelete: deleteMutation.mutateAsync,
    onCreate: createMutation.mutateAsync,
  }
}
export default useUser
