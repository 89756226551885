import {
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import type { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart'

import type { ChartData } from '@/data/device-data'
import {
  localizedDateWithTime,
  shortLocalizedDateWithTime,
  shortLocalizedDateWithoutTime,
  timeWithoutDate,
} from '@/utils/dateHelpers'

const MAX_MM = 920
const MAX_IN = 36

function WaterLevelChart({
  data,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  isZoomed,
  showTimeOnly,
  showCondensed,
  unitSuffix,
  materialHeightTop,
  materialHeightBottom,
  zoomArea,
  showZoomBox,
}: {
  data: ChartData[]
  onMouseDown?: CategoricalChartFunc
  onMouseMove: CategoricalChartFunc
  onMouseUp: CategoricalChartFunc
  isZoomed: boolean
  showTimeOnly: boolean
  showCondensed: boolean
  unitSuffix: string
  materialHeightTop?: number
  materialHeightBottom?: number
  zoomArea: { x1: string | null; x2: string | null }
  showZoomBox: boolean
}) {
  const maxValue = unitSuffix === 'in' ? MAX_IN : MAX_MM

  return (
    <ResponsiveContainer
      width="100%"
      height={300}
      className="plot-container py-2"
    >
      <LineChart
        data={data}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        <>
          <XAxis
            dataKey="timestamp"
            domain={isZoomed ? ['dataMin - 20', 'dataMax + 20'] : undefined}
            tickFormatter={(val) =>
              showTimeOnly
                ? timeWithoutDate(new Date(val))
                : showCondensed
                  ? shortLocalizedDateWithTime(new Date(val))
                  : shortLocalizedDateWithoutTime(new Date(val))
            }
            className="whitespace-break-spaces text-xs"
          />
          <YAxis
            yAxisId="left"
            tickFormatter={(val) => `${val} ${unitSuffix}`}
            domain={
              isZoomed
                ? [
                    (dataMin: number) => Math.max(0, dataMin - 50),
                    (dataMax: number) => Math.min(maxValue, dataMax + 50),
                  ]
                : [0, maxValue]
            }
            className="whitespace-nowrap text-xs"
          />
          <YAxis
            yAxisId="right"
            tickFormatter={(val) => `${val}%`}
            domain={[
              (dataMin: number) => Math.max(0, dataMin - 20),
              (dataMax: number) => Math.min(100, dataMax + 20),
            ]}
            orientation="right"
            className="whitespace-nowrap text-xs"
          />
          <Tooltip
            labelFormatter={(val) => localizedDateWithTime(new Date(val))}
            wrapperStyle={{ fontSize: '12px' }}
            formatter={(label) => `${label}`}
          />
          <Legend wrapperStyle={{ fontSize: '12px' }} />
          <ReferenceLine
            yAxisId="left"
            y={materialHeightTop}
            stroke="gray"
            strokeDasharray="3 3"
            className="text-xs"
          />
          <ReferenceArea
            yAxisId="left"
            y1={materialHeightTop}
            y2={materialHeightBottom}
            fill="rgba(0, 0, 0, 0.1)"
          />
          <ReferenceLine
            yAxisId="left"
            y={materialHeightBottom}
            label={{ value: 'Capillary material', position: 'bottom' }}
            stroke="gray"
            strokeDasharray="3 3"
            className="text-xs"
          />
          <Line
            type="monotone"
            dataKey="averageWaterLevelLeft"
            stroke="#0079BF"
            strokeWidth={2}
            dot={false}
            name={`Left section (${unitSuffix})`}
            yAxisId="left"
          />
          <Line
            type="monotone"
            dataKey="averageWaterLevelRight"
            stroke="#82ca9d"
            strokeWidth={2}
            dot={false}
            name={`Right section (${unitSuffix})`}
            yAxisId="left"
          />
          <Line
            type="monotone"
            dataKey="averageWaterLevelPercentage"
            stroke="#a745a7"
            strokeWidth={2}
            dot={false}
            name="Water volume (%)"
            yAxisId="right"
          />

          {showZoomBox && (
            <ReferenceArea
              yAxisId="left"
              x1={zoomArea.x1 ?? undefined}
              x2={zoomArea.x2 ?? undefined}
              ifOverflow="extendDomain"
            />
          )}
        </>
      </LineChart>
    </ResponsiveContainer>
  )
}
export default WaterLevelChart
