import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import type { OpenCloseValve } from '@/data/device'
import {
  createDevice,
  deleteDevice,
  getDevice,
  restartDevice,
  setActiveSection,
  setValveState,
  updateAutoRegulate,
  updateCycleTime,
  updateDevice,
} from '@/data/device'
import type { CreateUpdateDevice } from '@/utils/deviceHelpers'

const DEFAULT_REFETCH_INTERVAL =
  import.meta.env.VITE_DEVICES_POLLING_INTERVAL || 15000

function useDevice(id?: string, pollingInterval?: number) {
  const queryClient = useQueryClient()
  const { orgId = '' } = useParams()
  const abortController = new AbortController()

  const refetchInterval =
    typeof pollingInterval === 'number'
      ? pollingInterval
      : DEFAULT_REFETCH_INTERVAL

  const {
    data: device,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['device', orgId, id],
    queryFn: async () => getDevice(orgId, id!, abortController.signal),
    refetchInterval: Number(refetchInterval),
    enabled: Boolean(orgId) && Boolean(id),
  })

  const updateMutation = useMutation({
    mutationFn: async (data: CreateUpdateDevice) =>
      updateDevice(orgId, id!, data),
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['devices'] })
      queryClient.invalidateQueries({ queryKey: ['devices', 'map'] })
    },
  })

  const createMutation = useMutation({
    mutationFn: async (data: CreateUpdateDevice) => createDevice(orgId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['devices'] })
      queryClient.invalidateQueries({ queryKey: ['devices', 'map'] })
    },
  })

  const valveMutation = useMutation({
    mutationFn: async (data: OpenCloseValve) =>
      setValveState(
        orgId,
        id!,
        data.type,
        data.minutes,
        data.desiredNitrogenPPM,
      ),
  })

  const restartMutation = useMutation({
    mutationFn: async () => restartDevice(orgId, id!),
    onSuccess: () => {
      refetch()
    },
  })

  const deleteMutation = useMutation({
    mutationFn: async () => deleteDevice(orgId, id!),
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['devices'] })
      queryClient.invalidateQueries({ queryKey: ['devices', 'map'] })
    },
  })

  const cycleTimeMutation = useMutation({
    mutationFn: async (seconds: number) => updateCycleTime(orgId, id!, seconds),
    onSuccess: () => {
      refetch()
    },
  })

  const autoRegulateMutation = useMutation({
    mutationFn: async (shouldAutoRegulate: boolean) =>
      updateAutoRegulate(orgId, id!, shouldAutoRegulate),
    onSuccess: () => {
      refetch()
    },
  })

  const activeSectionMutation = useMutation({
    mutationFn: async (activeSection: number) =>
      setActiveSection(orgId, id!, activeSection),
    onSuccess: () => {
      refetch()
    },
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return {
    device,
    isLoading,
    onUpdate: updateMutation.mutateAsync,
    onCreate: createMutation.mutateAsync,
    onOpenCloseValve: valveMutation.mutateAsync,
    onRestart: restartMutation.mutateAsync,
    onDelete: deleteMutation.mutateAsync,
    isDeleting: deleteMutation.isPending,
    onUpdateCycleTime: cycleTimeMutation.mutateAsync,
    onEnableDisableAutoRegulate: autoRegulateMutation.mutateAsync,
    onUpdateActiveSection: activeSectionMutation.mutateAsync,
  }
}
export default useDevice
