import { ListFilterIcon, PlusIcon, XIcon } from 'lucide-react'
import { Fragment } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import DeviceListCard from '@/components/v2/DeviceListCard'
import DeviceListCardErrorBoundary from '@/components/v2/errors/DeviceListCardErrorBoundary'
import DeviceListCardSkeleton from '@/components/v2/skeletons/DeviceListCardSkeleton'
import useDevices from '@/hooks/useDevices'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'

import { Skeleton } from '../ui/skeleton'

import EmptyDashboard from './EmptyDashboard'

function DeviceList({ isLoading }: { isLoading: boolean }) {
  const { t } = useTranslation(['app', 'common'])
  const [type, setType] = useQueryParam('type', withDefault(StringParam, ''))
  const [searchText, setSearchText] = useQueryParam(
    'q',
    withDefault(StringParam, ''),
  )
  const [debouncedSearch] = useDebounce(searchText, 500)

  const {
    devices,
    isLoading: isDevicesLoading,
    refetch,
    isRefetching,
  } = useDevices(type, undefined, debouncedSearch)
  const showLoading = isDevicesLoading || isLoading

  const hasActiveFilters = Boolean(type) || Boolean(searchText)

  return (
    <ErrorBoundary fallback={<h6>{t('device_list.error_message')}</h6>}>
      <div className="my-4 flex justify-between">
        <div className="flex flex-wrap gap-2">
          <h4 className="mr-2 text-2xl">
            {showLoading ? (
              <Skeleton className="h-9 w-40" />
            ) : (
              t('device_list.header', {
                count: devices?.length || 0,
              })
            )}
          </h4>
          {type ? (
            <div className="hidden items-center gap-1 rounded-full border border-skyBlue/20 bg-skyBlue/10 px-2 py-1 text-sm font-semibold md:flex">
              <span className="text-xs font-normal">
                {t('type', { ns: 'common' })}:
              </span>{' '}
              {capitalizeFirstCharacter(t(type, { ns: 'common' }))}
              <button
                className="rounded-full p-1 hover:bg-skyBlue/20"
                onClick={() => setType(null)}
              >
                <XIcon className="h-3 w-3" />
              </button>
            </div>
          ) : null}
          {debouncedSearch ? (
            <div className="hidden items-center gap-1 rounded-full border border-skyBlue/20 bg-skyBlue/10 px-2 py-1 text-sm font-semibold md:flex">
              <span className="text-xs font-normal">
                {t('search', { ns: 'common' })}:
              </span>{' '}
              {debouncedSearch}
              <button
                className="rounded-full p-1 hover:bg-skyBlue/20"
                onClick={() => setSearchText(null)}
              >
                <XIcon className="h-3 w-3" />
              </button>
            </div>
          ) : null}
        </div>
        <div className="flex gap-4">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                size="sm"
                variant="outline"
                className="flex items-center gap-1"
              >
                <ListFilterIcon className="h-4 w-4" />{' '}
                {capitalizeFirstCharacter(t('filter', { ns: 'common' }))}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <div className="flex flex-col gap-4">
                <Select onValueChange={setType} defaultValue={type}>
                  <div>
                    <Label>
                      {capitalizeFirstCharacter(t('type', { ns: 'common' }))}
                    </Label>
                    <SelectTrigger className="mt-1">
                      <SelectValue
                        placeholder={t('device_list.filter_by_type')}
                      ></SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="bunker">
                          {capitalizeFirstCharacter(
                            t('bunker', { ns: 'common' }),
                          )}
                        </SelectItem>
                        <SelectItem value="tee">
                          {capitalizeFirstCharacter(t('tee', { ns: 'common' }))}
                        </SelectItem>
                        <SelectItem value="green">
                          {capitalizeFirstCharacter(
                            t('green', { ns: 'common' }),
                          )}
                        </SelectItem>
                        <SelectItem value="equestrian">
                          {capitalizeFirstCharacter(
                            t('equestrian', { ns: 'common' }),
                          )}
                        </SelectItem>
                        <SelectItem value="equestrian_sand">
                          {capitalizeFirstCharacter(
                            t('equestrian_sand', { ns: 'common' }),
                          )}
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </div>
                </Select>
              </div>
            </PopoverContent>
          </Popover>

          <Link to={`${window.location.pathname}/add-device`}>
            <Button size="sm" className="flex items-center gap-1">
              <PlusIcon className="h-4 w-4" />{' '}
              {capitalizeFirstCharacter(t('add', { ns: 'common' }))}
            </Button>
          </Link>
        </div>
      </div>
      <div className="dashboard">
        {showLoading ? (
          Array.from(new Array(20)).map((_, i) => (
            <DeviceListCardSkeleton key={`loading-${i}`} />
          ))
        ) : devices && devices.length > 0 ? (
          devices.map((device) => (
            <Fragment key={device.id}>
              <ErrorBoundary
                fallback={
                  <DeviceListCardErrorBoundary
                    onRetry={async () => refetch()}
                    isRefetching={isRefetching}
                  />
                }
              >
                <DeviceListCard
                  type={device.type}
                  status={device.status}
                  name={device.name}
                  volume={device.data?.waterLevelPercentage}
                  valveState={device.data?.fillValveState}
                  timestamp={device.data?.timestamp}
                  id={device.id}
                  imageUrl={device.imageUrl}
                  waterLevelLeft={device.data?.waterLevelLeft}
                  waterLevelRight={device.data?.waterLevelRight}
                  link={`${window.location.pathname}/${device.id}`}
                />
              </ErrorBoundary>
            </Fragment>
          ))
        ) : hasActiveFilters ? (
          <h4>No devices matching filters</h4>
        ) : (
          <EmptyDashboard />
        )}
      </div>
    </ErrorBoundary>
  )
}

export default DeviceList
