/* eslint-disable import/no-unresolved */
import type { Device } from '@/data/device'
import { cn } from '@/lib/utils'

import Bunker1 from '/bunker1.jpeg?url'
import Bunker2 from '/bunker2.jpeg?url'
import Bunker3 from '/bunker3.jpeg?url'

function DeviceImageWithFallback({
  imageUrl,
  id,
  isDashboard = false,
}: {
  imageUrl?: Device['imageUrl']
  id: Device['id']
  isDashboard?: boolean
}) {
  const index = id % 3
  const fallback = index === 0 ? Bunker1 : index === 1 ? Bunker2 : Bunker3

  const imageUrlWithFallback = imageUrl || fallback
  return (
    <img
      src={imageUrlWithFallback}
      alt={`Device ${id}`}
      className={cn(
        'h-full max-h-20 w-full object-cover',
        !isDashboard ? 'aspect-video max-h-max rounded-3xl' : 'rounded-xl',
      )}
    />
  )
}

export default DeviceImageWithFallback
