import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getOrganizations } from '@/data/organization'

function useOrganizations() {
  const abortController = new AbortController()
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['organizations'],
    queryFn: async () => getOrganizations(abortController.signal),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { data, isLoading, refetch, isRefetching }
}

export default useOrganizations
