import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import HamburgerIcon from '@/assets/icons/hamburger.svg?react'
import { Button } from '@/components/ui/button'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from '../../ui/sheet'
import type { Route } from '../HeaderBar'

function MobileMenu({
  routes,
  adminRoutes,
}: {
  routes: Route[]
  adminRoutes: Route[]
}) {
  const { t } = useTranslation('common')
  return (
    <Sheet>
      <SheetTrigger asChild className="block md:hidden">
        <Button variant="ghost" size="icon">
          <HamburgerIcon />
          <span className="sr-only">Open menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="overflow-y-auto bg-sand px-0">
        <div className="flex h-full flex-col">
          <div className="mt-8 flex flex-col gap-2">
            <SheetHeader className="px-2 text-left text-sm uppercase text-concreteGray">
              Navigation
            </SheetHeader>
            {routes.map((route) => (
              <SheetClose asChild key={route.path}>
                <Link
                  to={route.path}
                  className="w-full px-4 py-2 hover:bg-white"
                >
                  {capitalizeFirstCharacter(t(route.name))}
                </Link>
              </SheetClose>
            ))}
          </div>
          {adminRoutes?.length > 0 ? (
            <div className="mt-8 flex flex-col gap-2 pb-4">
              <SheetHeader className="px-2 text-left text-sm uppercase text-concreteGray">
                Admin
              </SheetHeader>
              {adminRoutes.map((route) => (
                <SheetClose asChild key={route.path}>
                  <Link
                    to={route.path}
                    className="w-full px-4 py-2 hover:bg-white"
                  >
                    {capitalizeFirstCharacter(t(route.name))}
                  </Link>
                </SheetClose>
              ))}
            </div>
          ) : null}
        </div>
      </SheetContent>
    </Sheet>
  )
}
export default MobileMenu
