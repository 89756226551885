import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import ActivityNotificationRow from '@/components/v2/ActivityNotificationRow'
import ActivityLoadingPage from '@/components/v2/skeletons/ActivityLoadingPage'
import useActivity from '@/hooks/useActivity'

function ActivityPage() {
  const { t } = useTranslation('app')
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useActivity()

  const sentinelRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
            fetchNextPage()
          }
        })
      },
      { threshold: 0.1 },
    )

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current)
    }
    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current)
      }
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  if (error) {
    return <p>Error: {error.message}</p>
  }

  return (
    <>
      {isLoading ? (
        <ActivityLoadingPage />
      ) : (
        <div className="mx-auto w-full max-w-4xl px-2 py-4 sm:p-4">
          <h2 className="text-2xl font-bold">{t('notifications.header')}</h2>
          <div className="mt-8 flex flex-col">
            {data?.pages?.map((page, i) => (
              <React.Fragment key={i}>
                {page.map((notification) => (
                  <ActivityNotificationRow notification={notification} />
                ))}
              </React.Fragment>
            ))}
            <div ref={sentinelRef} className="h-1"></div>{' '}
            {/* Sentinel element */}
            {isFetchingNextPage && <p>Loading more...</p>}
            {!isFetchingNextPage && hasNextPage && <p></p>}
          </div>
        </div>
      )}
    </>
  )
}
export default ActivityPage
