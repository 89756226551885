import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getTemperatureUnits } from '@/data/enum'

function useTemperatureUnits() {
  const abortController = new AbortController()

  const { data: units, isLoading } = useQuery({
    queryKey: ['temperatureUnits'],
    queryFn: async () => getTemperatureUnits(abortController),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { units, isLoading }
}

export default useTemperatureUnits
