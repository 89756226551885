import { useTranslation } from 'react-i18next'

import useTemperatureUnits from '@/hooks/useTemperatureUnits'

import { FormControl } from '../../ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select'

function TemperatureSelect({
  onChange,
  value,
  disabled,
}: {
  onChange: (value: string) => void
  value: string
  disabled?: boolean
}) {
  const { t } = useTranslation('common')
  const { units } = useTemperatureUnits()

  return (
    <Select onValueChange={onChange} value={value} disabled={disabled}>
      <FormControl>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        {(units || []).map((unit) => (
          <SelectItem value={unit} key={unit}>
            {t(`temperature_${unit}`)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
export default TemperatureSelect
