function NoAccessErrorBoundary() {
  return (
    <div className="m-auto mt-4 max-w-md rounded-md bg-red-100 p-4 md:mt-8">
      <h4 className="text-xl font-semibold text-concreteGray">
        You do not have access to this page
      </h4>
    </div>
  )
}
export default NoAccessErrorBoundary
