import { Navigate, useLocation, useParams } from 'react-router-dom'

import { useAuth } from '@/components/v2/AuthProvider'

// This page will only redirect the user to the AddDevicePage inside the organization scope.
// It is needed to avoid embedding organizationId in the QR code - which is desired to ensure that devices are not branded for a specific customer or location
function AddDeviceRedirectPage() {
  const { imei } = useParams()
  const { user } = useAuth()
  const location = useLocation()

  const defaultOrg = user?.organizationUsers?.[0]?.organizationId
  if (defaultOrg && imei) {
    return (
      <Navigate
        to={`/app/${defaultOrg}/add-device?imei=${imei}`}
        state={{ from: location }}
      />
    )
  }

  return <div></div>
}
export default AddDeviceRedirectPage
