import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import type { DetailedDevice } from '@/data/device'

import MapMarker from '../map/MapMarker'
import MapView from '../map/MapView'

function LocationMapDialog({
  isOpen,
  onClose,
  onSave,
  device,
  showGetCurrent = false,
}: {
  isOpen: boolean
  onClose: () => void
  onSave?: (lat: number, lng: number) => void
  device: DetailedDevice
  showGetCurrent?: boolean
}) {
  const [isGettingLocation, setIsGettingLocation] = useState(false)
  const { t } = useTranslation('app')
  const [tempCoordinates, setTempCoordinates] = useState({
    lat: device.lat,
    lng: device.lng,
  })

  const getLocation = () => {
    if (navigator.geolocation) {
      setIsGettingLocation(true)
      navigator.geolocation.getCurrentPosition((position) => {
        window.dispatchEvent(
          new CustomEvent('locate_me', {
            detail: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          }),
        )
        setIsGettingLocation(false)
      })
    }
  }

  const isClickable = typeof onSave !== 'undefined'

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="aspect-video w-full max-w-4xl">
        <DialogHeader>
          <DialogTitle>{device.name}</DialogTitle>
          {isClickable ? (
            <DialogDescription>
              {t('location_map_dialog.subheader')}
            </DialogDescription>
          ) : null}
        </DialogHeader>
        <div className="relative aspect-video w-full">
          <MapView
            containerStyles={{ width: '100%', height: '100%' }}
            lat={device.lat!}
            lng={device.lng!}
            onClick={(lat, lng) => setTempCoordinates({ lat, lng })}
          >
            <MapMarker
              id={device.id}
              navigateOnClick={false}
              lat={tempCoordinates.lat!}
              lng={tempCoordinates.lng!}
              status={device.status}
              name={device.name}
              showHoverTooltip={false}
            />
          </MapView>
        </div>
        <DialogFooter className="mt-4">
          <Button variant="outline" onClick={onClose} className="mt-4">
            {t('location_map_dialog.close')}
          </Button>
          {showGetCurrent ? (
            <Button
              variant="outline"
              onClick={() => getLocation()}
              isLoading={isGettingLocation}
              className="mt-4"
            >
              {t('location_map_dialog.get_location')}
            </Button>
          ) : null}
          {isClickable ? (
            <Button
              variant="default"
              onClick={() => onSave(tempCoordinates.lat!, tempCoordinates.lng!)}
              disabled={
                device.lat === tempCoordinates.lat &&
                device.lng === tempCoordinates.lng
              }
              className="mt-4"
            >
              {t('location_map_dialog.update_location')}
            </Button>
          ) : null}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
export default LocationMapDialog
