import HeaderBg from '@/assets/headerbg.svg?react'

function CapillaryFlowHeader() {
  return (
    <div className="relative flex h-[100px] -translate-y-[25px] justify-center sm:translate-y-0">
      <HeaderBg />
    </div>
  )
}
export default CapillaryFlowHeader
