// The order should match the 'DeviceStatus' enum in the backend
export const status = [
  'broken_data_connection',
  'low_water',
  'high_water',
  'not_operating_optimally',
  'scheduled_maintenance',
  'operating_well',
  'no_data_received',
  'error',
  'pending',
]

export const activeSectionState = ['left', 'right']

export const autoRegulateState = ['off', 'on']
