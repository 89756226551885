import { useTranslation } from 'react-i18next'

import NoticeIcon from '@/assets/icons/noticeicon.svg?react'
import { Button } from '@/components/ui/button'

function DeviceListCardErrorBoundary({
  onRetry,
  isRefetching,
}: {
  onRetry: () => void
  isRefetching: boolean
}) {
  const { t } = useTranslation('app')
  return (
    <div className="aspect-square h-full w-full rounded-3xl border border-gray-300 bg-gray-100">
      <div className="flex h-full flex-col items-center justify-center">
        <NoticeIcon className="h-20 w-20" />
        <h4 className="font-semibold">
          {t('device_list_card_error_boundary.something_went_wrong')}
        </h4>
        <Button
          variant="outline"
          className="mt-8"
          onClick={onRetry}
          isLoading={isRefetching}
        >
          {t('device_list_card_error_boundary.try_again')}
        </Button>
      </div>
    </div>
  )
}

export default DeviceListCardErrorBoundary
