import DirectionIcon from '@/assets/icons/winddirection.svg?react'
import type { WindDirection } from '@/data/weather'

function WindDirectionIcon({ direction }: { direction?: WindDirection }) {
  if (!direction) return null

  let rotation
  switch (direction) {
    case 'N':
      rotation = ''
      break
    case 'NE':
      rotation = 'rotate-45'
      break
    case 'E':
      rotation = 'rotate-90'
      break
    case 'SE':
      rotation = 'rotate-[135deg]'
      break
    case 'S':
      rotation = 'rotate-180'
      break
    case 'SW':
      rotation = '-rotate-[135deg]'
      break
    case 'W':
      rotation = '-rotate-90'
      break
    case 'NW':
      rotation = '-rotate-45'
      break
  }

  return <DirectionIcon className={`inline ${rotation}`} />
}
export default WindDirectionIcon
