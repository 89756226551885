import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'

import RewindIcon from '@/assets/icons/rewindicon.svg?react'
import { Button } from '@/components/ui/button'
import DeviceList from '@/components/v2/DeviceList'
import DeviceListMap from '@/components/v2/DeviceListMap'
import NoOrganizationErrorBoundary from '@/components/v2/errors/NoOrganizationErrorBoundary'
import Overview from '@/components/v2/Overview'
import { useContainerDimensions } from '@/hooks/useContainerWidth'
import useOrganization from '@/hooks/useOrganization'

const DashboardPage = () => {
  const flexWrapper = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(flexWrapper)
  const { t } = useTranslation('app')
  const { organization, isLoading } = useOrganization()

  const [showFullWidth, setShowFullWidth] = useQueryParam(
    'map',
    withDefault(BooleanParam, false),
  )

  return (
    <div className="m-auto flex w-full max-w-[2000px] flex-wrap-reverse pb-8 md:gap-12">
      <div ref={flexWrapper} className="relative flex w-full gap-8">
        {!organization && !isLoading ? (
          <div className="m-auto">
            <NoOrganizationErrorBoundary />
          </div>
        ) : (
          <>
            <div className="flex flex-1 flex-col gap-4">
              <Overview isLoading={isLoading} organization={organization} />
              <DeviceList isLoading={isLoading} />
            </div>
            <div className="relative hidden min-h-[calc(100vh-64px)] rounded-3xl lg:block lg:w-[400px] lg:min-w-[400px]">
              <div
                className={`absolute left-0 top-0 hidden h-full max-h-[95vh] overflow-hidden rounded-3xl transition-transform lg:block ${
                  showFullWidth ? '-translate-x-[800px]' : 'translate-x-0'
                }`}
                style={{
                  transform: showFullWidth
                    ? `translateX(-${width}px) translateX(400px)`
                    : 'translateX(0)',
                  width: showFullWidth ? `${width}px` : '400px',
                }}
              >
                <DeviceListMap
                  lat={organization?.lat}
                  lng={organization?.lng}
                  isLoading={isLoading}
                />
                <Button
                  variant="secondary"
                  className="absolute left-4 top-8 z-50 rounded-full font-bold text-black hover:bg-gray-50"
                  onClick={() => setShowFullWidth((prev) => !prev)}
                >
                  {showFullWidth ? (
                    <>
                      <RewindIcon className="rotate-180" />{' '}
                      <span>{t('dashboard_map.minimize')}</span>
                    </>
                  ) : (
                    <>
                      <RewindIcon /> <span>{t('dashboard_map.expand')}</span>
                    </>
                  )}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export default DashboardPage
