function GenericErrorBoundary() {
  return (
    <>
      <h4 className="text-xl font-semibold">Something went wrong</h4>
      <h6 className="text-lg">
        The problem seems to be on our side. It has been automatically reported
        and we will fix it as soon as possible
      </h6>
    </>
  )
}
export default GenericErrorBoundary
