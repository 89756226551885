import BunkerIcon from '@/assets/icons/bunker.svg?react'
import EquestrianIcon from '@/assets/icons/equestrian.svg?react'
import GrassIcon from '@/assets/icons/grass.svg?react'
import GreenIcon from '@/assets/icons/green.svg?react'
import TeeIcon from '@/assets/icons/tee.svg?react'
import type { Device } from '@/data/device'

function DeviceTypeIcon({ type }: { type: Device['type'] }) {
  switch (type.toLowerCase()) {
    case 'equestrian':
    case 'equestrian_grass':
    case 'equestrian_sand':
      return <EquestrianIcon />

    case 'green':
      return <GreenIcon />

    case 'tee':
      return <TeeIcon />

    case 'bunker':
      return <BunkerIcon />

    default:
      return <GrassIcon />
  }
}

export default DeviceTypeIcon
