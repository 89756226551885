import { useTranslation } from 'react-i18next'

import useBasinSpecs from '@/hooks/useBasinSpecs'

import { FormControl } from '../ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'

function BasinSpecSelect({
  onChange,
  value,
}: {
  onChange: (value: string) => void
  value: string
}) {
  const { t } = useTranslation('app')
  const { data } = useBasinSpecs()

  return (
    <Select onValueChange={onChange} value={value}>
      <FormControl>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        {(data || []).map((spec) => (
          <SelectItem value={spec.id?.toString() ?? ''} key={spec.id}>
            {t(`basin_spec.${spec.name}`)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
export default BasinSpecSelect
