import * as Sentry from '@sentry/react'

import type { CreateUpdateDataDownlink } from '@/components/admin/AddEditDataDownlinkModal'
import { axiosClient } from '@/lib/axios'

const BASE_URI = '/admin/DataDownlink'

export interface DataDownlink {
  id: number
  fillValveTime?: number
  drainValveTime?: number
  dripLineValveTime?: number
  setAutoRegulate?: boolean
  setActiveSection?: number
  shouldFetchConfig?: boolean
  shouldReboot?: boolean
  desiredNitrogenPPM?: number
  manualFertigationRatio?: number
  deviceId: number
  imei: string
  createdAt: string
  modifiedAt?: string
  deletedAt?: string
  createdBy: string
  modifiedBy?: string
  isDownlinkedToDevice: boolean
}

export interface PaginatedDataDownlinks {
  downlinks: DataDownlink[]
  totalCount: number
}

export const getPaginatedDataDownlinks = async (
  abortSignal: AbortSignal,
  imei?: string | null,
  email?: string | null,
  take = 20,
  page = 0,
  from?: Date,
  to?: Date,
  includeDeleted?: boolean | null,
) => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      take: take.toString(),
      includeDeleted: includeDeleted ? 'true' : 'false',
      ...(typeof imei === 'string' && { imei }),
      ...(typeof email === 'string' && { email }),
      ...(from && { from: from.toISOString() }),
      ...(to && { to: to.toISOString() }),
    })

    const res = await axiosClient.get<PaginatedDataDownlinks>(
      `${BASE_URI}?${params.toString()}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const getDataDownlinkById = async (
  id: number,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.get(`${BASE_URI}/${id}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const createDataDownlink = async (
  data: CreateUpdateDataDownlink,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.post(`${BASE_URI}`, data, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const updateDataDownlink = async (
  id: number,
  data: CreateUpdateDataDownlink,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.put(`${BASE_URI}/${id}`, data, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const deleteDataDownlink = async (
  id: number,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.delete(`${BASE_URI}/${id}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}
