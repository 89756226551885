import * as Sentry from '@sentry/react'

import type { EntityType } from '@/components/v2/ImageSettings'
import { axiosClient } from '@/lib/axios'

const getBaseUri = (organizationId: string) =>
  `/organization/${organizationId}/file`

export const uploadImage = async (
  organizationId: string,
  file: File,
  entity: EntityType,
) => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('entityType', entity)
    const res = await axiosClient.post<string>(
      `${getBaseUri(organizationId)}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}
