import { axiosClient } from '@/lib/axios'

export async function getRoles(abortController: AbortController) {
  const res = await axiosClient.get<string[]>('/enum/roles', {
    signal: abortController.signal,
  })
  return res.data
}

export async function getDistanceUnits(abortController: AbortController) {
  const res = await axiosClient.get<string[]>('/enum/distance', {
    signal: abortController.signal,
  })
  return res.data
}

export async function getTemperatureUnits(abortController: AbortController) {
  const res = await axiosClient.get<string[]>('/enum/temperature', {
    signal: abortController.signal,
  })
  return res.data
}

export async function getOrganizationTypes(abortController: AbortController) {
  const res = await axiosClient.get<string[]>('/enum/organization-types', {
    signal: abortController.signal,
  })
  return res.data
}

export async function getDeviceTypes(abortController: AbortController) {
  const res = await axiosClient.get<string[]>('/enum/device-types', {
    signal: abortController.signal,
  })
  return res.data
}
