import { zodResolver } from '@hookform/resolvers/zod'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import useQRCode from '@/hooks/useQRCode'

const qrCodeSchema = z.object({
  imei: z
    .string()
    .min(15, { message: 'Must be 15 characters' })
    .max(15, { message: 'Must be 15 characters' }),
})

export type QRCodeData = z.infer<typeof qrCodeSchema>

function QRCodePage() {
  const { t } = useTranslation(['admin'])
  const [qrCode, setQrCode] = useState<string>()
  const qrCodeRef = useRef<HTMLImageElement>(null)

  const handlePrint = useReactToPrint({
    content: () => qrCodeRef?.current,
  })

  const { generate } = useQRCode()
  const form = useForm({
    resolver: zodResolver(qrCodeSchema),
    defaultValues: {
      imei: '',
    },
  })

  const handleSubmit = async (data: QRCodeData) => {
    const qrCodeImg = await generate(data)
    if (qrCodeImg) {
      setQrCode(qrCodeImg)
    }
  }

  return (
    <div>
      <div className="container mt-8">
        <h2 className="text-2xl font-bold">{t('qr_code.header')}</h2>
        <p className="text-lg">{t('qr_code.subheader')}</p>
        <div className="my-8">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <FormField
                control={form.control}
                name="imei"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>{t('qr_code.imei')}</FormLabel>
                    <FormControl>
                      <div className="relative max-w-xs">
                        <Input
                          variant={fieldState.error ? 'error' : 'default'}
                          {...field}
                        />
                        <Button
                          type="submit"
                          className="absolute right-0 top-0 rounded-bl-none rounded-tl-none"
                        >
                          {t('qr_code.generate')}
                        </Button>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
        {qrCode ? (
          <>
            <div className="mt-8 max-w-fit rounded-2xl border-2 border-gray-200 p-4">
              <img
                ref={qrCodeRef}
                src={`data:image/jpeg;base64,${qrCode}`}
                alt="qr code"
                className="h-[200px] w-[200px]"
              />
            </div>
            <Button
              type="button"
              onClick={handlePrint}
              className="mt-8 font-bold"
            >
              {t('qr_code.print')}
            </Button>
          </>
        ) : null}
      </div>
    </div>
  )
}
export default QRCodePage
