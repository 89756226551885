import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import TripleDotIcon from '@/assets/icons/tripledot.svg?react'
import type { User } from '@/data/user'
import useUser from '@/hooks/useUser'
import { localizedDateWithoutTime } from '@/utils/dateHelpers'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'

import { Button } from '../ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import { useToast } from '../ui/use-toast'

function OrganizationUsersTable({
  users,
  disabled,
}: {
  users?: User[]
  disabled?: boolean
}) {
  const { t } = useTranslation(['app', 'common'])
  const { onDelete, onInvite } = useUser()
  const { toast } = useToast()
  const { orgId } = useParams()

  const handleDelete = async (id: number) => {
    try {
      await onDelete(id)
      toast({
        title: 'User deleted!',
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: 'Delete failed!',
        description:
          'Something went wrong when deleting the user. Please try again in a while',
        className: failureToastClassNames,
      })
    }
  }

  const handleInvite = async (id: number) => {
    try {
      await onInvite(id)
      toast({
        title: 'Invite sent!',
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: 'Invite failed!',
        description:
          'Either the user has an active invite already or something went wrong when sending the invite. Please try again in a while',
        className: failureToastClassNames,
      })
    }
  }
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="whitespace-nowrap">
            {capitalizeFirstCharacter(t('first_name', { ns: 'common' }))}
          </TableHead>
          <TableHead className="whitespace-nowrap">
            {capitalizeFirstCharacter(t('last_name', { ns: 'common' }))}
          </TableHead>
          <TableHead className="whitespace-nowrap">
            {capitalizeFirstCharacter(t('email', { ns: 'common' }))}
          </TableHead>
          <TableHead align="right" className="whitespace-nowrap">
            {capitalizeFirstCharacter(t('phone_number', { ns: 'common' }))}
          </TableHead>
          <TableHead className="whitespace-nowrap">{t('role')}</TableHead>
          <TableHead align="right" className="whitespace-nowrap">
            {capitalizeFirstCharacter(t('last_logged_in', { ns: 'common' }))}
          </TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {(users || []).map((user) => (
          <TableRow key={user.id}>
            <TableCell>{user.firstName}</TableCell>
            <TableCell>{user.lastName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell align="right">{user.phoneNumber}</TableCell>
            <TableCell>
              {t(
                `role.${user.organizationUsers?.find(
                  (ou) => ou.organizationId.toString() === orgId,
                )?.role}`,
              )}
            </TableCell>
            <TableCell align="right">
              {user.lastLoginAt
                ? localizedDateWithoutTime(new Date(user.lastLoginAt))
                : ''}
            </TableCell>
            <TableCell>
              <DropdownMenu>
                <DropdownMenuTrigger asChild disabled={disabled}>
                  <Button size="icon" variant="ghost" type="button">
                    <TripleDotIcon />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {!user.lastLoginAt ? (
                    <DropdownMenuItem
                      onClick={async () => handleInvite(user.id)}
                    >
                      {t('organization_settings.resend_invitation')}
                    </DropdownMenuItem>
                  ) : null}
                  <DropdownMenuItem onClick={async () => handleDelete(user.id)}>
                    {t('organization_settings.delete_user')}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
export default OrganizationUsersTable
