import * as Sentry from '@sentry/react'

import type { CreateUpdateUser } from '@/components/admin/user/AddEditUserModal'
import type { LinkUser } from '@/components/admin/user/LinkUserModal'
import { axiosClient } from '@/lib/axios'

import type { OrganizationUser } from '../user'

export type UserRole = 'admin' | 'organization_admin' | 'user'

export interface AdminUser {
  id: number
  email: string
  role: UserRole
  firstName?: string
  lastName?: string
  phoneNumber?: string
  organizationUsers: OrganizationUser[]
  inAppNotificationsEnabled: boolean
  emailNotificationsEnabled: boolean
  imageUrl?: string
  isEmailVerified: boolean
  isBlocked: boolean
  failedLoginCount: number
  lastFailedLoginAt?: Date
  lastLoginAt?: Date
  createdAt: Date
  updatedAt: Date
}

export interface PaginatedUserResponse {
  users: AdminUser[]
  totalCount: number
}

const BASE_URL = `/admin/user`

export const getUsers = async (
  abortSignal: AbortSignal,
  email?: string | null,
  organizationId?: string | null,
  includeAdminUsers = true,
  take = 20,
  page = 0,
) => {
  try {
    const params = new URLSearchParams({
      includeAdminUsers: includeAdminUsers.toString(),
      page: page.toString(),
      take: take.toString(),
      ...(typeof organizationId === 'string' && { organizationId }),
      ...(typeof email === 'string' && { email }),
      ...(typeof includeAdminUsers === 'boolean' && {}),
    })

    const res = await axiosClient.get<PaginatedUserResponse>(
      `${BASE_URL}?${params.toString()}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const getUser = async (id: number, abortSignal: AbortSignal) => {
  try {
    const res = await axiosClient.get<AdminUser>(`${BASE_URL}/${id}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const createUser = async (
  data: CreateUpdateUser,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.post(`${BASE_URL}`, data, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const updateUser = async (
  id: number,
  data: CreateUpdateUser,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.put(`${BASE_URL}/${id}`, data, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const unblockUser = async (id: number, abortSignal: AbortSignal) => {
  try {
    const res = await axiosClient.put(`${BASE_URL}/${id}/unblock`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const sendUserInvite = async (id: number, abortSignal: AbortSignal) => {
  try {
    const res = await axiosClient.post(`${BASE_URL}/${id}/send-invite`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const deleteUser = async (id: number, abortSignal: AbortSignal) => {
  try {
    const res = await axiosClient.delete(`${BASE_URL}/${id}`, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const linkUser = async (
  id: number,
  data: LinkUser,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.put(`${BASE_URL}/${id}/link`, data, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export const unlinkUser = async (
  id: number,
  data: LinkUser,
  abortSignal: AbortSignal,
) => {
  try {
    const res = await axiosClient.put(`${BASE_URL}/${id}/unlink`, data, {
      signal: abortSignal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}
