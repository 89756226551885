import { useTranslation } from 'react-i18next'

import BrokenDataConnecionIcon from '@/assets/icons/brokendataconnection.svg?react'
import ErrorIcon from '@/assets/icons/error.svg?react'
import PendingIcon from '@/assets/icons/pending.svg?react'
import PrestineIcon from '@/assets/icons/prestine.svg?react'
import WarningIcon from '@/assets/icons/warning.svg?react'
import type { Device } from '@/data/device'
import { cn } from '@/lib/utils'
import {
  errorStatuses,
  operatingWellStatuses,
  warningStatuses,
} from '@/utils/statusHelpers'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'

function DeviceStatusBadge({ status }: { status: Device['status'] }) {
  const { t } = useTranslation('common')

  return (
    <div
      className={cn(
        'flex items-center gap-1 rounded-3xl border bg-white px-2 py-1',
      )}
    >
      {status === 'broken_data_connection' ? (
        <BrokenDataConnecionIcon />
      ) : status === 'pending' ? (
        <PendingIcon />
      ) : errorStatuses.includes(status) ? (
        <ErrorIcon />
      ) : warningStatuses.includes(status) ? (
        <WarningIcon />
      ) : operatingWellStatuses.includes(status) ? (
        <PrestineIcon />
      ) : null}
      <span className="whitespace-nowrap text-xs">
        {capitalizeFirstCharacter(t(status))}
      </span>
    </div>
  )
}

export default DeviceStatusBadge
