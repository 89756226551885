import { differenceInMinutes } from 'date-fns'

import { Card, CardContent, CardTitle } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import useAdminDashboard from '@/hooks/useAdminDashboard'
import { localizedDateWithTime } from '@/utils/dateHelpers'

const WARNING_THRESHOLD = 5
const ERROR_THRESHOLD = 10

export default function AdminDashboardpage() {
  const { data, isLoading } = useAdminDashboard()
  const minuteDiff = data?.lastDataPointAt
    ? differenceInMinutes(new Date(), new Date(data.lastDataPointAt))
    : null

  const statusColor =
    typeof minuteDiff === 'number'
      ? minuteDiff > ERROR_THRESHOLD
        ? 'red'
        : minuteDiff > WARNING_THRESHOLD
        ? 'yellow'
        : 'green'
      : 'red'

  const updatedAt = data?.updatedAt
    ? localizedDateWithTime(new Date(data.updatedAt))
    : 'No data'

  return (
    <main>
      <h2 className="text-3xl">Dashboard</h2>
      <h4 className="mt-2 text-sm">Updated {updatedAt}</h4>
      <div className="mt-8">
        <div className="flex">
          {isLoading ? (
            <Skeleton className="h-32 w-44" />
          ) : (
            <Card variant={statusColor} className="space-y-4 p-4">
              <CardTitle>Last datapoint</CardTitle>
              <CardContent className="px-0">
                <span className="text-lg">
                  {data?.lastDataPointAt
                    ? localizedDateWithTime(new Date(data?.lastDataPointAt))
                    : 'No data'}
                </span>
              </CardContent>
            </Card>
          )}
        </div>
        <div className="mt-8 flex flex-wrap gap-4">
          <Card variant="blue" className="space-y-4 p-4">
            <CardTitle>Organizations</CardTitle>
            <CardContent className="px-0">
              <span className="block text-sm">Number of organizations:</span>
              <span className="text-lg">{data?.organizations || 0}</span>
            </CardContent>
          </Card>
          <Card variant="blue" className="space-y-4 p-4">
            <CardTitle>Users</CardTitle>
            <CardContent className="px-0">
              <span className="block text-sm">Number of users:</span>
              <span className="text-lg">{data?.users || 0}</span>
            </CardContent>
          </Card>
          <Card variant="blue" className="space-y-4 p-4">
            <CardTitle>Devices</CardTitle>
            <CardContent className="px-0">
              <span className="block text-sm">Number of devices:</span>
              <span className="text-lg">{data?.devices || 0}</span>
            </CardContent>
          </Card>
          <Card variant="blue" className="space-y-4 p-4">
            <CardTitle>Datapoints</CardTitle>
            <CardContent className="px-0">
              <span className="block text-sm">Number of datapoints:</span>
              <span className="text-lg">{data?.dataPoints || 0}</span>
            </CardContent>
          </Card>
        </div>
      </div>
    </main>
  )
}
