import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { capitalizeFirstCharacter } from '@/utils/textHelpers'

import WaterLevelsIcon from '../../assets/icons/waterlevels.svg?react'
import { Button } from '../ui/button'
import { Card } from '../ui/card'

function EmptyDashboard() {
  const { t } = useTranslation(['app'])

  return (
    <Card variant="blue" className="col-span-full flex flex-1 flex-col p-4">
      <div className="m-auto flex max-w-xl flex-col items-center justify-center gap-4">
        <WaterLevelsIcon />
        <h1 className="text-2xl font-semibold">
          {t('dashboard.empty_heading')}
        </h1>
        <p>
          <span>{t('dashboard.empty_description')}</span>
        </p>
        <Link to={`${window.location.pathname}/add-device`}>
          <Button size="sm" className="flex items-center gap-1">
            {capitalizeFirstCharacter(t('dashboard.empty_primary_action'))}
          </Button>
        </Link>
      </div>
    </Card>
  )
}

export default EmptyDashboard
