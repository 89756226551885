import type { OrgUserRole, UserRole } from '@/data/user'

export const isAdmin = (role?: string | UserRole) => {
  if (!role) return false
  return ADMIN_ROLES.includes(role as UserRole)
}

export const isOrgAdminOrAdmin = (role?: string | UserRole) => {
  if (!role) return false
  return ORGANIZATION_ADMIN_ROLES.includes(role as UserRole)
}

export const isOwner = (role?: string | OrgUserRole) => {
  if (!role) return false
  return OWNER_ROLES.includes(role as OrgUserRole)
}

export const isOwnerOrManager = (role?: string | OrgUserRole) => {
  if (!role) return false
  return MANAGER_ROLES.includes(role as OrgUserRole)
}

export const ALL_USER_ROLES = []

export const ADMIN_ROLES: UserRole[] = ['admin']
export const ORGANIZATION_ADMIN_ROLES: UserRole[] = [
  ...ADMIN_ROLES,
  'organization_admin',
]
export const USER_ROLES: UserRole[] = [...ORGANIZATION_ADMIN_ROLES, 'user']

export const OWNER_ROLES: OrgUserRole[] = ['owner']
export const MANAGER_ROLES: OrgUserRole[] = [...OWNER_ROLES, 'manager']
export const MEMBER_ROLES: OrgUserRole[] = [...MANAGER_ROLES, 'member']

export const USER_ROLE_ACCESS: { key: UserRole; values: UserRole[] }[] = [
  { key: 'admin', values: ADMIN_ROLES },
  { key: 'organization_admin', values: ORGANIZATION_ADMIN_ROLES },
  { key: 'user', values: USER_ROLES },
]

export const ORG_USER_ROLE_ACCESS: {
  key: OrgUserRole
  values: OrgUserRole[]
}[] = [
  { key: 'owner', values: OWNER_ROLES },
  { key: 'manager', values: MANAGER_ROLES },
  { key: 'member', values: MEMBER_ROLES },
]
