import StillWaterIllustration from '@/assets/illustrations/stillwater.svg?url'
import WaveIllustration from '@/assets/illustrations/wave.svg?url'
import { cn } from '@/lib/utils'

function WaterWave({ shouldAnimate }: { shouldAnimate: boolean }) {
  return (
    <div
      data-testid="water-wave"
      data-animate={shouldAnimate}
      className={cn(
        'h-[10px] w-[175px] opacity-40',
        shouldAnimate && 'animate-ocean-wave',
      )}
      style={{
        background: `url(${
          shouldAnimate ? WaveIllustration : StillWaterIllustration
        }) repeat-x`,
      }}
    ></div>
  )
}
export default WaterWave
